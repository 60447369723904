import BaseForm from '../base/BaseForm';
import {reduxForm, Field} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import InputMask from "react-input-mask";
import * as FontAwesome from "react-icons/lib/fa";

class Rules extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],

        }
    }
    renderFields = ({input}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <div className={s.ruleListStyle}>
                    <button
                        type={"button"}
                        onClick={() => input.onChange('limited')}
                        className={cx(s.ruleButtonStyle, input.value === 'limited' ? s.selectedRuleStyle : s.defaultRuleStyle)}
                    >
                        <div className={cx(s.iconWrapperStyle)}>
                            <FontAwesome.FaCheck className={cx(s.iconStyle)}/>
                        </div>
                        <div className={cx(s.ruleContentStyle, 'textWhite')}>
                            <h3 className={cx(s.ruleTitle)}>{formatMessage(messages.whoCanBookInfo6)}</h3>
                            <p className={cx(s.ruleDescription)}>{formatMessage(messages.whoCanBookInfo4)}</p>
                            <p className={cx(s.ruleDescription)}>{formatMessage(messages.whoCanBookInfo5)}</p>
                        </div>
                    </button>
                    <button
                        type={"button"}
                        onClick={() => input.onChange('instant')}
                        className={cx(s.ruleButtonStyle, input.value === 'instant' ? s.selectedRuleStyle : s.defaultRuleStyle)}
                    >
                        <div className={cx(s.iconWrapperStyle)}>
                            <FontAwesome.FaUser className={cx(s.iconStyle, 'textWhite')}/>
                        </div>
                        <div className={cx(s.ruleContentStyle, 'textWhite')}>
                            <h3 className={cx(s.ruleTitle)}>{formatMessage(messages.whoCanBookInfo1)}</h3>
                            <p className={cx(s.ruleDescription)}>{formatMessage(messages.whoCanBookInfo2)}</p>
                        </div>
                    </button>
                    <button
                        type={"button"}
                        onClick={() => input.onChange('request')}
                        className={cx(s.ruleButtonStyle, input.value === 'request' ? s.selectedRuleStyle : s.defaultRuleStyle)}
                    >
                        <div className={cx(s.iconWrapperStyle)}>
                            <FontAwesome.FaBan className={cx(s.iconStyle, 'textWhite')}/>
                        </div>
                        <div className={cx(s.ruleContentStyle, 'textWhite')}>
                            <h3 className={cx(s.ruleTitle)}>{formatMessage(messages.whoCanBookInfo3)}</h3>
                        </div>
                    </button>
                </div>
            </div>
        )
    }

    renderCancellationRules = ({input, cancellationPolicies}) => {
        const {formatMessage} = this.props.intl;

        function handleSelect(policy) {
            const selectedPolicy = cancellationPolicies.find(item => item.id === policy.id);
            if (selectedPolicy) {
                return (
                    <div className={cx(s.policyDetails, s.spaceTop5, 'bgBlackTwo')}>
                        <h3 className={s.detailsTitle}>
                            {selectedPolicy.name}
                        </h3>

                        <div className={s.detailItem}>
                            <span className={s.detailIcon}>{selectedPolicy.policyContent}</span>
                        </div>
                        <div className={s.detailItem}>
                            <span>{selectedPolicy.subContent}</span>
                        </div>
                    </div>
                )
            }
            return (<div></div>)

        }

        return (
            <div>
                <ul className={cx(s.listContainer, s.safetyAmenitiesFlex, s.space0)}>
                    {cancellationPolicies.map((option, index) => {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex, s.space0)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small, s.maxContent)}>
                                    <div
                                        className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                            {[s.checked]: parseInt(input.value) === option.id},
                                            {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                        onClick={() => {
                                            input.onChange(option.id);
                                        }}>
                                        <div className={s.checkoutText}>{option.policyName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                    }
                </ul>

                {input.value && handleSelect(cancellationPolicies.find(item => item.id === input.value))}
            </div>
        )
    }

    render() {
        const {
            listingFields: {
                houseRules,
            }, handleSubmit,
            cancellationPolicies
        } = this.props;
        const {formatMessage} = this.props.intl;

        return (
            <div className={cx(s.listingForm, 'bgBlackTwo')}>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.setHouseRules} />
                            </ControlLabel>
                            <Field name="houseRules" component={this.renderButtonGroup}
                                   className={cx(s.small, s.maxContent)}
                                   options={houseRules}/>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.whoCanBook} />
                                <span
                                    className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="bookingType"
                                component={this.renderFields}
                            ></Field>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.chooseCancellationPolicy} />
                                <span
                                    className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="cancellationPolicy"
                                component={this.renderCancellationRules}
                                className={cx(s.formControlInput, s.jumboInput)}
                                cancellationPolicies={cancellationPolicies}
                            ></Field>
                        </FormGroup>
                    </Col>
                </Row>
            </div>

        )
    }

}

Rules = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Rules)

const mapState = (state) => ({
    listingFields: state.listingFields.data,
    cancellationPolicies: state.location.cancellationPolicies || [],
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Rules)));
