import messages from '../../../locale/messages';

const validatePage3 = {
  layoutType: (value) => {
    if (!value) {
      return messages.required;
    }
  },
  repairType: (value) => {
    if (!value) {
      return messages.required;
    }
  },
  wallMaterial: (value) => {
    if (!value) {
      return messages.required;
    }
  },
  ceilingHeight: (value) => {
    if (!value) {
      return messages.required;
    }
  },
  elevatorType: (value) => {
    if (!value) {
      return messages.required;
    }
  },

  bedrooms: (value) => {
    if (!value) {
      return messages.required;
    }
  },

  bathrooms: (value) => {
    if (!value) {
      return messages.required;
    }
  },
  bathroomType: (value) => {
    if (!value) {
      return messages.required;
    }
  },
}

export default validatePage3
