import {FormControl} from "react-bootstrap";
import s from "../style/base.css";
import React from "react";
import cx from "classnames";
import IncrementButton from "../../IncrementButton";


class BaseForm extends React.Component {
    constructor(props) {
        super(props);
    }

    renderFormControlSelect = ({input, label, meta: {touched, error}, children, className}) => {
        return (
            <>
                <FormControl componentClass="select" {...input} className={className}>
                    {children}
                </FormControl>
            </>
        )
    }

    renderFormControlTextArea = ({input, label, meta: {touched, error}, children, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    componentClass={"textarea"}
                />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </>
        )
    }

    renderFormControl = ({input, label, type, meta: {touched, error}, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <>
                <FormControl {...input} placeholder={label} type={type} className={className}/>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </>
        )
    }

    renderButtonGroup = ({label, name, options, input, className}) => {
        console.log(input.name, input.value);
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex, s.space0)}>
                {options && options.map((option, index) => {
                    if (option.isEnable == "1") {
                        return (
                            <li className={cx('amenitiesCheckBox')} key={index}>
                                <div className={cx(s.checkBoxOutline, className)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: input.value?.indexOf(option.id) !== -1},
                                        {["darkModeAmenitiesChecked"]: input.value?.indexOf(option.id) !== -1}, 'svgImg')}
                                         onClick={() => {
                                             const newValue = [...input.value];
                                             if (newValue?.indexOf(option.id) !== -1) {
                                                 newValue.splice(newValue?.indexOf(option.id), 1);
                                             } else {
                                                 newValue.push(option.id);
                                             }
                                             input.onChange(newValue);

                                         }}>
                                        <div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    renderRadioGroup = ({label, name, options, input, className, onChange}) => {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex, s.space0)}>
                {options && options.map((option, index) => {
                    if (option.isEnable == '1') {
                        return (
                            <li className={cx('amenitiesCheckBox')} key={index}>
                                <div className={cx(s.checkBoxOutline, className)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: parseInt(input.value) === option.id},
                                        {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                         onClick={() => {
                                             if (onChange) {
                                                 onChange(option);
                                                 return
                                             }
                                             input.onChange(option.id);
                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    renderIncrementButton = (field) => (
        <IncrementButton
            {...field}
        />
    );

}

export default BaseForm;
