import React, {Component} from "react";
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import InputGroup from "react-bootstrap/lib/InputGroup";

// Component
import updateStep3 from "./updateStep3";
import FooterButton from "./FooterButton";
import SidePanel from "./SidePanel";

import messages from "../../locale/messages";
import validateStep3 from "./validateStep3";

//Image
import toolTipIcon from "/public/SiteIcons/listCommonToolTip.svg";

import bt from "../../components/commonStyle.css";
import s from "./ListPlaceStep1.css";
import update from "./update";
import CustomRadio from "../CustomRadio";

class Pricing extends Component {
    static propTypes = {
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
        createNewListing: PropTypes.bool,
    };

    static defaultProps = {
        nextPagePath: 'tariff',
        previousPagePath: 'bedrooms',
        createNewListing: false
    }

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
        };
    }

    UNSAFE_componentWillMount() {
        const {valid} = this.props;
        this.setState({isDisabled: valid ? false : true});
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {valid} = nextProps;
        this.setState({isDisabled: valid ? false : true});
    }

    renderFormControl = ({
                             input,
                             label,
                             type,
                             meta: {touched, error},
                             className,
                         }) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl
                    {...input}
                    placeholder={label}
                    type={type}
                    className={className}
                    maxLength={12}
                />
                {touched && error && (
                    <span className={s.errorMessage}>{formatMessage(error)}</span>
                )}
            </div>
        );
    };

    renderFormControlTax = ({
                                input,
                                label,
                                type,
                                meta: {touched, error},
                                className,
                                prefixLabel,
                            }) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <InputGroup>
                    <FormControl
                        {...input}
                        placeholder={label}
                        type={type}
                        className={className}
                        maxLength={12}
                    />
                    <InputGroup.Addon className={s.prefixIcon}>
                        {prefixLabel}
                    </InputGroup.Addon>
                </InputGroup>
                {touched && error && (
                    <span className={s.errorMessage}>{formatMessage(error)}</span>
                )}
            </div>
        );
    };

    renderFormControlSelect = ({
                                   input,
                                   label,
                                   meta: {touched, error},
                                   children,
                                   className,
                               }) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl componentClass="select" {...input} className={className}>
                    {children}
                </FormControl>
            </div>
        );
    };

    buttonGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable === "1") {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: input.value.indexOf(option.id) !== -1},
                                        {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                         onClick={() => {
                                             const newValue = [...input.value];
                                             if (newValue.indexOf(option.id) !== -1) {
                                                 newValue.splice(newValue.indexOf(option.id), 1);
                                             } else {
                                                 newValue.push(option.id);
                                             }
                                             input.onChange(newValue);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    checkboxGroup = ({label, name, options, input, type}) => (
        <ul className={cx(s.listContainer, s.rulesTop)}>
            {
                options.map((option, index) => {
                        if (option.isEnable) {
                            return (
                                <li className={cx(s.listContent, s.fullWidth)} key={index}>
                                    <span className={s.checkBoxSection}>
                                      <CustomRadio
                                          name={input.name}
                                          value={option.id}
                                          checked={input.value === option.id}
                                          className={'iradio_square-green'}
                                          onChange={event => {
                                              input.onChange(option.id);
                                          }}
                                      />
                                    </span>
                                    <span className={cx(s.checkBoxSection, s.checkBoxLabel)}>
                                        <label
                                            className={cx(s.checkboxLabel, s.noPadding, 'textWhite')}>{option.itemName}</label>
                                    </span>
                                </li>
                            )
                        }
                    }
                )
            }

        </ul>
    );
    radioGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable) {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: parseInt(input.value) === option.id},
                                        {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                         onClick={() => {
                                             input.onChange(option.id);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    renderAdditionalField({}) {
        const {formatMessage} = this.props.intl;
        const {createNewListing} = this.props;
        const bargainingType = [
            {
                id: 1,
                itemName: formatMessage(messages.yesText),
                isEnable: 1
            },
            {
                id: 2,
                itemName: formatMessage(messages.noText),
                isEnable: 1
            }
        ]
        const depositType = [
            {
                id: 1,
                itemName: formatMessage(messages.yesText),
                isEnable: 1
            },
            {
                id: 2,
                itemName: formatMessage(messages.noText),
                isEnable: 1
            }
        ]
        return (
            <div>
                <FormGroup className={cx(s.formGroup, s.space4, s.spaceTop4)}>
                    <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                        <FormattedMessage {...messages.auction} /> {createNewListing &&
                        <span className={s.required}>*</span>}
                    </ControlLabel>
                    <Field name="bargainingPrice" component={this.checkboxGroup} options={bargainingType}/>
                </FormGroup>
                <FormGroup className={cx(s.formGroup, s.space4, s.spaceTop4)}>
                    <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                        <FormattedMessage {...messages.deposit} /> {createNewListing &&
                        <span className={s.required}>*</span>}
                    </ControlLabel>

                    <Field name="deposit" component={this.checkboxGroup} options={bargainingType}/>
                </FormGroup>
            </div>
        )
    }


    render() {
        const {
            handleSubmit,
            nextPage,
            previousPage,
            formPage,
            step,
            createNewListing,
            availableCurrencies,
            isExistingList,
            listingFields: {
                publicUtilitiesType,
                publicUtilities,
                paymentTypes
            },

        } = this.props;
        const {isDisabled} = this.state;
        const {formatMessage} = this.props.intl;
        let listingType = createNewListing || isExistingList;
        const nextPagePath = createNewListing ? 'house-rules' : 'tariff';
        const previousPagePath = createNewListing ? 'conveniences' : 'bedrooms';
        return (
            <div className={s.grid}>
                <SidePanel
                    title={formatMessage(messages.stepOneCommonHeading)}
                    landingContent={formatMessage(messages.tabPricing)}
                />
                <form onSubmit={handleSubmit}>
                    <div className={cx(s.landingMainContent, "disCountAddon")}>
                        <FormGroup className={s.formGroup}>
                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.currency} /><span className={s.required}>*</span>
                            </ControlLabel>

                            <Field
                                name="currency"
                                component={this.renderFormControlSelect}
                                className={cx(
                                    s.formControlSelect,
                                    s.jumboSelect,
                                    s.listCommonJumboSelect,
                                    "listCommonJumboSelectRTL"
                                )}
                            >
                                {availableCurrencies?.length > 0 &&
                                    availableCurrencies.map((currency, key) => {
                                        if (currency.isEnable === true) {
                                            return (
                                                <option key={key} value={currency.symbol}>
                                                    {currency.symbol}
                                                </option>
                                            );
                                        }
                                    })}
                            </Field>
                        </FormGroup>
                        <FormGroup className={cx(s.formGroup, s.space4, s.spaceTop4)}>
                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.basePrice} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="basePrice"
                                type="text"
                                component={this.renderFormControl}
                                label={formatMessage(messages.basePriceLabel)}
                                className={cx(
                                    s.formControlInput,
                                    s.jumboSelect,
                                    s.listCommonJumboSelect,
                                    "listCommonJumboSelectRTL"
                                )}
                            />
                        </FormGroup>
                        {/*{listingType && this.renderAdditionalField()}*/}
                        <div className={s.tipCommonCss}>
                            <img src={toolTipIcon}/>
                            <span className={cx(s.commonTipCsss, "textWhite")}>
                                <FormattedMessage {...messages.basePriceTip} /> <span className={s.required}>*</span>
                          </span>
                        </div>
                        {paymentTypes && <FormGroup className={cx(s.formGroup, s.space4)}>
                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.paymentTypes} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="paymentTypes"
                                component={this.buttonGroup}
                                options={paymentTypes}
                            />
                        </FormGroup>}
                        {publicUtilities && <FormGroup className={cx(s.formGroup, s.space4)}>
                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.publicUtilities} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="publicUtilities"
                                component={this.buttonGroup}
                                options={publicUtilities}
                            />
                        </FormGroup>}
                        {publicUtilitiesType && <FormGroup className={cx(s.formGroup, s.space4)}>
                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.publicUtilitiesType} /> <span
                                className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="publicUtilitiesType"
                                component={this.radioGroup}
                                options={publicUtilitiesType}
                            />
                        </FormGroup>}
                    </div>
                    <FooterButton
                        nextPage={nextPage}
                        previousPage={previousPage}
                        nextPagePath={this.props.nextPagePath}
                        previousPagePath={this.props.previousPagePath}
                        formPage={formPage}
                        step={step}
                        isDisabled={isDisabled}
                    />
                </form>
            </div>
        );
    }
}

Pricing = reduxForm({
    form: "ListPlaceStep1", // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateStep3,
    onSubmit: update,
    // onSubmit: updateStep4
})(Pricing);

const mapState = (state) => ({
    listingFields: state.listingFields.data,
    availableCurrencies: state.currency.availableCurrencies,
    aviableTariffs: state.tariff?.aviableTariffs,
    base: state.currency.base,
    mapUpdateLoading: state.location.mapUpdateLoading,
    isExistingList: state.location.isExistingList,
});

const mapDispatch = {};

export default injectIntl(
    withStyles(s, bt)(connect(mapState, mapDispatch)(Pricing))
);
