import {gql} from "react-apollo";

import {
    GET_TARIFFS_SUCCESS,
    GET_TARIFFS_ERROR,
    GET_TARIFFS_START,
} from "../constants";

const query = gql`
  {
    getAllTariffs {
      id
      name
      description
      host_commision
      guest_commision
      host_is_percent
      guest_is_percent
      is_only_commission
      advertising
      customer_support
      risk_insurance
      apartment_tour
      checkIn_tenants
      premium_sections
      search_priority
      service_guarantee
      type
    }
  }
`;

export function getAllTariffs() {
    return async (dispatch, getState, {client}) => {
        dispatch({
            type: GET_TARIFFS_START,
        });

        try {
            // Send Request to get listing data
            const {data} = await client.query({
                query,
                fetchPolicy: "network-only",
            });

            if (data && data.getAllTariffs) {
                dispatch({
                    type: GET_TARIFFS_SUCCESS,
                    aviableTariffs: data.getAllTariffs,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_TARIFFS_ERROR,
            });
            return false;
        }

        return true;
    };
}
