import React from 'react';
import {flowRight as compose} from 'lodash';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Layout.css';
import Header from '../HeaderV2';
import Footer from '../Footer';
import CookiesDisclaimer from '../CookiesDisclaimer';
import cx from 'classnames';

import MobileMenu from '../MobileMenu'

function Layout(props) {
    return (
        <div className={s.layout}>
            <Header fixedHeader={true} page={props.page} guests={props.guests} whyHostHeader={props.whyHostHeader}
                    whyHostSearchHide={props.whyHostSearchHide} viewListingHeader={props.viewListingHeader}/>
            <main style={{flexGrow: 1}}> {props.children}</main>
            <Footer/>
            <MobileMenu/>

            <CookiesDisclaimer/>
        </div>
    )
}

export default compose(
    withStyles(s),
)(Layout);
