// General
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Redux Form
import {Field, reduxForm, formValueSelector} from 'redux-form';
// Translation
import {injectIntl, FormattedMessage} from 'react-intl';
// Redux
import {connect} from 'react-redux';
import {
    FormGroup,
    ControlLabel,
    FormControl, Row, Col, ToggleButton, ToggleButtonGroup
} from 'react-bootstrap';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

// Locale
import messages from '../../locale/messages';

// components
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import CountryList from '../CountryList';

// Helpers
import validate from './validate';
import update from './update';

//Image
import IncrementButton from "../IncrementButton";
import {updateLocationStatus} from "../../actions/getLocation";
import {updateListingMap} from "../../actions/updateListingMap";
import InputMask from "react-input-mask";
import validatePage1 from "./moreValidators/validatePage1";


class Page2 extends Component {

    static propTypes = {
        initialValues: PropTypes.object,
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        isLocationChosen: PropTypes.bool,
        onSubmit: PropTypes.any,
        updateLocationStatus: PropTypes.any,
        isExistingList: PropTypes.bool,
        updateListingMap: PropTypes.any,
        mapUpdateLoading: PropTypes.bool,
        createNewListing: PropTypes.bool,
        additionalFields: PropTypes.bool,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
    };

    static defaultProps = {
        nextPagePath: 'bedrooms',
        previousPagePath: 'index',
        createNewListing: false,
        additionalFields: false
    }

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
            houseType: [],
            roomType: [],
            buildingSize: [],
            leaseType: [],
            selectedLeaseType: null
        }
        this.renderCountryList = this.renderCountryList.bind(this);

    }

    UNSAFE_componentWillMount() {
        const {listingFields, leaseTypeValue, leaseType} = this.props;

        if (listingFields != undefined) {
            this.setState({
                houseType: listingFields.houseType,
                roomType: listingFields.roomType,
                buildingSize: listingFields.buildingSize,
                leaseType: listingFields.leaseType,
            });
        }


    }

    componentDidMount() {
        const {valid} = this.props;

        // Set initial filter based on first lease type if no value is selected
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {valid, listingFields} = nextProps;

        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }

        if (listingFields != undefined) {
            this.setState({
                houseType: listingFields.houseType,
                roomType: listingFields.roomType,
                buildingSize: listingFields.buildingSize,
                leaseType: listingFields.leaseType,
            });
        }
    }

    renderSelectField = ({input, label, meta: {touched, error}, children}) => {
        const {formatMessage} = this.props.intl;

        return (
            <div>
                <select
                    {...input}
                >
                    {children}
                </select>
                {touched && error && <span>{formatMessage(error)}</span>}
            </div>
        )
    }

    renderFormControlSelect = ({input, label, meta: {touched, error}, children, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl componentClass="select" {...input} className={className}>
                    {children}
                </FormControl>
            </div>
        )
    }

    renderCountryList({input, label, meta: {touched, error}, children, className}) {
        const {formatMessage} = this.props.intl;
        return <div>
            <CountryList input={input} className={className} isEmptyFirst/>
            {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        </div>
    }

    renderFormControl = ({input, label, type, meta: {touched, error}, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl {...input} placeholder={label} type={type} className={className}/>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        )
    }

    renderLocationForm() {
        const {
            isExistingList,
            nextPage,
            previousPage,
            onSubmit,
            invalid,
            updateListingMap,
            loading,
            mapUpdateLoading
        } = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages.country} /> <span className={s.required}>*</span>
                    </ControlLabel>
                    <Field name="country"
                           component={this.renderCountryList}
                           className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}/>
                </FormGroup>
                <Row>

                    <Col xs={12} sm={12} md={12} lg={6}>
                        <FormGroup className={s.space5}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.state} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="state"
                                component={this.renderFormControl}
                                className={cx(s.formControlInput, s.jumboInput)}
                                label={formatMessage(messages.state)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}><FormGroup className={s.space5}>
                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                            <FormattedMessage {...messages.district} /> <span className={s.required}>*</span>
                        </ControlLabel>
                        <Field
                            name="district"
                            component={this.renderFormControl}
                            className={cx(s.formControlInput, s.jumboInput)}
                            label={formatMessage(messages.district)}
                        />
                    </FormGroup></Col>
                </Row>

                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages.city} /> <span className={s.required}>*</span>
                    </ControlLabel>
                    <Field
                        name="city"
                        component={this.renderFormControl}
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.city)}

                    />
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages.street} /> <span className={s.required}>*</span>
                    </ControlLabel>
                    <Field
                        name="street"
                        component={this.renderFormControl}
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.street)}
                    />
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages.buildingName} />
                    </ControlLabel>
                    <Field
                        name="buildingName"
                        component={this.renderFormControl}
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.buildingName)}
                    />
                </FormGroup>
            </div>
        );
    }

    checkboxGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable === "1") {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: input.value.indexOf(option.id) !== -1},
                                        {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                         onClick={() => {
                                             const newValue = [...input.value];
                                             if (newValue.indexOf(option.id) !== -1) {
                                                 newValue.splice(newValue.indexOf(option.id), 1);
                                             } else {
                                                 newValue.push(option.id);
                                             }
                                             input.onChange(newValue);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    radioGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable) {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: parseInt(input.value) === option.id},
                                        {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                         onClick={() => {
                                             input.onChange(option.id);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    renderIncrementButton = (field) => (
        <IncrementButton
            {...field}
        />
    );

    changeCadastalNumber = (e) => {
        this.setState({
            cadastralNumber: e.target.value
        })
    }

    renderCadastrNumberField = ({input, label, type, meta: {touched, error}, className, changeCadastalNumber}) => {
        function beforeMaskedStateChange({nextState}) {
            let {value} = nextState;
            if (value.endsWith(":")) {
                value = value.slice(0, -1);
            }

            return {
                ...nextState,
                value
            };
        }

        return (
            <InputMask
                name="cadastralNumber"
                value={input.value}
                onChange={(e) => {
                    changeCadastalNumber(e)
                    input.onChange(e)
                }}
                maskChar={null}
                alwaysShowMask="true"
                className={cx(s.formControlInput, s.jumboInput)}
                mask={"99:99:99:99:99:9999:9999"}
                style={{
                    width: "100%"
                }}
            />
        )
    }

    render() {
        const {
            handleSubmit,
            previousPage,
            nextPage,
            existingList,
            formPage,
            step,
            isExistingList,
            createNewListing,
            additionalFields
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {
            isDisabled,
            houseType,
            leaseType,
            cadastralNumber,
        } = this.state;
        let leaseTypes = leaseType
        let path = this.props.previousPagePath;
        if (existingList) {
            path = "home";
        }
        let listingType = additionalFields || isExistingList;

        return (
            <div>
                <div className={s.grid}>
                    <SidePanel
                        title={formatMessage(messages.stepOneCommonHeading)}
                        landingContent={formatMessage(messages.whatKindOfPlaceListing)}
                    />
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className={s.landingMainContent}>
                                    <FormGroup className={s.space5}>
                                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                            <FormattedMessage {...messages.leaseTypeQuestion} /> <span
                                            className={s.required}>*</span>
                                        </ControlLabel>
                                        <Field
                                            name="leaseType"
                                            component={this.radioGroup}
                                            options={leaseTypes}
                                        />
                                    </FormGroup>
                                    <FormGroup className={s.space5}>
                                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                            <FormattedMessage {...messages.whatTypeOfProperty} /> <span
                                            className={s.required}>*</span>
                                        </ControlLabel>
                                        <Field name="houseType" component={this.renderFormControlSelect}
                                               className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}>
                                            {
                                                houseType.map((value, key) => {
                                                    return (
                                                        value.isEnable == 1 &&
                                                        <option value={value.id} key={key}>{value.itemName}</option>
                                                    )
                                                })
                                            }
                                        </Field>
                                    </FormGroup>

                                    {this.renderLocationForm()}
                                    {listingType && <FormGroup className={s.space5}>
                                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                            <FormattedMessage {...messages.cadastralNumber} /> {createNewListing &&
                                            <span
                                                className={s.required}>*</span>}
                                        </ControlLabel>
                                        <Field
                                            name="cadastralNumber"
                                            validate={createNewListing ? [validatePage1.cadastralNumber] : []}
                                            component={this.renderCadastrNumberField}
                                            changeCadastalNumber={this.changeCadastalNumber}
                                            className={cx(s.formControlInput, s.jumboInput)}
                                            label={formatMessage(messages.cadastralNumber)}
                                        />
                                    </FormGroup>}
                                    <Field
                                        name="lat"
                                        type={'hidden'}
                                        component={this.renderFormControl}
                                        className={cx(s.formControlInput, s.jumboInput)}
                                    />
                                    <Field
                                        name="lng"
                                        type={'hidden'}
                                        component={this.renderFormControl}
                                        className={cx(s.formControlInput, s.jumboInput)}
                                    />
                                </div>
                                <FooterButton
                                    isDisabled={isDisabled}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    nextPagePath={this.props.nextPagePath}
                                    previousPagePath={this.props.previousPagePath}
                                    type={createNewListing ? "submit" : null}
                                    formPage={formPage}
                                    step={step}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

Page2 = reduxForm({
    form: 'ListPlaceStep1', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    validate,
    onSubmit: update
})(Page2);

// Decorate with connect to read form values
const selector = formValueSelector('ListPlaceStep1'); // <-- same as form name

const mapState = (state) => ({
    existingList: state.location.isExistingList,
    listingFields: state.listingFields.data,
    isLocationChosen: state.location.isLocationChosen,
    isExistingList: state.location.isExistingList,
    loading: state.loader.location,
    mapUpdateLoading: state.location.mapUpdateLoading,
    listingType: state.listing.listingType,
});

const mapDispatch = {
    updateLocationStatus,
    updateListingMap
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Page2)));
