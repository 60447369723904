import React from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import Header from "../HeaderV2";
import CookiesDisclaimer from "../CookiesDisclaimer";

import s from "./Layout.css";
class NewListLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className={cx(s.overFlowHidden, s.layout)}>
        <Header fixedHeader={true}/>
        <div style={{flexGrow: 1}} className={s.paddingTop}>
            {this.props.children}
        </div>
        <CookiesDisclaimer />
      </div>
    );
  }
}

export default withStyles(s)(NewListLayout);
