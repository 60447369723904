import { gql } from 'react-apollo';

import {
  GET_LISTING_DATA_STEP2_START,
  GET_LISTING_DATA_STEP2_SUCCESS,
  GET_LISTING_DATA_STEP2_ERROR
} from '../constants';

import { initialize } from 'redux-form';

const query = gql`
  query ($listId:String!, $preview: Boolean) {
    UserListing (listId:$listId, preview: $preview) {
      id
      userId
      title
      description
      coverPhoto
      kuulaLink
      settingsData {
        id
        settingsId
        listsettings {
          id
          itemName
          settingsType {
            typeName
            isMultiValue
          }
        }
      }
    }
  }
`;

export function getListingDataStep2(listId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_LISTING_DATA_STEP2_START,
    });

    try {
      // Send Request to get listing data
      const { data } = await client.query({
        query,
        variables: { listId, preview: true },
        fetchPolicy: 'network-only',
      });


      if (data && data.UserListing) {
        const values = {
            id:  data.UserListing.id,
            title: data.UserListing.title,
            description: data.UserListing.description,
            coverPhoto: data.UserListing.coverPhoto,
            kuulaLink: data.UserListing.kuulaLink,
            userId:  data.UserListing.userId,
        }
        if ( data.UserListing.settingsData &&  data.UserListing.settingsData.length > 0) {
            data.UserListing.settingsData.map((item) => {
              console.log(item.listsettings.settingsType.typeName, 'item.listsettings.settingsType.typeName')
              if (item.listsettings.settingsType.isMultiValue) {
                if (values[item.listsettings.settingsType.typeName] === undefined) {
                  values[item.listsettings.settingsType.typeName] = [];
                }
                values[item.listsettings.settingsType.typeName].push(item.settingsId);
              } else {
                values[item.listsettings.settingsType.typeName] = item.settingsId;
              }
            });
        }
        // Reinitialize the form values
        await dispatch(initialize('ListPlaceStep2', values));

        // Dispatch a success action
        dispatch({
          type: GET_LISTING_DATA_STEP2_SUCCESS,
          step2DataIsLoaded: true,
          isExistingList: true,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_LISTING_DATA_STEP2_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}
