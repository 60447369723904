import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {reducer as toastrReducer} from "react-redux-toastr";
import {loadingBarReducer} from "react-redux-loading-bar";

// Internal Reducers
import user from "./user";
import runtime from "./runtime";
import intl from "./intl";
import content from "./content";
import account from "./account";
import siteSettings from "./siteSettings";
import location from "./listYourSpace";
import modalStatus from "./modalReducer";
import listingFields from "./listingFields";
import viewListing from "./viewListing";
import currency from "./currency";
import search from "./search";
import toggle from "./toggle";
import personalized from "./personalized";
import mapListingView from "./mapListingView";
import book from "./book";
import reservation from "./reservation";
import loader from "./loader";
import calendar from "./calendar";
import sticky from "./stickyReducers";
import onChangeListing from "./onChangeListing";
import payout from "./payout";
import regions from "./regions";

// Site Admin
import userManagement from "./siteadmin/users";
import listSettings from "./siteadmin/listSettings";
import adminModalStatus from "./siteadmin/adminModalReducer";
import adminListSettingsData from "./siteadmin/adminListSettingsData";
import popularLocation from "./siteadmin/popularLocation";
import payoutChangeListing from "./payoutChangeListing";
import homeBannerImages from "./siteadmin/homeBannerImages";
import adminPrevileges from "./siteadmin/adminUserReducer";
import image from "./siteadmin/image";
import sideMenu from "./sideMenu";
import tariff from "./tariff";
import listing from "./listing";

export default function createRootReducer({apolloClient}) {
    return combineReducers({
        apollo: apolloClient.reducer(),
        loadingBar: loadingBarReducer,
        user,
        runtime,
        intl,
        siteSettings,
        listing,
        form: formReducer,
        content,
        account,
        userManagement,
        toastr: toastrReducer,
        location,
        modalStatus,
        listSettings,
        adminModalStatus,
        listingFields,
        adminListSettingsData,
        regions,
        viewListing,
        currency,
        search,
        toggle,
        personalized,
        mapListingView,
        book,
        reservation,
        loader,
        calendar,
        sticky,
        onChangeListing,
        payoutChangeListing,
        popularLocation,
        homeBannerImages,
        adminPrevileges,
        payout,
        image,
        sideMenu,
        tariff,
    });
}
