import BaseForm from '../base/BaseForm';
import {reduxForm, Field, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import InputMask from "react-input-mask";
import defaultPic from "../../../../public/SiteImages/large_no_image.jpeg";
import Logo from '../../Logo';
import toolTipIcon from '/public/SiteIcons/listCommonToolTip.svg';
import DayDragCalendar from '../../../components/DayDragCalendar';

class Calendar extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],

        }
    }

    constructor(props) {
        super(props);
        this.state = {
            sources: [],
            isDisabled: true,
            selectedLeaseType: null
        };
    }

    componentDidMount() {
        const {listBlockedPrice, listingFields} = this.props;
        let sources = [];
        let sourceObject = {};

        listBlockedPrice && listBlockedPrice.map((item, key) => {
            sourceObject = {};
            sourceObject["isSpecialPrice"] = item.isSpecialPrice;
            sourceObject["blockedDates"] = item.blockedDates;
            sources.push(sourceObject);
        });
        this.setState({sources});
        if (listingFields != undefined && listingFields.leaseType) {
            const {leaseTypeValue} = this.props;
            const initialLeaseType = leaseTypeValue ||
                (listingFields.leaseType.find(item => !item.parentId)?.id);

            if (initialLeaseType) {
                this.handleLeaseTypeChange(initialLeaseType);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listBlockedPrice} = nextProps;
        let sources = [];
        let sourceObject = {};

        listBlockedPrice && listBlockedPrice.map((item, key) => {
            sourceObject = {};
            sourceObject["isSpecialPrice"] = item.isSpecialPrice;
            sourceObject["blockedDates"] = item.blockedDates;
            sources.push(sourceObject);
        });
        this.setState({sources});

    }

    handleLeaseTypeChange = (selectedLeaseTypeId) => {
        const {listingFields} = this.props;
        const selectedLeaseType = listingFields.leaseType.find(item => item.id === parseInt(selectedLeaseTypeId, 10)) || listingFields.leaseType[0];
        this.setState({selectedLeaseType});
    };

    render() {
        const {formatMessage} = this.props.intl;
        const {error, handleSubmit, submitting, dispatch, nextPage, previousPage, valid} = this.props;
        const {disabledDates, blockedDates, listId} = this.props;
        const {minNight, maxNight, houseRules, checkInEnd, checkInStart} = this.props;
        const {cancellationPolicy, maxDaysNotice, bookingNoticeTime, weeklyDiscount, monthlyDiscount} = this.props;
        const {availableDates, availableDatesPrices, baseCurrency, currency, availableCurrencies} = this.props;
        const {basePrice, cleaningPrice, stepTwoDetails, formPage, step, tax, createNewListing} = this.props;

        const {sources, selectedLeaseType} = this.state;

        let isAdminCurrency;
        isAdminCurrency = availableCurrencies && availableCurrencies.find(o => o.isBaseCurrency == true)
        let title = stepTwoDetails && stepTwoDetails.title;
        let description = stepTwoDetails && stepTwoDetails.description;
        let coverPhoto = stepTwoDetails && stepTwoDetails.coverPhoto;
        let coverImage = stepTwoDetails && stepTwoDetails.listPhotos.find(o => o.id == coverPhoto);
        let path = '/images/upload/x_medium_';
        let showImage;
        let className = 'calendarFooter'
        if (coverImage) {
            showImage = path + coverImage.name;
        } else if (!coverImage && stepTwoDetails && stepTwoDetails.listPhotos && stepTwoDetails.listPhotos.length > 0) {
            showImage = path + (stepTwoDetails && stepTwoDetails.listPhotos && stepTwoDetails.listPhotos[0].name);
        } else {
            showImage = defaultPic;
        }

        return (
            <div className={cx(s.listingForm, 'bgBlackTwo')}>
                <Row>
                    <Col xs={12}>
                        <h3 className={cx(s.sidePanelTitle, s.space6)}><FormattedMessage {...messages.availability} />
                        </h3>
                    </Col>
                    <Col xs={12} className={cx(s.landingContent, 'landingContentRTL')}>
                        <div>
                            <h3 className={s.landingContentTitle}><FormattedMessage {...messages.calendar} /></h3>
                            <div className={s.lableWeight}>
                                <p className={cx(s.bookedWidth)}><span
                                    className={cx(s.notAvailableColor, 'availableRTl')}></span><FormattedMessage {...messages.booked} />
                                </p>
                                <p className={s.calenderColorText}><span
                                    className={cx(s.bookedColor, 'availableRTl')}></span><FormattedMessage {...messages.notAvailable} />
                                </p>
                                <p className={s.calenderColorText}><span
                                    className={cx(s.availableColor, 'availableRTl')}></span><FormattedMessage {...messages.availableLabel} />
                                </p>
                                <p className={s.calenderColorText}><span
                                    className={cx(s.specialColor, 'availableRTl')}></span><FormattedMessage {...messages.sessionPrice} />
                                </p>
                            </div>
                            <div className={s.tipCommonCss}>
                                <img src={toolTipIcon}/>
                                <span
                                    className={cx(s.commonTipCsss, s.calendarNoted, 'textWhite')}><FormattedMessage {...messages.unBlockInfo} /></span>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <FormGroup className={cx(s.formGroup, s.posRelative)}>
                                    {/* <div className={s.imagefeed}>
                      <div className={s.caListimg} style={{ backgroundImage: `url(${showImage})` }}>
                      </div>
                      <div className={s.caListPara}>
                        <h2>{title}</h2>
                        <p>{description}</p>
                      </div>
                    </div> */}
                                    <DayDragCalendar
                                        formName={"ListingNewForm"}
                                        disabledDates={disabledDates}
                                        blockedDates={blockedDates}
                                        listId={listId}
                                        availableDates={availableDates}
                                        availableDatesPrices={availableDatesPrices}
                                        sources={sources}
                                        minNight={minNight}
                                        maxNight={maxNight}
                                        houseRules={houseRules}
                                        checkInEnd={checkInEnd}
                                        checkInStart={checkInStart}
                                        cancellationPolicy={cancellationPolicy}
                                        maxDaysNotice={maxDaysNotice}
                                        bookingNoticeTime={bookingNoticeTime}
                                        baseCurrency={baseCurrency}
                                        currency={currency}
                                        isAdminCurrency={isAdminCurrency}
                                        basePrice={basePrice}
                                        cleaningPrice={cleaningPrice}
                                        tax={tax}
                                        weeklyDiscount={weeklyDiscount}
                                        monthlyDiscount={monthlyDiscount}
                                        transitionDuration={0}
                                        todayLabel={formatMessage(messages.todayLabel)}
                                    />
                                </FormGroup>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

}

Calendar = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Calendar)


// Decorate with connect to read form values
const selector = formValueSelector('ListingNewForm'); // <-- same as form name

const mapState = (state) => ({
    listingFields: state.listingFields.data,
    disabledDates: selector(state, 'disabledDates'),
    blockedDates: selector(state, 'blockedDates'),
    listBlockedPrice: selector(state, 'listBlockedPrice'),
    minNight: selector(state, 'minNight'),
    maxNight: selector(state, 'maxNight'),
    houseRules: selector(state, 'houseRules'),
    checkInStart: selector(state, 'checkInStart'),
    checkInEnd: selector(state, 'checkInEnd'),
    bookingNoticeTime: selector(state, 'bookingNoticeTime'),
    maxDaysNotice: selector(state, 'maxDaysNotice'),
    cancellationPolicy: selector(state, 'cancellationPolicy'),
    availableDates: selector(state, 'availableDates'),
    availableDatesPrices: selector(state, 'availableDatesPrices'),
    currency: selector(state, 'currency'),
    availableCurrencies: state.currency.availableCurrencies,
    basePrice: selector(state, 'basePrice'),
    cleaningPrice: selector(state, 'cleaningPrice'),
    weeklyDiscount: selector(state, 'weeklyDiscount'),
    monthlyDiscount: selector(state, 'monthlyDiscount'),
    tax: selector(state, 'tax'),
    stepTwoDetails: state.calendar.stepTwoDetails,
    listingSteps: state.location.listingSteps,
    leaseTypeValue: selector(state, 'leaseType')
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Calendar)));
