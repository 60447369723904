import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import ExistingPage1 from './ExistingPage1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page5 from './Page5';
import Page6 from './Page6';
import Page7 from './Page7';
import Page8 from './Page8';
import Photos from './Photos';
import Conveniences from "./Conveniences";
import Description from './Description';
import GuestRequirements from './GuestRequirements';
import HouseRules from './HouseRules';
import ReviewGuestBook from './ReviewGuestBook';
import AdvanceNotice from './AdvanceNotice';
import MinMaxNights from './MinMaxNights';
import Calendar from './Calendar';
import Pricing from './Pricing';
import Discount from './Discount';
import Booking from './Booking';
import LocalLaws from './LocalLaws';
import ListIntro from './ListIntro';

import history from '../../core/history';
import getLocation from './getLocation';
import submit from './submit';
import _createDetailedListing from './createDetailedListing';
import _createDetailedListing2 from './createDetailedListing2';
import _createDetailedListing3 from './createDetailedListing3';
import update from './update';
import updateStep2 from './updateStep2';
import updateStep3 from './updateStep3';
import ReservationRule from "./ReservationRule";
import CancellationRule from "./CancellationRule";
import Tariff from "./Tariff";
import {change} from 'redux-form'
import {setListingType} from "../../actions/listing";

class ListPlaceStep1 extends Component {

    static propTypes = {
        listData: PropTypes.object,
        existingList: PropTypes.bool,
        listingSteps: PropTypes.object,
        listId: PropTypes.number,
        formBaseURI: PropTypes.string,
        mode: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.createDetailedListing = this.createDetailedListing.bind(this);
        this.state = {
            page: 'index',
        };
    }

    componentWillUnmount() {
        setListingType({name: 'type', value: undefined,})
        setListingType({name: 'createOnGoing', value: false,})
    }

    createDetailedListing(dispatch) {
        // if (this.props.listingType === 'detailed') {
        if (this.props.forms) {
            let values1 = [], values2 = [], values3 = [];
            if (this.props.forms['ListPlaceStep1'] && this.props.forms['ListPlaceStep1'].values) {
                values1 = this.props.forms['ListPlaceStep1'].values;
            }
            if (this.props.forms['ListPlaceStep2'] && this.props.forms['ListPlaceStep2'].values) {
                values2 = this.props.forms['ListPlaceStep2'].values;
            }
            if (this.props.forms['ListPlaceStep3'] && this.props.forms['ListPlaceStep3'].values) {
                values3 = this.props.forms['ListPlaceStep3'].values;
            }
            return _createDetailedListing(this.props.forms['ListPlaceStep1'].values, dispatch)
                .then(async function (res) {
                    await dispatch(change('ListPlaceStep2', 'id', res.id));
                    await dispatch(change('ListPlaceStep3', 'id', res.id));
                    await _createDetailedListing2({...values2, id: res.id}, dispatch)
                    await _createDetailedListing3({...values3, id: res.id}, dispatch)
                    history.push("/become-a-host/" + res.id + "/calendar");
                })
        }
        // }
    }

    nextPage(formPage) {

        const {listId, existingList, formBaseURI} = this.props;
        let pathName = formBaseURI + formPage;
        if (existingList === true) {
            pathName = formBaseURI + listId + "/" + formPage;
        }
        history.push(pathName);
        this.setState({page: formPage})
    }

    previousPage(formPage) {
        const {listId, existingList, formBaseURI} = this.props;
        let pathName = formBaseURI + formPage;
        if (existingList === true) {
            pathName = formBaseURI + listId + "/" + formPage;
        }
        history.push(pathName);
        this.setState({page: formPage})
    }

    render() {
        const {
            formPage,
            photosCount,
            mode,
            existingList,
            listId,
            baseCurrency,
            step,
            listingType,
            setListingType
        } = this.props;
        const {page} = this.state;
        let createNewListing = false
        let currentPage = page;
        if (mode != undefined && mode === "new") {
            currentPage = 'index';
        } else if (formPage != undefined) {
            currentPage = formPage;
        }
        createNewListing = listingType.type === 'detailed'
        const nextPage = this.nextPage;
        const createDetailedListing = createNewListing ?
            (_, d) => this.createDetailedListing(d) :
            existingList ? update : async (s, d) => {
                await _createDetailedListing(s, d).then(res => {
                    nextPage(res.id + '/calendar')
                })
            };

        const updateDetailedListing2 = createNewListing ? async (_, dispatch) => {
                let values1 = [], values2 = [], values3 = [];
                if (this.props.forms['ListPlaceStep1'] && this.props.forms['ListPlaceStep1'].values) {
                    values2 = this.props.forms['ListPlaceStep2'].values;
                }
                if (this.props.forms['ListPlaceStep2'] && this.props.forms['ListPlaceStep2'].values) {
                    values3 = this.props.forms['ListPlaceStep3'].values;
                }
                await _createDetailedListing2({...values2, id: listId}, dispatch, {updateStep: true})
                await _createDetailedListing3({...values3, id: listId}, dispatch, {updateStep: true})
                setListingType({name: 'type', value: undefined,})
                setListingType({name: 'createOnGoing', value: false,})
                history.push("/become-a-host/" + listId + "/home");
            } :
            listingType.createOnGoing ? async (_, dispatch) => {
                let values1 = [], values2 = [], values3 = [];
                if (this.props.forms['ListPlaceStep1'] && this.props.forms['ListPlaceStep1'].values) {
                    values2 = this.props.forms['ListPlaceStep2'].values;
                }
                if (this.props.forms['ListPlaceStep2'] && this.props.forms['ListPlaceStep2'].values) {
                    values3 = this.props.forms['ListPlaceStep3'].values;
                }
                await _createDetailedListing2({...values2, id: listId}, dispatch, {updateStep: true})
                await _createDetailedListing3({
                    bookingType: values3.bookingType,
                    leaseMaxValue: values3.leaseMaxValue,
                    leaseMinValue: values3.leaseMinValue,
                    id: listId
                }, dispatch, {updateStep: true})
                setListingType({name: 'type', value: undefined,})
                setListingType({name: 'createOnGoing', value: false,})
                history.push("/become-a-host/" + listId + "/home");

            } : updateStep2


        return (

            <div className={'inputFocusColor'}>
                {currentPage === "index" && <ListIntro nextPage={this.nextPage} formPage={formPage} step={step}/>}
                {currentPage === "home" && <ExistingPage1
                    nextPage={this.nextPage}
                    photosCount={photosCount}
                />}

                {currentPage === "room" && <Page2
                    nextPage={this.nextPage}
                    previousPage={this.previousPage}
                    createNewListing={!existingList}
                    nextPagePath={"map"}
                    previousPagePath={existingList ? "home" : "index"}
                    onSubmit={existingList ? update : async function (v, d) {
                        await getLocation(v, d);
                        nextPage("map")
                    }}
                    additionalFields={listingType.type === 'detailed'}
                    formPage={formPage} step={step}
                />}

                {currentPage === "map" && <Page6
                    nextPage={this.nextPage}
                    createNewListing={createNewListing}
                    previousPage={this.previousPage}
                    formPage={formPage} step={step}
                />}

                {currentPage === "bedrooms" && <Page3
                    nextPage={this.nextPage}
                    nextPagePath={createNewListing ? 'conveniences' : 'pricing'}
                    previousPagePath={existingList ? "room" : "room"}
                    previousPage={this.previousPage}
                    createNewListing={createNewListing}
                    additionalFields={listingType.type === 'detailed'}
                    formPage={formPage} step={step}
                />}


                {currentPage === "conveniences" && <Conveniences
                    nextPage={this.nextPage}
                    previousPage={this.previousPage}
                    nextPagePath={listingType.createOnGoing ? 'pricing' : 'description'}
                    previousPagePath={listingType.createOnGoing ? 'bedrooms' : 'photos'}
                    createNewListing={listingType.createOnGoing}
                    additionalFields={listingType.type === 'detailed'}
                    formPage={formPage} step={step}
                />}


                {currentPage === "pricing" && <Pricing
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    formPage={formPage}
                    nextPagePath={listingType.createOnGoing && createNewListing ? 'house-rules' : 'tariff'}
                    previousPagePath={listingType.createOnGoing && createNewListing ? 'conveniences' : 'bedrooms'}
                    createNewListing={listingType.createOnGoing}
                    additionalFields={listingType.type === 'detailed'}
                    step={step}
                />}


                {/* listStep1 */}

                {/* listStep2 */}


                {/* end listStep2 */}
                {/*{currentPage === "location" && <Page5*/}
                {/*  nextPage={this.nextPage}*/}
                {/*  previousPage={this.previousPage}*/}
                {/*  onSubmit={existingList ? update : submit}*/}
                {/*  formPage={formPage} step={step}*/}
                {/*/>}*/}


                {/* start listStep3 */}

                {currentPage === "house-rules" && <HouseRules
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    nextPagePath={'reservation-rule'}
                    previousPagePath={listingType.createOnGoing ? 'pricing' : 'home'}
                    createNewListing={listingType.createOnGoing}
                    additionalFields={listingType.type === 'detailed'}
                    formPage={formPage} step={step}
                />}
                {currentPage === "reservation-rule" && <ReservationRule
                    previousPage={this.previousPage}
                    nextPagePath={'cancellation-rules'}
                    previousPagePath={'house-rules'}
                    createNewListing={listingType.createOnGoing}
                    additionalFields={listingType.type === 'detailed'}
                    nextPage={this.nextPage}
                    formPage={formPage}
                    step={step}
                />}
                {currentPage === "cancellation-rules" && <CancellationRule
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    nextPagePath={listingType.createOnGoing ? 'description' : 'local-laws'}
                    previousPagePath={'reservation-rule'}
                    createNewListing={listingType.createOnGoing}
                    additionalFields={listingType.type === 'detailed'}
                    formPage={formPage}
                    step={step}
                />}

                {currentPage === "local-laws" && <LocalLaws
                    previousPage={this.previousPage}
                    onSubmit={updateStep3}
                    previousPagePath={"calendar"}
                    formPage={formPage} step={step}
                />}

                {currentPage === "description" && <Description
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    createNewListing={listingType.createOnGoing && !createNewListing}
                    onSubmit={updateDetailedListing2}
                    nextPagePath={listingType.createOnGoing ? 'tariff' : 'home'}
                    previousPagePath={listingType.createOnGoing ? 'cancellation-rules' : 'conveniences'}
                    formPage={formPage} step={step}
                />}

                {currentPage === "tariff" && <Tariff
                    nextPage={this.nextPage}
                    previousPage={this.previousPage}
                    nextPagePath={createNewListing ? 'calendar' : 'home'}
                    previousPagePath={createNewListing ? 'description' : 'pricing'}
                    createNewListing={!existingList}
                    onSubmit={createDetailedListing}
                    formPage={formPage} step={step}
                />}


                {currentPage === "calendar" && <Calendar
                    listId={listId}
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    nextPagePath={listingType.createOnGoing ? 'photos' : 'home'}
                    previousPagePath={listingType.createOnGoing ? 'pricing' : 'cancellation-rules'}
                    createNewListing={listingType.createOnGoing}
                    baseCurrency={baseCurrency}
                    formPage={formPage} step={step}
                />}

                {currentPage === "photos" && <Photos
                    previousPage={this.previousPage}
                    listId={listId}
                    createNewListing={listingType.createOnGoing && createNewListing}
                    onSubmit={updateDetailedListing2}
                    nextPagePath={listingType.createOnGoing ? 'description' : 'conveniences'}
                    previousPagePath={listingType.createOnGoing ? 'calendar' : 'home'}
                    nextPage={this.nextPage}
                    formPage={formPage} step={step}
                />}


                {/*{currentPage === "amenities" && <Page7
                    nextPage={this.nextPage}
                    previousPage={this.previousPage}
                    formPage={formPage} step={step}
                />}*/}

                {/* {currentPage === "review-how-guests-book" && <ReviewGuestBook
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    formPage={formPage} step={step}
                />}
                {currentPage === "advance-notice" && <AdvanceNotice
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    formPage={formPage} step={step}
                />}
                {currentPage === "min-max-nights" && <MinMaxNights
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    listId={listId}
                    formPage={formPage} step={step}
                />}*/}


                {/*{currentPage === "discount" && <Discount
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    formPage={formPage} step={step}
                />}*/}
                {/*{currentPage === "booking-scenarios" && <Booking
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    formPage={formPage} step={step}
                />}*/}

            </div>
        );
    }

}


const mapState = (state) => ({
    existingList: state.location.isExistingList,
    listingSteps: state.location.listingSteps,
    photosCount: state.location.photosCount,
    listingType: state.listing,
    forms: state.form
});

const mapDispatch = {
    setListingType
};

export default injectIntl(connect(mapState, mapDispatch)(ListPlaceStep1));
