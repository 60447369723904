import messages from '../../../locale/messages';

export default function validateAbout(values) {
    const errors = {};

    if (!values.houseType) {
        errors.houseType = messages.required;
    }

    if (!values.leaseType) {
        errors.leaseType = messages.required;
    }
    if (!values.leaseMinValue) {
        errors.leaseMinValue = messages.required;
    }
    if (!values.leaseMaxValue) {
        errors.leaseMaxValue = messages.required;
    }


    return errors;
}
