import {gql} from 'react-apollo';

import {
    GET_LISTING_FIELDS_INTIAL_VALUES_START,
    GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS,
    GET_LISTING_FIELDS_INTIAL_VALUES_ERROR
} from '../constants';

import {initialize} from 'redux-form';

const query = gql`
  query($step: String){
    getListingSettings(step: $step) {
      id
      typeName
      fieldType
      typeLabel
      step
      isEnable
      isMultiValue
      listSettings {
        id
        typeId
        itemName
        otherItemName
        maximum
        minimum
        startValue
        endValue
        isEnable
        image
      }
    }
  }
`;

export function getListingFieldsValues(step, listId, isOtherPage) {

    return async (dispatch, getState, {client}) => {

        dispatch({
            type: GET_LISTING_FIELDS_INTIAL_VALUES_START,
        });

        try {

            const step1DataIsLoaded = getState().location.step1DataIsLoaded;
            const step2DataIsLoaded = getState().location.step2DataIsLoaded;
            const step3DataIsLoaded = getState().location.step3DataIsLoaded;
            let listingFieldsValues = {}, bedType, bedTypeValue
            bedType = getState().listingFields.data.bedType;

            // Get Base Currency from Redux Store
            const baseCurrency = getState().currency.base;
            // Send Request to get listing data
            const {data} = await client.query({
                query,
                variables: {step},
                fetchPolicy: 'network-only'
            });

            if (!data && !data.getListingSettings) {
                console.log('Error in Get Listing Fields Values')
                dispatch({
                    type: GET_LISTING_FIELDS_INTIAL_VALUES_ERROR,
                });
            } else {
                data.getListingSettings.map((item, key) => {
                    if (item.fieldType === "numberType") {
                        listingFieldsValues[item.typeName] = item.listSettings.length > 0 ? item.listSettings[0].startValue : null;
                    } else {
                        if (item.isMultiValue === true) {
                            listingFieldsValues[item.typeName] = [];
                        } else {
                            let unSelectIndex = item.listSettings.length > 0 ? item.listSettings.findIndex(o => (Number(o.isEnable) == 1)) : null;
                            console.log([item.typeName, unSelectIndex], 'item.typeName')
                            listingFieldsValues[item.typeName] = !(unSelectIndex === null || unSelectIndex === -1) ? item.listSettings[unSelectIndex].id : '';
                        }

                    }
                })
                // Reinitialize the form values
                if (step === "2" && !step2DataIsLoaded) {
                    let updatedValuesStep2 = Object.assign({}, listingFieldsValues, {id: listId});
                    dispatch(initialize("ListPlaceStep2", updatedValuesStep2, true));
                    dispatch({
                        type: GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS,
                        initialValuesLoadedStep2: true,
                    });
                } else if (step === "3" && !step3DataIsLoaded) {
                    let updatedValuesStep3 = Object.assign({}, listingFieldsValues, {id: listId}, {currency: baseCurrency}, {bookingType: 'instant'}, {maxDaysNotice: 'available'});
                    console.log(updatedValuesStep3, 'updatedValuesStep3')

                    dispatch(initialize("ListPlaceStep3", updatedValuesStep3, true));
                    dispatch({
                        type: GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS,
                        initialValuesLoadedStep3: true,
                    });
                } else {
                    if (!step1DataIsLoaded || isOtherPage) {
                        let updatedValuesStep1 = Object.assign({}, listingFieldsValues, {residenceType: "1", cadastralNumber: ""});
                        dispatch(initialize("ListPlaceStep1", updatedValuesStep1));
                        dispatch({
                            type: GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS,
                            initialValuesLoaded: true
                        });
                    }
                }
            }
        } catch (error) {
            dispatch({
                type: GET_LISTING_FIELDS_INTIAL_VALUES_ERROR,
                payload: {
                    error
                }
            });
            return false;
        }

        return true;
    };
}
