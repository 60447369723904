// Redux Form
import { SubmissionError } from "redux-form";

// Fetch request
import fetch from "../../core/fetch";

// Redux Action
import { getListingDataStep3 } from "../../actions/getListingDataStep3";
import { updateListingSteps } from "../../actions/updateListingSteps";
import { setLoaderStart, setLoaderComplete } from "../../actions/loader/loader";
import { updateCurrentStep } from "../../actions/updateCurrentStep";
// For Redirect
import history from "../../core/history";
// Locale
import messages from "../../locale/messages";

async function  createDetailedListing3(values, dispatch, options = {updateStep: false}) {
  let weeklyDiscount = values?.weeklyDiscount || 0;
  let monthlyDiscount = values?.monthlyDiscount || 0;
  let cleaningPrice = values?.cleaningPrice || 0;
  let tax = values?.tax || 0;

  let id,
    houseRules,
    bookingNoticeTime,
    checkInStart,
    checkInEnd,
    maxDaysNotice,
    minNight,
    maxNight,
    basePrice,
    currency,
    blockedDates,
    bookingType,
    cancellationPolicy,
      leaseMinValue,
      leaseMaxValue,
    tariff_id;

  id = values?.id;
  houseRules = values?.houseRules;
  bookingNoticeTime = values?.bookingNoticeTime;
  checkInStart = values?.checkInStart;
  checkInEnd = values?.checkInEnd;
  maxDaysNotice = values?.maxDaysNotice;
  minNight = values?.minNight;
  maxNight = values?.maxNight;
  basePrice = values?.basePrice;
  currency = values?.currency;
  blockedDates = values?.blockedDates;
  bookingType = values?.bookingType;
  cancellationPolicy = values?.cancellationPolicy;
  tariff_id = values?.tariff_id;
    leaseMaxValue = values?.leaseMaxValue;
    leaseMinValue = values?.leaseMinValue;

  let variables = Object.assign(
    {},
    {
      weeklyDiscount,
      monthlyDiscount,
      cleaningPrice,
      tax,
      id,
      houseRules,
      bookingNoticeTime,
      checkInStart,
      checkInEnd,
      maxDaysNotice,
      minNight,
      maxNight,
      blockedDates,
      bookingType,
      cancellationPolicy,
        leaseMinValue,
        leaseMaxValue,
    }
  );

  dispatch(setLoaderStart("updateListing"));
  const query = `query (
  	$id: Int,
    $houseRules: [Int],
    $bookingNoticeTime:String,
    $checkInStart:String,
    $checkInEnd:String,
    $maxDaysNotice:String,
    $minNight:Int,
    $maxNight:Int,
    $cleaningPrice:Float,
    $tax:Float,
    $weeklyDiscount:Int,
    $monthlyDiscount:Int,
    $blockedDates: [String],
    $bookingType: String!,
    $cancellationPolicy: Int,
    $leaseMinValue:Int,
    $leaseMaxValue:Int,
    
  ) {
      updateListingStep3 (
        id: $id,
        houseRules: $houseRules,
        bookingNoticeTime:$bookingNoticeTime,
        checkInStart:$checkInStart,
        checkInEnd:$checkInEnd,
        maxDaysNotice:$maxDaysNotice,
        minNight:$minNight,
        maxNight:$maxNight,
        cleaningPrice:$cleaningPrice,
        tax:$tax,
        weeklyDiscount:$weeklyDiscount,
        monthlyDiscount:$monthlyDiscount,
        blockedDates: $blockedDates,
        bookingType: $bookingType,
        cancellationPolicy: $cancellationPolicy,
        leaseMinValue: $leaseMinValue,
        leaseMaxValue: $leaseMaxValue
         
      ) {
        status
      }
    }`;

  const resp = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
      variables,
    }),
    credentials: "include",
  });

  const { data } = await resp.json();

    console.log(data, variables, 'test')

  if (data?.updateListingStep3 != undefined) {
    if (data?.updateListingStep3.status == "success") {
        if (options.updateStep) {
            await dispatch(updateListingSteps(values.id, 3));
        }
      await dispatch(setLoaderComplete("updateListing"));
    } else if (data?.updateListingStep3.status == "notLoggedIn") {
      dispatch(setLoaderComplete("updateListing"));
      throw new SubmissionError({ _error: messages.notLoggedIn });
    } else {
      dispatch(setLoaderComplete("updateListing"));
      throw new SubmissionError({ _error: messages.somethingWentWrong });
    }
  } else {
    dispatch(setLoaderComplete("updateListing"));
    throw new SubmissionError({ _error: messages.somethingWentWrong });
  }
}

export default createDetailedListing3;
