import messages from '../../../locale/messages';

const validatePage1 = {
  cadastralNumber: (value) => {
    if (!value || (value.replaceAll(':', '').length < 14)) {
      return messages.required;
    }
  },
  tariff: (value) => {
    if (!value) {
      return messages.required;
    }
  }
}

export default validatePage1
