// Redux Form
import {change} from 'redux-form';

// Fetch request
import fetch from '../../../core/fetch';

// Locale
import messages from '../../../locale/messages';

// For Redirect
import history from '../../../core/history';
import showToaster from "../../../helpers/showToaster";
import {setLoaderComplete, setLoaderStart} from "../../../actions/loader/loader";
const checkMapQuery = `
  query ($address: String) {
    locationItem(address: $address) {
      lat
      lng
      status
    }
  }
`;
async function getLocation(values, dispatch) {
    let address = `${values.street},${values.district},${values.state},${values.country},`;
    dispatch(setLoaderStart('location'));

    const mapResp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: checkMapQuery,
            variables: { address }
        }),
        credentials: 'include'
    });

    const checkMapResponse = await mapResp.json();
    if (checkMapResponse && checkMapResponse.data && checkMapResponse.data.locationItem && checkMapResponse.data.locationItem.status !== 200) {
        showToaster({ messageId: 'invalidAddress', toasterType: 'error' })
        return Promise.reject(false);
    } else {
        await dispatch(setLoaderComplete('location'));
        return Promise.resolve(checkMapResponse.data)
    }
}

export default getLocation;
