import {SubmissionError} from 'redux-form';
import fetch from '../../core/fetch';

import messages from '../../locale/messages';
import history from '../../core/history';
import {getListingData} from '../../actions/getListing';
import {updateListingSteps} from '../../actions/updateListingSteps';
import {setLoaderStart, setLoaderComplete} from '../../actions/loader/loader';
import showToaster from '../../helpers/showToaster';
import {updateCurrentStep} from '../../actions/updateCurrentStep';

async function createDetailedListing(values, dispatch) {
    let variables = Object.assign({}, values);
    const checkMapQuery = `
        query ($address: String) {
        locationItem(address: $address) {
          lat
          lng
          status
        }
        }
        `;
    let address = `${values.street},${values.district},${values.city},${values.state},${values.country},`;

    const mapResp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: checkMapQuery,
            variables: {address}
        }),
        credentials: 'include'
    });

    const checkMapResponse = await mapResp.json();
    if (checkMapResponse && checkMapResponse.data && checkMapResponse.data.locationItem && checkMapResponse.data.locationItem.status !== 200) {
        showToaster({messageId: 'invalidAddress', toasterType: 'error'})
        return;
    } else {
        dispatch(setLoaderStart('location'));
        variables.lat = checkMapResponse.data.locationItem.lat;
        variables.lng = checkMapResponse.data.locationItem.lng;
        if (variables.cadastralNumber) {
            variables.cadastralNumber = variables.cadastralNumber.replaceAll(':', '')
        }
        const query = `query (
            $houseType:String,
            $country:String,
            $street:String,
            $buildingName:String,
            $city:String,
            $state:String,
            $district:String,
            $lat:Float,
            $lng:Float,
            
            $leaseType: Int
            $livingArea: Float
            $tariff_id: String,
            $floors: String
            $rooms: Int
            $basePrice: Float
            $currency: String
            $paymentTypes: [Int]
            $publicUtilities: [Int]
            $publicUtilitiesType: Int
            $layoutType: Int
            $repairType: Int
            $wallMaterial: Int
            $ceilingHeight: Int
            $elevatorType: Int
            $bedrooms: Int,
            $bathrooms:Float,
            $bathroomType: Int,
            $cadastralNumber: String
          ) {
              createListing (
                houseType:$houseType,
                country: $country
                street: $street
                buildingName: $buildingName
                city: $city
                state: $state
                lat: $lat,
                lng: $lng,
                leaseType: $leaseType,
                district: $district,
                publicUtilities: $publicUtilities,
                paymentTypes: $paymentTypes,
                basePrice: $basePrice,
                currency: $currency,
                floors: $floors,
                rooms: $rooms,
                livingArea: $livingArea,
                publicUtilitiesType: $publicUtilitiesType,
                tariff_id: $tariff_id,
                bedrooms: $bedrooms,
                bathrooms: $bathrooms,
                bathroomType: $bathroomType,
                layoutType: $layoutType,
                repairType: $repairType,
                wallMaterial: $wallMaterial,
                ceilingHeight: $ceilingHeight,
                elevatorType: $elevatorType
                cadastralNumber: $cadastralNumber
              ) {
                status
                id
              }
        }`;

        const resp = await fetch('/graphql', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: query,
                variables: variables
            }),
            credentials: 'include'
        });

        const {data} = await resp.json();

        if (data.createListing.status == "success") {
            await dispatch(updateListingSteps(data.createListing.id, 1, 'completed'));
            dispatch(setLoaderComplete('location'));
            await dispatch(setLoaderComplete('location'));
            return Promise.resolve({id: data.createListing.id});
        } else if (data.createListing.status == "notLoggedIn") {
            throw new SubmissionError({_error: messages.notLoggedIn});
        } else if (data.createListing.status == "adminLoggedIn") {
            throw new SubmissionError({_error: messages.adminLoggedIn});
        } else {
            throw new SubmissionError({_error: messages.somethingWentWrong});
        }
    }

}

export default createDetailedListing;
