import messages from '../../locale/messages';

const validateStep2 = values => {

  const errors = {}


  if (!values.rooms) errors.rooms = messages.required;

  if (!values.rooms || (values.rooms && values.rooms.toString().trim() == '')) {
    errors.rooms = messages.required;
  }
  if (!values.livingArea) errors.livingArea = messages.required;

  if (isNaN(values.livingArea) || (!/^\d+(\.\d+)?$/.test(values.livingArea))) {
    errors.livingArea = messages.onlyNumericKey;
  }
  if (!values.totalArea) errors.totalArea = messages.required;

  if (isNaN(values.totalArea) || (!/^\d+(\.\d+)?$/.test(values.totalArea))) {
    errors.totalArea = messages.onlyNumericKey;
  }

  if (!values.floors) errors.floors = messages.required;

  if (isNaN(values.floors) || (!/^\d+(\.\d+)?$/.test(values.floors)) || (parseInt(values.floors, 10) < 1)) {
    errors.floors = messages.onlyNumericKey;
  }
  if (!values.floor) errors.floor = messages.required;

  if (isNaN(values.floor) || (!/^\d+(\.\d+)?$/.test(values.floor)) || (parseInt(values.floor, 10) < 1)) {
    errors.floor = messages.onlyNumericKey;
  }

  return errors
}

export default validateStep2
