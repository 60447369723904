import messages from '../../../locale/messages';

export default function validateLocations(values) {
    const errors = {};

    if (!values.country || (values.country && values.country.toString().trim() == '')) {
        errors.country = messages.required;
    }

    if (!values.state || (values.state && values.state.toString().trim() == '')) {
        errors.state = messages.required;
    }

    if (!values.street || (values.street && values.street.toString().trim() == '')) {
        errors.street = messages.required;
    }

    if (!values.district_id || (values.district_id && values.district_id.toString().trim() == '')) {
        errors.district_id = messages.required;
    }

    return errors;
}
