import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Layout.css';
import HeaderV2 from '../HeaderV2';
import Feedback from '../Feedback';
import Footer from '../Footer';
import FooterToggle from '../FooterToggle';
import cx from 'classnames';
import CookiesDisclaimer from '../CookiesDisclaimer';
import MobileMenu from '../MobileMenu'

class Layout extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        page: PropTypes.string
    };

    static defaultProps = {
        page: ''
    };

    render() {
        const {page, className, fixedHeader} = this.props;

        return (
            <div className={s.layout}>
                <HeaderV2 homeHeaderOnly={true} page={page}/>
                <div style={{flexGrow: 1}}>
                    {this.props.children}
                </div>
                {/*<div className={cx('hidden-xs', { [s.searchFooter]: page === 'search' }, className)}>*/}
                <FooterToggle/>
                <Footer/>
                {/*</div>*/}
                <MobileMenu/>
                <CookiesDisclaimer/>
            </div>
        );
    }
}

export default withStyles(s)(Layout);
