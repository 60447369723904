// General
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Redux Form
import {Field, reduxForm, formValueSelector, FieldArray, change} from 'redux-form';
// Redux
import {connect} from 'react-redux';
// Translation
import {injectIntl, FormattedMessage} from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    FormGroup,
    ControlLabel,
    FormControl, Row, Col, Nav, NavItem, Tab
} from 'react-bootstrap';

// Internal Component
import IncrementButton from '../IncrementButton';
import IncrementBtn from './IncrementBtn';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

// Style
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';
import update from './update';
import messages from '../../locale/messages';
import validate from "./validate";
import validateStep3 from "./validateStep3";
import updateStep3 from "./updateStep3";

class CancellationRule extends Component {

    static propTypes = {
        initialValues: PropTypes.object,
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        isExistingList: PropTypes.bool,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
    };

    static defaultProps = {
        nextPagePath: 'calendar',
        previousPagePath: 'reservation-rule',
    }


    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
        }
    }


    componentDidMount() {
        const {valid, leaseTypeValue} = this.props;

        // Set initial filter based on first lease type if no value is selected
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listingFields, valid} = nextProps;
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }

    radioGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable === "1") {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: parseInt(input.value) === option.id},
                                        {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                         onClick={() => {
                                             input.onChange(option.id);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }
    renderFields = ({input, cancellationPolicies}) => {
        const {formatMessage} = this.props.intl;

        function handleSelect(policy) {
            const selectedPolicy = cancellationPolicies.find(item => item.id === policy.id);
            if (selectedPolicy) {
                return (
                    <div className={s.policyDetails}>
                        <h3 className={s.detailsTitle}>
                            {selectedPolicy.name}
                        </h3>

                        <div className={s.detailItem}>
                            <span className={s.detailIcon}>{selectedPolicy.policyContent}</span>
                        </div>
                        <div className={s.detailItem}>
                            <span>{selectedPolicy.subContent}</span>
                        </div>
                    </div>
                )
            }
            return (<div></div>)

        }

        return (
            <div>
                <h2 className={s.title}>{formatMessage(messages.chooseCancellationPolicy)}</h2>
                <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                    {cancellationPolicies.map((option, index) => {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div
                                        className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                            {[s.checked]: parseInt(input.value) === option.id},
                                            {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                        onClick={() => {
                                            input.onChange(option.id);

                                        }}>
                                        < div className={s.checkoutText}>{option.policyName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                    }
                </ul>

                {input.value && handleSelect(cancellationPolicies.find(item => item.id === input.value))}
            </div>
        )
    }


    render() {
        const {
            handleSubmit,
            previousPage,
            nextPage,
            isExistingList,
            formPage,
            step,
            cancellationPolicies,
            _listingType
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {isDisabled} = this.state;
        let listingType = _listingType === 'detailed';
        // let path = isExistingList ? "map" : "location";
        return (
            <div className={s.grid}>
                <SidePanel
                    title={formatMessage(messages.stepThreeCommonHeading)}
                    landingContent={formatMessage(messages.cancellationPolicy)}
                />
                <div className={cx(s.landingMainContent)}>
                    <form onSubmit={handleSubmit}>
                        <FormGroup className={s.space5}>
                            <Field
                                name="cancellationPolicy"
                                component={this.renderFields}
                                className={cx(s.formControlInput, s.jumboInput)}
                                cancellationPolicies={cancellationPolicies}
                                label={formatMessage(messages.cancellationPolicy)}
                            ></Field>
                        </FormGroup>
                        <FooterButton
                            isDisabled={isDisabled}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            nextPagePath={this.props.nextPagePath}
                            previousPagePath={this.props.previousPagePath}
                            formPage={formPage}
                            step={step}
                        />
                    </form>

                </div>
            </div>
        )
    }
}


CancellationRule = reduxForm({
    form: 'ListPlaceStep3', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: updateStep3
})(CancellationRule);

// Decorate with connect to read form values
const selector = formValueSelector('ListPlaceStep3'); // <-- same as form name

const mapState = (state) => ({
    isExistingList: state.location.isExistingList,
    listingFields: state.listingFields.data,
    _listingType: state.listing.listingType,
    cancellationPolicies: state.location.cancellationPolicies || [],
});

const mapDispatch = {
    change,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(CancellationRule)));
