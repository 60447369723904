exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MobileMenu-mobile-nav-3-ce_ {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    border-top: 1px solid #e5e5e5;\n    background-color: white;\n}\n\n.MobileMenu-mobile-nav__container-vq-QG {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    height: 64px;\n    height: 4rem;\n}\n\n.MobileMenu-mobile-nav__item-2sYGY {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    gap: 2px;\n    gap: 0.125rem;\n    text-decoration: none;\n    color: #4b5563;\n    -webkit-transition: color 0.3s ease;\n    -o-transition: color 0.3s ease;\n    transition: color 0.3s ease;\n}\n\n.MobileMenu-mobile-nav__item-2sYGY:hover {\n    color: #111827;\n}\n\n.MobileMenu-mobile-nav__item--active-3k8gs {\n    color: #ef4444;\n}\n\n.MobileMenu-mobile-nav__icon-1-4Hj {\n    width: 20px;\n    width: 1.25rem;\n    height: 20px;\n    height: 1.25rem;\n    -webkit-transition: -webkit-transform 0.3s ease;\n    transition: -webkit-transform 0.3s ease;\n    -o-transition: transform 0.3s ease;\n    transition: transform 0.3s ease;\n    transition: transform 0.3s ease, -webkit-transform 0.3s ease;\n}\n\n.MobileMenu-mobile-nav__item--active-3k8gs .MobileMenu-mobile-nav__icon-1-4Hj {\n    -webkit-transform: scale(1.1);\n        -ms-transform: scale(1.1);\n            transform: scale(1.1);\n    fill: #ef4444;\n}\n\n.MobileMenu-mobile-nav__text-2Yzcc {\n    font-size: 14px;\n    font-size: 0.875rem;\n}\n\n@media (min-width: 768px) {\n    .MobileMenu-mobile-nav-3-ce_ {\n        display: none;\n    }\n}\n\n", ""]);

// exports
exports.locals = {
	"mobile-nav": "MobileMenu-mobile-nav-3-ce_",
	"mobile-nav__container": "MobileMenu-mobile-nav__container-vq-QG",
	"mobile-nav__item": "MobileMenu-mobile-nav__item-2sYGY",
	"mobile-nav__item--active": "MobileMenu-mobile-nav__item--active-3k8gs",
	"mobile-nav__icon": "MobileMenu-mobile-nav__icon-1-4Hj",
	"mobile-nav__text": "MobileMenu-mobile-nav__text-2Yzcc"
};