import React from 'react';
import PropTypes from 'prop-types';
import {formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
import {FormattedMessage, injectIntl} from 'react-intl';

import CurrencyConverter from '../../CurrencyConverter';
import ViewCount from '../ViewCount';
import BookingForm from './BookingForm';
import Loader from '../../Loader';

import messages from '../../../locale/messages';

import s from './Calendar.css';
import {
    Label,
    Overlay,
    Tooltip
} from "react-bootstrap";
import shareIcon from '/public/SiteIcons/viewListingShareIcon.svg';
import WishListIcon from '../../WishListIcon';
import {openSocialShareModal} from "../../../actions/modalActions";
import moment from "moment/moment";
import * as ReactDOM from 'react-dom';
import Faq from "../../../../public/SiteIcons/question.svg";

class Calendar extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        personCapacity: PropTypes.number.isRequired,
        country: PropTypes.string,
        data: PropTypes.object,
        listingData: PropTypes.shape({
            basePrice: PropTypes.number.isRequired,
            cleaningPrice: PropTypes.number,
            tax: PropTypes.number,
            currency: PropTypes.string,
            monthlyDiscount: PropTypes.number,
            weeklyDiscount: PropTypes.number,
            minNight: PropTypes.number,
            maxNight: PropTypes.number,
            maxDaysNotice: PropTypes.string,
        }),
        isLoading: PropTypes.bool,
        loading: PropTypes.bool,
        blockedDates: PropTypes.array,
        isHost: PropTypes.bool.isRequired,
        bookingType: PropTypes.string.isRequired,
        formatMessage: PropTypes.any,
        userBanStatus: PropTypes.number,
        reviewsCount: PropTypes.number.isRequired,
        reviewsStarRating: PropTypes.number.isRequired,
    };
    static defaultProps = {
        isLoading: false,
        loading: false,
        blockedDates: [],
        isHost: false,
        data: {},
        listingData: {
            basePrice: 0, cleaningPrice: 0, tax: 0, monthlyDiscount: 0,
            weeklyDiscount: 0, minNight: 0, maxNight: 0
        },
        country: ''
    };

    constructor(props) {
        super(props);
        this.getTarget = this.getTarget.bind(this)
        this.handleTooltip = this.handleTooltip.bind(this)
        this.state = {
            tooltipText: '',
            showTooltip: false
        }
    }

    getTarget() {
        return ReactDOM.findDOMNode(this.target);
    }

    handleTooltip(show, message) {
        this.setState({showTooltip: show, tooltipText: message});
    }

    render() {
        const {id, personCapacity, isLoading, isHost, userBanStatus, bookingType} = this.props;
        const {
            listingData: {
                basePrice,
                cleaningPrice,
                tax,
                currency,
                monthlyDiscount,
                weeklyDiscount,
                maxDaysNotice,
                bookingNoticeTime,
                taxRate,
                monthlyPrice,
                deposit
            }
        } = this.props;
        const {loading, blockedDates, startDate, endDate, guests, country, averageBasePrice} = this.props;
        const {reviewsCount, reviewsStarRating, base, toCurrency, formStartDate} = this.props;
        const {data: {tariff}} = this.props
        const {
            listingData: {
                totalArea, floor, floors, leaseMinValue, leaseMaxValue
            }, features
        } = this.props;
        const {formatMessage} = this.props.intl;

        let loadingStatus = loading || isLoading || false, starRatingValue = 0;
        let initialValues = {
            startDate,
            endDate,
            guests
        }

        if (reviewsCount > 0 && reviewsStarRating > 0) {
            starRatingValue = Math.round(reviewsStarRating / reviewsCount)
        }

        let leastBasePrice = averageBasePrice ? averageBasePrice : (basePrice ? basePrice : 0);

        const roomName = features?.rooms && isNaN(Number(features.rooms.listsettings.itemName)) ? features.rooms.listsettings.itemName : features.rooms.listsettings.itemName + '-' + formatMessage(messages.rooms).toLocaleLowerCase();
        const name = `${totalArea} ${formatMessage(messages.areaSymbol)}, ${roomName}, ${floor}/${floors} ${formatMessage(messages.floorsShort).toLowerCase()}`;
        const leaseType = features.leaseType
        const paymentType = features.paymentTypes
        const bookingGoals = features.bookingGoals
        const publicUtilitiesType = features.publicUtilitiesType

        let minNight, maxNight, periodMessage;

        const daysFormat = formatMessage(messages.day, {value: leaseMinValue})
        const monthFormat = formatMessage(messages.month_, {value: leaseMinValue})

        const tags = []

        const isMonthlyRent = features.leaseType.some(item => item.listsettings.otherItemName === 'month')
        const onlyMonthlyRent = features.leaseType.every(item => item.listsettings.otherItemName === 'month')
        const isDailyRent = features.leaseType.some(item => item.listsettings.otherItemName === 'day')
        const onlyDailyRent = features.leaseType.every(item => item.listsettings.otherItemName === 'day')
        const sharedProps = {
            container: this,
            target: this.getTarget,
            show: this.state.showTooltip
        };
        tags.push(
            {
                name: formatMessage(messages.rentTag, {
                    value: leaseMinValue,
                    value2: onlyMonthlyRent ? monthFormat : daysFormat,
                    value1: isMonthlyRent ? formatMessage(messages.month_, {value: leaseMaxValue}) : formatMessage(messages.day, {value: leaseMaxValue})
                }),
            }
        )

        if (paymentType) {
            paymentType.forEach(item => {
                tags.push({
                    name: item.listsettings.itemName
                })
            })
        }
        if (bookingGoals) {
            bookingGoals.forEach(item => {
                tags.push({
                    name: item.listsettings.itemName
                })
            })
        }

        if (onlyDailyRent || isDailyRent) {
            minNight = leaseMinValue;
            periodMessage = formatMessage(messages.night)
        }
        if (onlyMonthlyRent) {
            const today = formStartDate || moment();
            let minPeriod = today.date() === 1 ? leaseMinValue - 1 : leaseMinValue,
                maxPeriod = today.date() === 1 ? leaseMaxValue - 1 : leaseMaxValue;
            let minMonth = moment(today).add(minPeriod, 'months')
            let maxMonth = moment(today).add(maxPeriod, 'months')

            if (today.date() === 1) {
                minNight = moment(minMonth).date(minMonth.endOf('months').date()).diff(today, 'days');
                maxNight = moment(maxMonth).date(maxMonth.endOf('months').date()).diff(today, 'days');
            } else {

                minNight = moment(minMonth).diff(moment(today), 'days')
                maxNight = moment(maxMonth).diff(moment(today), 'days')
            }
            periodMessage = formatMessage(messages.month)
        }
        if (isMonthlyRent) {
            const today = formStartDate || moment();
            let maxPeriod = today.date() === 1 ? leaseMaxValue - 1 : leaseMaxValue;
            let maxMonth = moment(today).add(maxPeriod, 'months')

            if (today.date() === 1) {
                maxNight = moment(maxMonth).date(maxMonth.endOf('months').date()).diff(today, 'days');
            } else {
                maxNight = moment(maxMonth).diff(moment(today), 'days')
            }
        } else {
            maxNight = leaseMaxValue;
        }

        if (bookingType === "instant") {
            tags.push({
                name: formatMessage(messages.instantBook)
            })
        } else if (bookingType === "request") {
            tags.push({
                name: formatMessage(messages.requestToBook)
            })
        } else {
            tags.push({
                name: formatMessage(messages.limitedToBook)
            })
        }
        const {openSocialShareModal, data} = this.props;
        let isListOwner = data.isListOwner;
        let wishListStatus = data.wishListStatus;
        return (
            <div className={cx(s.bookItContainer, 'bookItContentCommon', 'modalMarginTop')}>
                <div className={cx(s.bookItContentBox, 'bgBlack')} data-sticky-section>
                    <div className={cx('borderRadiusNone', 'textWhite')}>
                        <div className={cx(s.listingTitle)}>
                            <div className={cx(s.sectionTitleText)}>
                                {name}
                            </div>
                            <div className={cx(s.saveButtonPosition, 'saveButtonPositionRtl')}>
                                {
                                    !isListOwner && !loading && <div className={cx(s.displayInline, 'shareBtnRtl')}>
                                        <WishListIcon type="button" listId={id} key={id}
                                                      isChecked={wishListStatus} isViewListing={true}/>
                                    </div>
                                }
                                {
                                    <div
                                        className={cx(s.buttonContainer, 'bgBlack', 'textWhite', 'buttonContainerRTL', {[s.mobilePaddingRemove]: isListOwner})}
                                        onClick={openSocialShareModal}
                                    >
                                        <span className={cx(s.vtrMiddle, s.imgCss, 'svgImg')}><img
                                            src={shareIcon}/></span>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className={cx(s.noPadding, s.mobileBg, s.calendarTableCell)}>


                            <div className={cx(s.bookItPriceAmount, s.currenyMarginR, 'currenyMarginRtl')}>
                                <CurrencyConverter
                                    amount={leastBasePrice}
                                    className={s.bookItPrice}
                                    from={currency}
                                />
                                <span className={cx("visible-xs", s.mobileRight)}> / {periodMessage} /></span>
                                <span className="hidden-xs"> / {periodMessage}</span>
                                {
                                    bookingType === "instant" && <span>
                                        <FontAwesome.FaBolt className={s.instantIcon}/>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={cx(s.bootItPriceSection, 'borderRadiusNone', 'textWhite', s.spaceTop4, s.space4)}>
                        <div className={cx(s.noPadding, s.mobileBg, s.calendarTableCell)}>
                            <div className={s.tags}
                                 style={{ position: 'relative' }}
                            >
                                {tags.map((item, index) => {
                                    return <Label
                                        key={item.name} className={cx(s.tagsItem)}
                                        bsStyle="default">{item.name}
                                        {item.description && item.description.length > 0 && <div className={cx(s.specialPriceIcon, 'specialpriceRtl')}>
                                                    <span>
                                                        <img src={Faq} className={cx(s.faqImage, 'specialpriceRtl')}/>
                                                    </span>
                                            <div
                                                className={cx(s.tltip, s.relativeSection, 'tltipDarkMode', 'relativeSectionRTL')}>
                                                {item.description}
                                            </div>
                                        </div>}
                                    </Label>
                                })}
                            </div>


                        </div>
                    </div>
                    <div className={cx('bookItFormSection')}>
                        <Loader
                            show={loadingStatus}
                            type={"page"}

                        >
                            <div className={cx(s.bookItPanel, 'borderRadiusNone', 'bookItPanelRtl')}>
                                <BookingForm
                                    initialValues={initialValues}
                                    id={id}
                                    personCapacity={personCapacity}
                                    basePrice={basePrice}
                                    monthlyPrice={monthlyPrice}
                                    cleaningPrice={cleaningPrice}
                                    tariff={tariff}
                                    tax={tax}
                                    bookingGoals={bookingGoals}
                                    leaseType={leaseType}
                                    paymentType={paymentType}
                                    deposit={deposit}
                                    publicUtilitiesType={publicUtilitiesType}
                                    currency={currency}
                                    monthlyDiscount={monthlyDiscount}
                                    weeklyDiscount={weeklyDiscount}
                                    minNight={minNight}
                                    maxNight={maxNight}
                                    blockedDates={blockedDates}
                                    isHost={isHost}
                                    userBanStatus={userBanStatus}
                                    bookingType={bookingType}
                                    maxDaysNotice={maxDaysNotice}
                                    startDate={startDate}
                                    endDate={endDate}
                                    taxRate={taxRate}
                                    guests={guests}
                                    country={country}
                                />
                                <div>
                                    <FormGroup
                                        className={cx(s.noMargin, s.textMuted, 'text-center', s.smallText, 'textWhite')}>
                                        <FormattedMessage {...messages.bookingInfo} />
                                    </FormGroup>
                                </div>
                            </div>

                        </Loader>
                    </div>
                </div>
                <ViewCount
                    listId={id}
                    isHost={isHost}
                />
            </div>
        );
    }
}

const selector = formValueSelector('BookingForm');

const mapState = (state) => ({
    isLoading: state.viewListing.isLoading,
    averageBasePrice: selector(state, 'averageBasePrice'),
    base: state.currency.base,
    toCurrency: state.currency.to,
    formStartDate: selector(state, 'startDate'),
});
const mapDispatch = {
    openSocialShareModal

};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Calendar)))
