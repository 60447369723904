// Redux Form
import {SubmissionError} from 'redux-form';

// Fetch request
import fetch from '../../core/fetch';

// Locale
import messages from '../../locale/messages';


// Redux Action
import {getListingData} from '../../actions/NewListing/getListing';
import {manageListingSteps} from '../../actions/manageListingSteps';
import {getListingFieldsValues} from '../../actions/NewListing/getListingFieldsValues';
import {setLoaderStart, setLoaderComplete} from '../../actions/loader/loader';

// For Redirect
import history from '../../core/history';

async function update(values, dispatch) {

    let bedTypes = JSON.stringify(values.bedTypes);
    let variables = Object.assign({}, values, {bedTypes});
    dispatch(setLoaderStart('updateListing'));
    const query = `query (
  	$id: Int,
    $roomType:String,
    $houseType:String,
    $residenceType:String,
    $bedrooms:String,
    $buildingSize:String,
    $beds:Int,
    $personCapacity:Int,
    $bathrooms:Float,
    $bathroomType:String,
    $country:String,
    $street:String,
    $buildingName:String,
    $city:String,
    $state:String,
    $zipcode:String,
  	$lat: Float,
  	$lng: Float,
  	$isMapTouched: Boolean,
    $amenities: [Int],
    $safetyAmenities: [Int],
    $spaces: [Int],
    $bedTypes: String,
    $leaseType: Int,
    $publicUtilities: [Int],
    $paymentTypes: [Int],
    $basePrice: Float,
    $currency: String,
    $floors: String,
    $rooms: Int,
    $publicUtilitiesType: Int,
    $livingArea: Float,
    $tariff_id: String,
    $cadastralNumber: String,
    $repairType: Int,
    $wallMaterial: Int,
    $ceilingHeight: Int,
    $elevatorType: Int,
    $layoutType: Int,
    $floor: String,
    $totalArea: Float
    
  ) {
      updateListing (
        id: $id,
        roomType:$roomType,
        houseType:$houseType,
        residenceType: $residenceType,
        bedrooms: $bedrooms,
        buildingSize: $buildingSize
        beds: $beds
        personCapacity: $personCapacity
        bathrooms: $bathrooms
        bathroomType: $bathroomType
        country: $country
        street: $street
        buildingName: $buildingName
        city: $city
        state: $state
        zipcode: $zipcode
        lat: $lat
        lng: $lng
        isMapTouched: $isMapTouched,
        amenities: $amenities,
        safetyAmenities: $safetyAmenities,
        spaces: $spaces,
        bedTypes: $bedTypes,
        leaseType: $leaseType,
        publicUtilities: $publicUtilities,
        paymentTypes: $paymentTypes,
        basePrice: $basePrice,
        currency: $currency,
        floors: $floors,
        rooms: $rooms,
        livingArea: $livingArea,
        publicUtilitiesType: $publicUtilitiesType,
        tariff_id: $tariff_id,
        cadastralNumber: $cadastralNumber,
        repairType: $repairType,
        wallMaterial: $wallMaterial,
        ceilingHeight: $ceilingHeight,
        elevatorType: $elevatorType,
        layoutType: $layoutType,
        floor: $floor,
        totalArea: $totalArea
      ) {
        status
      }
    }`;

    if (variables.cadastralNumber) {
        variables.cadastralNumber = variables.cadastralNumber.replaceAll(':', '')
    }

    const resp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables
        }),
        credentials: 'include'
    });

    const {data} = await resp.json();

    if (data.updateListing.status == "success") {
        await history.push('/become-a-host/' + values.id + '/home');
        await dispatch(getListingData(values.id));
        await dispatch(setLoaderComplete('updateListing'));

    } else if (data.updateListing.status == "notLoggedIn") {
        dispatch(setLoaderComplete('updateListing'));
        throw new SubmissionError({_error: messages.notLoggedIn});
    } else {
        dispatch(setLoaderComplete('updateListing'));
        throw new SubmissionError({_error: messages.somethingWentWrong});
    }

}

export default update;
