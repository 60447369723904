import {LISTING_TYPE, LISTING_STEPS, SET_PERSONALIZED_VALUES} from '../constants';

export default function getListingType(state = {}, action) {
    switch (action.type) {
        case LISTING_TYPE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case LISTING_STEPS:
            return {
                ...state,
                listingSteps: {
                    ...state.listingSteps,
                    [action.payload.name]: action.payload.value
                },
            };
        default:
            return state;
    }
}
