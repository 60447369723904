import React, {Component} from "react";
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {injectIntl, FormattedMessage} from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import InputGroup from "react-bootstrap/lib/InputGroup";

// Component
import updateStep3 from "./updateStep3";
import FooterButton from "./FooterButton";
import SidePanel from "./SidePanel";

import messages from "../../locale/messages";
import validateStep3 from "./validateStep3";

//Image
import toolTipIcon from "/public/SiteIcons/listCommonToolTip.svg";

import bt from "../../components/commonStyle.css";
import s from "./ListPlaceStep1.css";
import update from "./update";
import CustomCheckbox from "../CustomCheckbox";
import validatePage1 from "./moreValidators/validatePage1";

class Tariff extends Component {
    static propTypes = {
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
        createNewListing: PropTypes.bool,
    };

    static defaultProps = {
        nextPagePath: 'home',
        previousPagePath: 'pricing',
        createNewListing: false
    }

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
        };
    }

    UNSAFE_componentWillMount() {
        const {valid} = this.props;
        this.setState({isDisabled: valid ? false : true});
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {valid} = nextProps;
        this.setState({isDisabled: valid ? false : true});
    }

    renderTariffCards = ({input, meta: {error, touched}, className, options}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div className={cx(s.pricingContainer)}>
                {options.map((item) => {
                    return (
                        <div key={item.id} className={cx(s.pricingCard, {[s.featured]: item.id == input.value})}>
                            <div className={cx(s.planHeader)}>
                                <h2 className={cx(s.planName)}>{item.name}</h2>
                                <div className={cx(s.planPrice)}>{item.price ? item.price : 'Free'}</div>
                                {item.price &&
                                    <div className="plan-period">{item.type === 'monthly' ? 'monthly' : 'yearly'}</div>}
                                {item.description && <p className={cx(s.planDescription)}>{item.description}</p>}
                            </div>
                            <ul className={cx(s.featuresList)}>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.advertising ? s.iconCheck : s.iconCross)}>
                                        {item.advertising ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.advertising)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.customer_support ? s.iconCheck : s.iconCross)}>
                                        {item.customer_support ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.customerSupport)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.risk_insurance ? s.iconCheck : s.iconCross)}>
                                        {item.risk_insurance ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.riskInsurance)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.apartment_tour ? s.iconCheck : s.iconCross)}>
                                        {item.apartment_tour ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.apartmentTour)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.checkIn_tenants ? s.iconCheck : s.iconCross)}>
                                        {item.checkIn_tenants ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.checkInTenants)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.premium_sections ? s.iconCheck : s.iconCross)}>
                                        {item.premium_sections ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.premiumSections)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.search_priority ? s.iconCheck : s.iconCross)}>
                                        {item.search_priority ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.searchPriority)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.service_guarantee ? s.iconCheck : s.iconCross)}>
                                        {item.service_guarantee ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText)}>
                                        {formatMessage(messages.serviceGuarantee)}
                                    </span>
                                </li>
                            </ul>
                            <a href="#" onClick={(e) => {
                                e.preventDefault()
                                input.onChange(item.id);

                            }} className={cx(s.planButton, s.secondary)}>
                                <FormattedMessage {...messages.select} />
                            </a>
                        </div>
                    )

                })}
            </div>
        )
    }

    render() {
        const {
            handleSubmit,
            nextPage,
            previousPage,
            formPage,
            step,
            createNewListing,
            aviableTariffs,
        } = this.props;
        const {isDisabled} = this.state;
        const {formatMessage} = this.props.intl;
        return (
            <div className={s.grid}>
                <SidePanel
                    title={formatMessage(messages.stepThreeCommonHeading)}
                    landingContent={formatMessage(messages.tabPricing)}
                />
                <form onSubmit={handleSubmit}>
                    <div className={cx(s.landingMainContent, "disCountAddon")}>
                        <FormGroup className={cx(s.formGroup, s.space4)}>
                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.tariff} /> <span className={s.required}>*</span>
                            </ControlLabel>

                            <Field
                                name="tariff_id"
                                validate={[validatePage1.tariff]}
                                component={this.renderTariffCards}
                                options={aviableTariffs}
                            >

                            </Field>
                        </FormGroup>
                    </div>
                    <FooterButton
                        nextPage={nextPage}
                        previousPage={previousPage}
                        nextPagePath={this.props.nextPagePath}
                        previousPagePath={this.props.previousPagePath}
                        formPage={formPage}
                        type={"submit"}
                        step={step}
                        isDisabled={isDisabled}
                    />
                </form>
            </div>
        );
    }
}

Tariff = reduxForm({
    form: "ListPlaceStep1", // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: update,
    // onSubmit: updateStep4
})(Tariff);

const mapState = (state) => ({
    aviableTariffs: state.tariff?.aviableTariffs,
});

const mapDispatch = {};

export default injectIntl(
    withStyles(s, bt)(connect(mapState, mapDispatch)(Tariff))
);
