import React from 'react';
import BaseForm from './base/BaseForm';
import {Field, reduxForm} from 'redux-form';
import submit from './scripts/submit';
import update from './scripts/update';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import cx from 'classnames';
import s from './style/listingForm.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {Col, Grid, Row} from "react-bootstrap";
import Locations from './ListingSteps/Locations';
import ProgressPanel from "./base/ProgressPanel";
import About from './ListingSteps/About';
import AboutHome from "./ListingSteps/AboutHome";
import Pricing from "./ListingSteps/Pricing";
import Rules from "./ListingSteps/Rules";
import Photos from "./ListingSteps/Photos";
import Tariff from "./ListingSteps/Tariff";
import Calendar from "./ListingSteps/Calendar";
import Description from "./ListingSteps/Description";
import Conveniences from "./ListingSteps/Conveniences";

class ListingForm extends React.Component {
    render() {
        const {
            formPage,
            photosCount,
            mode,
            existingList,
            listId,
            baseCurrency,
            step,
            listingType,
            setListingType
        } = this.props;
        return (
            <div onSubmit={existingList ? update : submit} className={cx(s.pageContainer)}>
                <Row>
                    <Col xs={12} md={9}>
                        <div className={cx(s.listingForms)}>
                            <Locations></Locations>
                            {existingList && <>

                                <About></About>
                                <AboutHome></AboutHome>
                                <Pricing></Pricing>
                                <Conveniences></Conveniences>
                                <Rules></Rules>
                                <Calendar listId={listId}></Calendar>
                                <Photos listId={listId}></Photos>
                                <Description></Description>
                            </>}

                        </div>
                    </Col>
                    <div className={cx('col-xs-12 col-md-3', s.progressPanelWrapper)}>
                        <ProgressPanel
                            onSubmit={existingList ? update : submit}
                        ></ProgressPanel>
                    </div>
                </Row>
            </div>
        )
    }
}

ListingForm = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(ListingForm)

const mapState = (state) => ({
    listingFields: state.listingFields,
    existingList: state.location.isExistingList,
    onSubmit: state.location.isExistingList ? update : submit
})

const mapDispatch = {}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ListingForm)));
