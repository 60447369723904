// General
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Redux Form
import {Field, reduxForm, formValueSelector, FieldArray, change} from 'redux-form';
// Redux
import {connect} from 'react-redux';
// Translation
import {injectIntl, FormattedMessage} from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    FormGroup,
    ControlLabel,
    FormControl, Row, Col
} from 'react-bootstrap';

// Internal Component
import IncrementButton from '../IncrementButton';
import IncrementBtn from './IncrementBtn';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

// Style
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

import update from './update';
import messages from '../../locale/messages';
import validate from "./validate";
import validateStep2 from "./validateA";
import validatePage3 from "./moreValidators/validatePage3";
class Page3 extends Component {

    static propTypes = {
        initialValues: PropTypes.object,
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        createNewListing: PropTypes.bool,
        additionalFields: PropTypes.bool,
        isExistingList: PropTypes.bool,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
    };
    static defaultProps = {
        nextPagePath: 'pricing',
        previousPagePath: 'room',
        createNewListing: false,
        additionalFields: false
    }

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
        }
        this.handleClick = this.handleClick.bind(this);
        this.renderAdditionalFields = this.renderAdditionalFields.bind(this);
    }


    componentDidMount() {
        const {valid, leaseTypeValue} = this.props;

        // Set initial filter based on first lease type if no value is selected
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }

    UNSAFE_componentWillMount() {
        const {listingFields, beds, change, bedTypes} = this.props;
        const {bedType} = this.state;
        let obj, bedsArray = [];

        if (listingFields != undefined) {
            this.setState({
                bedType: listingFields.bedType,
                beds: listingFields.beds[0],
                bedrooms: listingFields.bedrooms[0],
                personCapacity: listingFields.personCapacity[0],
                bathrooms: listingFields.bathrooms[0],
                bathroomType: listingFields.bathroomType,
            });
        }

        if ((bedTypes && beds != bedTypes.length) || (bedTypes && bedTypes.length == 0)) {
            Array(beds).fill().map((i, index) => {
                obj = {
                    bedCount: 1,
                    bedType: listingFields.bedType && listingFields.bedType.length > 0 ? listingFields.bedType[0].id : null,
                };
                bedsArray.push(obj);
            })
            change('bedTypes', bedsArray);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listingFields, valid} = nextProps;
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }

        if (listingFields != undefined) {
            this.setState({
                bedType: listingFields.bedType,
                beds: listingFields.beds[0],
                bedrooms: listingFields.bedrooms[0],
                personCapacity: listingFields.personCapacity[0],
                bathrooms: listingFields.bathrooms[0],
                bathroomType: listingFields.bathroomType,
            });
        }
    }


    renderIncrementButton = (field) => (
        <IncrementButton
            {...field}
        />
    );

    renderIncrementBtn = (input, dispatch) => (
        <IncrementBtn
            {...input}
            onChange={this.handleClick}
        />
    );

    renderSelectField = ({input, label, meta: {touched, error}, children}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <select
                    {...input}
                >
                    {children}
                </select>
                {touched && error && <span>{formatMessage(error)}</span>}
            </div>
        )
    }

    renderFormControlSelect = ({input, label, meta: {touched, error}, children, className}) => {
        return (
            <div>
                <FormControl componentClass="select" {...input} className={className}>
                    {children}
                </FormControl>
            </div>
        )
    }

    renderFormControl = ({input, label, type, meta: {touched, error}, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl {...input} placeholder={label} type={type} className={className}/>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        )
    }

    async handleClick(e) {
        const {beds, bedTypes, change} = this.props;
        const {bedType} = this.state;

        let bedTypesValue = Object.keys(bedTypes).map(function (key) {
            return bedTypes[key];
        });

        let updatedBedTypesArray = (bedTypesValue && bedTypesValue.length > 0) ? bedTypesValue.slice(0) : [];
        let updatedBedCount = beds + 1;
        let obj = {};

        if (Number(beds) < Number(e)) {
            obj = {
                bedCount: updatedBedCount,
                bedType: bedType.length > 0 ? bedType[0].id : null,
            };
            try {
                updatedBedTypesArray.push(obj);
            } catch (e) {

            }
        } else if ((Number(beds) >= Number(e)) || (Number(e) > 2)) {
            updatedBedTypesArray.splice(bedTypesValue.length - 1, 1);
        }

        await change('bedTypes', []);
        await change('bedTypes', updatedBedTypesArray);
    }

    checkboxGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable === "1") {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: input.value.indexOf(option.id) !== -1},
                                        {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                         onClick={() => {
                                             const newValue = [...input.value];
                                             if (newValue.indexOf(option.id) !== -1) {
                                                 newValue.splice(newValue.indexOf(option.id), 1);
                                             } else {
                                                 newValue.push(option.id);
                                             }
                                             input.onChange(newValue);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    radioGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable) {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: parseInt(input.value) === option.id},
                                        {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                         onClick={() => {
                                             input.onChange(option.id);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    renderInternetFields({input, name}) {
        const {formatMessage} = this.props.intl;

        return (
            <Row>
                <Col xs={12} sm={6} md={4} lg={4}>
                    <Field
                        name={name}
                        component={this.renderFormControl}
                        type="text"
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={name.toUpperCase()}
                    />
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                    <Field
                        name={name + 'speed'}
                        component={this.renderFormControl}
                        type="text"
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.speed)}
                    />
                </Col>
                <Col xs={12}>
                    <Field
                        name={name + 'limit'}
                        options={[
                            {id: 1, isEnable: 1, itemName: 'Unlimited', value: 'Unlimited'},
                            {id: 2, isEnable: 1, itemName: 'limited', value: 'Limited'}
                        ]}
                        component={this.radioGroup}
                        className={cx(s.formControlInput, s.jumboInput)}
                    />
                </Col>
            </Row>
        )
    }

    renderAdditionalFields() {
        const {bedrooms, beds, personCapacity, bathrooms, bathroomType} = this.state;
        const {
            listingFields: {
                layoutType, repairType, wallMaterial, ceilingHeight, elevatorType
            },
            additionalFields: createNewListing
        } = this.props
        return (
            <div>

                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages['layoutType']} /> {createNewListing &&<span
                                            className={s.required}>*</span>}
                    </ControlLabel>
                    <Field validate={createNewListing ? [validatePage3.layoutType] : []} name="layoutType" component={this.renderFormControlSelect}
                           className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}>
                        {
                            layoutType.map((value, key) => {
                                return (
                                    value.isEnable == 1 &&
                                    <option value={value.id} key={key}>{value.itemName}</option>
                                )
                            })
                        }
                    </Field>
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages['repairType']} /> {createNewListing &&<span
                                            className={s.required}>*</span>}
                    </ControlLabel>
                    <Field validate={createNewListing ? [validatePage3.repairType] : []} name="repairType" component={this.renderFormControlSelect}
                           className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}>
                        {
                            repairType.map((value, key) => {
                                return (
                                    value.isEnable == 1 &&
                                    <option value={value.id} key={key}>{value.itemName}</option>
                                )
                            })
                        }
                    </Field>
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages['wallMaterial']} /> {createNewListing &&<span
                                            className={s.required}>*</span>}
                    </ControlLabel>
                    <Field validate={createNewListing ? [validatePage3.wallMaterial] : []} name="wallMaterial" component={this.renderFormControlSelect}
                           className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}>
                        {
                            wallMaterial.map((value, key) => {
                                return (
                                    value.isEnable == 1 &&
                                    <option value={value.id} key={key}>{value.itemName}</option>
                                )
                            })
                        }
                    </Field>
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages['ceilingHeight']} /> {createNewListing &&<span
                                            className={s.required}>*</span>}
                    </ControlLabel>
                    <Field validate={createNewListing ? [validatePage3.ceilingHeight] : []} name="ceilingHeight" component={this.renderFormControlSelect}
                           className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}>
                        {
                            ceilingHeight.map((value, key) => {
                                return (
                                    value.isEnable == 1 &&
                                    <option value={value.id} key={key}>{value.itemName}</option>
                                )
                            })
                        }
                    </Field>
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages['elevatorType']} /> {createNewListing &&<span
                                            className={s.required}>*</span>}
                    </ControlLabel>
                    <Field validate={createNewListing ? [validatePage3.elevatorType] : []} name="elevatorType" component={this.renderFormControlSelect}
                           className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}>
                        {
                            elevatorType.map((value, key) => {
                                return (
                                    value.isEnable == 1 &&
                                    <option value={value.id} key={key}>{value.itemName}</option>
                                )
                            })
                        }
                    </Field>
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages.howManyBedrooms} />
                    </ControlLabel>
                    <Field
                        validate={createNewListing ? [validatePage3.bedrooms] : []}
                        name="bedrooms"
                        type="text"
                        component={this.renderIncrementButton}
                        labelSingluar={bedrooms.itemName}
                        labelPlural={bedrooms.otherItemName}
                        minValue={bedrooms.startValue}
                        incrementBy={1}
                        maxValue={bedrooms.endValue}
                    />
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages.howManyBathrooms} /> {createNewListing &&<span
                                            className={s.required}>*</span>}
                    </ControlLabel>
                    <Field
                        validate={createNewListing ? [validatePage3.bathrooms] : []}
                        name="bathrooms"
                        type="text"
                        component={this.renderIncrementButton}
                        labelSingluar={bathrooms.itemName}
                        labelPlural={bathrooms.otherItemName}
                        maxValue={bathrooms.endValue}
                        minValue={bathrooms.startValue}
                        incrementBy={0.5}
                    />
                </FormGroup>
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages['bathroomType']} /> {createNewListing &&<span
                                            className={s.required}>*</span>}
                    </ControlLabel>
                    <Field validate={createNewListing ? [validatePage3.bathroomType] : []} name="bathroomType" component={this.renderFormControlSelect}
                           className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}>
                        {
                            bathroomType.map((value, key) => {
                                return (
                                    value.isEnable == 1 &&
                                    <option value={value.id} key={key}>{value.itemName}</option>
                                )
                            })
                        }
                    </Field>
                </FormGroup>
            </div>
        )
    }

    render() {
        const {
            handleSubmit,
            previousPage,
            nextPage,
            isExistingList,
            formPage,
            step,
            listingFields,
            _listingType,
            additionalFields
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {isDisabled} = this.state;
        let listingType = additionalFields || isExistingList;

        return (
            <div className={cx(s.grid, 'arrowPosition')}>
                <SidePanel
                    title={formatMessage(messages.stepOneCommonHeading)}
                    landingContent={formatMessage(messages.howManyGuests)}
                />
                <form onSubmit={handleSubmit}>
                    <div className={s.landingMainContent}>
                        <FormGroup className={s.space5}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.howManyRooms} /> <span
                                            className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="rooms"
                                component={this.radioGroup}
                                options={listingFields.rooms}
                            />
                        </FormGroup>

                        <Row >
                            <Col xs={12} md={6}>
                                <FormGroup className={s.space5}>
                                    <FormGroup className={s.space5}>
                                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                            <FormattedMessage {...messages.livingArea} /> <span
                                            className={s.required}>*</span>
                                        </ControlLabel>
                                        <Field
                                            name="livingArea"
                                            component={this.renderFormControl}
                                            type="number"
                                            className={cx(s.formControlInput, s.jumboInput)}
                                            label={formatMessage(messages.livingArea)}
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className={s.space5}>
                                    <FormGroup className={s.space5}>
                                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                            <FormattedMessage {...messages.totalArea} /> <span
                                            className={s.required}>*</span>
                                        </ControlLabel>
                                        <Field
                                            name="totalArea"
                                            component={this.renderFormControl}
                                            type="number"
                                            className={cx(s.formControlInput, s.jumboInput)}
                                            label={formatMessage(messages.totalArea)}
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row >
                            <Col xs={12} md={6}>
                                <FormGroup className={s.space5}>
                                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                        <FormattedMessage {...messages.floor} /> <span
                                        className={s.required}>*</span>
                                    </ControlLabel>
                                    <Field
                                        name="floor"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                        label={formatMessage(messages.floor)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup className={s.space5}>
                                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                        <FormattedMessage {...messages.floors} /> <span
                                        className={s.required}>*</span>
                                    </ControlLabel>
                                    <Field
                                        name="floors"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                        label={formatMessage(messages.floors)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {listingType && this.renderAdditionalFields()}

                    </div>
                    <FooterButton
                        isDisabled={isDisabled}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        nextPagePath={this.props.nextPagePath}
                        previousPagePath={this.props.previousPagePath}
                        formPage={formPage}
                        step={step}
                    />
                </form>
            </div>
        )
    }
}


Page3 = reduxForm({
    form: 'ListPlaceStep1', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateStep2,
    onSubmit: update
})(Page3);

// Decorate with connect to read form values
const selector = formValueSelector('ListPlaceStep1'); // <-- same as form name

const mapState = (state) => ({
    isExistingList: state.location.isExistingList,
    listingFields: state.listingFields.data,
    _listingType: state.listing.listingType
});

const mapDispatch = {
    change,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Page3)));
