import Link from '../Link'
import SearchIcon from '/public/SiteIcons/searchIconHome.svg';
import SearchIconDark from '/public/SiteIcons/searchIconHomeDark.svg';
import WishlistIcon from '/public/SiteIcons/wishlist.svg'
import PersonIcon from '/public/SiteIcons/person.svg'
import React from 'react'
import {setUserLogout} from "../../actions/logout";
import {flowRight as compose} from "lodash";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import history from "../../core/history";
import cx from "classnames";
import messages from "../../locale/messages";
import s from './MobileMenuBeforeLogin.css'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {openLoginModal, openSignupModal} from "../../actions/modalActions";
import {toggleClose, toggleOpen} from "../../actions/Menu/toggleControl";

class MobileMenuBeforeLogin extends React.Component {
    constructor(props) {
        super(props);
    }


    handleMenu() {
        const {showMenu, toggleOpen, toggleClose} = this.props;
        showMenu ? toggleClose() : toggleOpen();
    }

    render () {
        const { location } = this.props
        return (
            <div className={cx(s.mobileNav, 'bgBlack',)}>
                <div className={cx(s.mobileNavContainer)}>
                    <Link
                        to="/"
                        className={cx(s.mobileNavItem, 'textWhite', (location === "/" ? 'mobile-nav__item--active' : ''))}
                    >
                        <svg className={cx(s.mobileNavIcon, 'svgWhite')} id="_x32_-Magnifying_Glass"
                             xmlns="http://www.w3.org/2000/svg" width="14.232" height="14.235"
                             viewBox="0 0 14.232 14.235">
                            <path id="Path_22044" data-name="Path 22044"
                                  d="M21.114,19.921l-2.736-2.736a6.283,6.283,0,1,0-1.193,1.193l2.736,2.736a.844.844,0,0,0,1.193-1.193ZM10.155,16.633a4.581,4.581,0,1,1,3.239,1.342,4.587,4.587,0,0,1-3.239-1.342Z"
                                  transform="translate(-7.129 -7.125)"/>
                        </svg>
                        <span className={cx(s.mobileNavText)}>
                            <FormattedMessage {...messages.search} />
                            </span>
                    </Link>
                    <Link
                        to="/wishlists"
                        className={cx(s.mobileNavItem,'textWhite', (location === "/wishlists" ? 'mobile-nav__item--active' : ''))}
                    >
                        <svg className={cx(s.mobileNavIcon, 'svgWhite')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path
                                d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" fill="none"></path>
                        </svg>


                        <span className={cx(s.mobileNavText)}>
                            <FormattedMessage {...messages.wishLists} />
                            </span>
                    </Link>
                    <span
                        onClick={() => this.handleMenu()}
                        className={cx(s.mobileNavItem, 'textWhite')}
                    >
                        <svg className={cx(s.mobileNavIcon, 'svgWhite')} xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" fill="none"></path>
                            <circle cx="12" cy="7" r="4" fill="none"></circle>
                        </svg>
                        <span className={cx(s.mobileNavText)}>
                            <FormattedMessage {...messages.menu} />
                            </span>
                    </span>
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    showMenu: state.toggle.showMenu,
});

const mapDispatch = {
    toggleOpen,
    toggleClose
};
export default compose(
    injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
)(MobileMenuBeforeLogin);
