// General
import React from 'react';
import PropTypes from 'prop-types';

import {flowRight as compose} from 'lodash';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Navbar} from 'react-bootstrap';
import LoadingBar from 'react-redux-loading-bar';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import NavigationV2 from '../NavigationV2';
import Logo from '../Logo';
import Toaster from '../Toaster';
import HeaderLocationSearch from './HeaderLocationSearch';

import {toggleOpen, toggleClose} from '../../actions/Menu/toggleControl';

import history from '../../core/history';
import DropDownMenu from '../DropDownMenu/DropDownMenu';

import closeIcon from '/public/SiteIcons/loginClose.svg';

import s from './Header.css';
import Nav from "react-bootstrap/lib/Nav";
import NavLink from '../NavLink'
import messages from "../../locale/messages";
import SearchForm2 from "../Home/SearchFormV2/SearchForm";
import SearchForm from "./SearchForm/SearchFormV2";
import {openSearchModal} from "../../actions/modalActions";
import SearchModal from "./SearchForm/SearchModal";

class Header extends React.Component {
    static propTypes = {
        borderLess: PropTypes.bool,
        showMenu: PropTypes.bool,
        fixedHeader: PropTypes.bool,
        toggleOpen: PropTypes.any.isRequired,
        formatMessage: PropTypes.any,
        checked: PropTypes.any,
        small: PropTypes.bool

    };

    static defaultProps = {
        borderLess: false,
        showMenu: false,
        searchDisablePages: [
            '/home'
        ],
        fixedHeader: false,
        small: false,
        whyHostSearchHide: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            searchHide: true,
            isOpen: 0
        };
        this.handleMenu = this.handleMenu.bind(this);
        this.handleDisableSearchPages = this.handleDisableSearchPages.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.openClose = this.openClose.bind(this);
    }

    componentDidMount() {
        const {small} = this.props
        if (small) {

        } else {
            this.handleDisableSearchPages();
            document.addEventListener('scroll', this.handleScroll);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    UNSAFE_componentWillReceiveProps() {
        this.handleDisableSearchPages();
    }

    handleMenu() {
        const {showMenu, toggleOpen, toggleClose} = this.props;
        showMenu ? toggleClose() : toggleOpen();
    }

    handleDisableSearchPages() {
        const {searchDisablePages} = this.props;
        let location, searchHide;
        location = history.location ? history.location.pathname : null;
        searchHide = false;
        if (location && searchDisablePages?.length > 0) {
            searchHide = searchDisablePages.find((o) => location === o);
            searchHide = (searchHide) ? true : false;
        }
        this.setState({searchHide})
    }

    openMenu() {
        const {showMenu, toggleOpen, toggleClose} = this.props;
        toggleOpen();
        document.body.classList.add('menu-open');
    }

    openClose() {
        const {showMenu, toggleOpen, toggleClose} = this.props;
        toggleClose();
        document.body.classList.remove('menu-open');
    }

    handleScroll() {
        const {fixedHeader} = this.props;
        if (fixedHeader) {
            fixedBgClass = 'stickyHeader';
            scrollLogo = true;
            this.setState({
                fixedBgClass,
                scrollLogo
            });
            return
        }
        let top = document.documentElement.scrollTop || document.body.scrollTop,
            isWeb = (document.documentElement.clientWidth || document.body.clientWidth) >= 1200 ? true : false;
        const docH = document.body.scrollHeight
        const headerH = document.getElementById('mainHeader').offsetHeight - 50
        const scrollVal = window.scrollY + window.innerHeight
        let fixedBgClass = '', scrollLogo = false;
        if (isWeb && window.scrollY >= 0 && scrollVal <= docH) {
            fixedBgClass = window.scrollY > headerH ? 'stickyHeader' : ''
            scrollLogo = true;

        }
        // if (isWeb && top >= 50) {
        //     fixedBgClass = 'stickyHeader';
        //     scrollLogo = true;
        // }
        this.setState({
            fixedBgClass,
            scrollLogo
        });
    }

    handleChange(checked) {
        this.setState({checked});
    }

    render() {
        const {borderLess, showMenu, whyHostSearchHide, fixedHeader, whyHostHeader} = this.props;
        const {viewListingHeader, page, guests, account, openSearchModal} = this.props;
        const {searchHide, isOpen, fixedBgClass, scrollLogo} = this.state;
        let borderClass, location, stickyHeader;

        if (borderLess) {
            borderClass = s.commonHeaderBorderLess;
        }
        if (history.location) location = history.location.pathname;

        stickyHeader = fixedBgClass ? 'stickyHeader' : '';

        return (
            <div id={'mainHeader'} className={cx(s.root, {[s.stickyHeader]: fixedHeader}, 'bgBlack', s.staticHeader,
                stickyHeader ? s.stickyHeader : '',
            )}>
                <Toaster/>
                <LoadingBar/>
                <div className={cx(s.container, s.containerV2, 'bgBlack', 'dashboardBottomLayout')}>
                    <Navbar
                        className={cx(
                            s.commonHeader, 'commonHeader',
                            borderClass, 'darkModeInnerMenu',
                            {['homeHeader']: location === '/' || location === '/home'},
                            whyHostHeader, viewListingHeader,
                        )}
                        expanded={showMenu}
                        onToggle={this.handleMenu}
                    >
                        <div className={cx(s.headerFlexBox)}>
                            <Navbar.Header
                                className={cx(s.header__logo, 'logoPadding', 'innerMenuLogo', !showMenu ? 'normalPosition' : 'fixedPosition')}>
                                <Navbar.Brand className={(s.homeLogo)}>
                                    <Logo link={"/home"} className={cx(s.brand, s.brandImg)}/>
                                </Navbar.Brand>
                            </Navbar.Header>

                            <div className={cx('layout5Css', s.headerSearchBar)}>
                                <HeaderLocationSearch className={cx(s.headerSearchBar__small)} guests={guests}
                                                      page={page}
                                                      viewListingHeader={viewListingHeader}/>
                                <div className={cx(s.headerSearchBar__big)}>
                                    <Nav className={cx(s.centerLinks, 'centerLinks')}>
                                        <div className={cx(s.tabViewHidden, 'centerMenuTop')}>
                                            <div className={cx('scrollOff', 'layout2CenterPadding')}>
                                                <NavLink to="/#"
                                                         className={cx(s.centerLink, s.dot, 'headerDotRTL', 'centerLink2', 'dot2')}>
                                                    <FormattedMessage {...messages.placesToStay} />
                                                </NavLink>
                                                <NavLink to="/whyhost"
                                                         className={cx(s.centerLink, 'centerLinkRTL', 'centerLink2')}>
                                                    <FormattedMessage {...messages.becomeAHost} />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </Nav>
                                    <SearchForm fixedStyle={stickyHeader} page={page}
                                                viewListingHeader={viewListingHeader}
                                                guests={guests}/>
                                </div>
                            </div>
                            <div className={cx(s.headerSearchMobile, 'textWhite')} onClick={() => {
                                openSearchModal()
                            }}>
                                <svg className={cx('svgWhite')} id="_x32_-Magnifying_Glass"
                                     xmlns="http://www.w3.org/2000/svg" width="14.232"
                                     height="14.235" viewBox="0 0 14.232 14.235">
                                    <path id="Path_22044" data-name="Path 22044"
                                          d="M21.114,19.921l-2.736-2.736a6.283,6.283,0,1,0-1.193,1.193l2.736,2.736a.844.844,0,0,0,1.193-1.193ZM10.155,16.633a4.581,4.581,0,1,1,3.239,1.342,4.587,4.587,0,0,1-3.239-1.342Z"
                                          transform="translate(-7.129 -7.125)"/>
                                </svg>

                                <span>
                                    <FormattedMessage  {...messages.search}/>
                                </span>
                            </div>
                            <div onClick={() => this.openMenu()}
                                 className={cx('hidden-lg', 'hamburgerButton', 'hamburgerInnerButton')}>
                                <DropDownMenu/>
                            </div>
                            <div
                                className={cx({[s.menuOpen]: showMenu == 1}, s.mobileMenu, 'homeMobileMenu', 'mobileMenuDark')}>
                                <div className={cx({[s.menuClose]: showMenu == 0}, s.rightMenuClose, 'hidden-lg')}>
                                    <div className={cx(s.closeButtonPosition, 'closeButtonPositionDark')}>
                                        <div className={cx(s.closeColor, 'textWhite', 'closeColorRTL', 'svgImg')}
                                             onClick={() => this.openClose()}>
                                            <img src={closeIcon}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(s.navigationMenu)}>
                                    <NavigationV2 whyHostSearchHide={whyHostSearchHide} page={page}/>
                                </div>
                            </div>

                        </div>
                    </Navbar>
                </div>
                <SearchModal></SearchModal>
            </div>
        );
    }
}

const mapState = (state) => ({
    showMenu: state.toggle.showMenu,
    account: state.account.data
});

const mapDispatch = {
    toggleOpen,
    toggleClose,
    openSearchModal
};

export default compose(
    injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
)(Header);
