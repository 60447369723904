// General
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Redux Form
import {Field, reduxForm, formValueSelector, FieldArray, change} from 'redux-form';
// Redux
import {connect} from 'react-redux';
// Translation
import {injectIntl, FormattedMessage} from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    FormGroup,
    ControlLabel,
    FormControl, Row, Col, Nav, NavItem, Tab
} from 'react-bootstrap';

import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

// Style
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';
import update from './update';
import messages from '../../locale/messages';
import validate from "./validate";
import * as FontAwesome from "react-icons/lib/fa";

class ReservationRule extends Component {

    static propTypes = {
        initialValues: PropTypes.object,
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        isExistingList: PropTypes.bool,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
    };

    static defaultProps = {
        nextPagePath: 'cancellation-rules',
        previousPagePath: 'house-rules',
    }


    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
        }
    }


    componentDidMount() {
        const {valid, leaseTypeValue} = this.props;

        // Set initial filter based on first lease type if no value is selected
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listingFields, valid} = nextProps;
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }

    renderFields = ({input}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <h2 className={s.titleStyle}>{formatMessage(messages.whoCanBook)}</h2>
                <div className={s.ruleListStyle}>
                    <button
                        type={"button"}
                        onClick={() => input.onChange('limited')}
                        className={cx(s.ruleButtonStyle, input.value === 'limited' ? s.selectedRuleStyle : s.defaultRuleStyle)}
                    >
                        <div className={cx(s.iconWrapperStyle)}>
                            <FontAwesome.FaCheck className={cx(s.iconStyle, 'textWhite')}/>
                        </div>
                        <div className={cx(s.ruleContentStyle)}>
                            <h3 className={cx(s.ruleTitle)}>{formatMessage(messages.whoCanBookInfo6)}</h3>
                            <p className={cx(s.ruleDescription)}>{formatMessage(messages.whoCanBookInfo4)}</p>
                            <p className={cx(s.ruleDescription)}>{formatMessage(messages.whoCanBookInfo5)}</p>
                        </div>
                    </button>
                    <button
                        type={"button"}
                        onClick={() => input.onChange('instant')}
                        className={cx(s.ruleButtonStyle, input.value === 'instant' ? s.selectedRuleStyle : s.defaultRuleStyle)}
                    >
                        <div className={cx(s.iconWrapperStyle)}>
                            <FontAwesome.FaUser className={cx(s.iconStyle, 'textWhite')}/>
                        </div>
                        <div className={cx(s.ruleContentStyle)}>
                            <h3 className={cx(s.ruleTitle)}>{formatMessage(messages.whoCanBookInfo1)}</h3>
                            <p className={cx(s.ruleDescription)}>{formatMessage(messages.whoCanBookInfo2)}</p>
                        </div>
                    </button>
                    <button
                        type={"button"}
                        onClick={() => input.onChange('request')}
                        className={cx(s.ruleButtonStyle, input.value === 'request' ? s.selectedRuleStyle : s.defaultRuleStyle)}
                    >
                        <div className={cx(s.iconWrapperStyle)}>
                            <FontAwesome.FaBan className={cx(s.iconStyle, 'textWhite')}/>
                        </div>
                        <div className={cx(s.ruleContentStyle)}>
                            <h3 className={cx(s.ruleTitle)}>{formatMessage(messages.whoCanBookInfo3)}</h3>
                        </div>
                    </button>
                </div>
            </div>
        )
    }


    render() {
        const {
            handleSubmit,
            previousPage,
            nextPage,
            isExistingList,
            formPage,
            step,
            listingFields,
            _listingType
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {isDisabled} = this.state;
        let listingType = _listingType === 'detailed';
        // let path = isExistingList ? "map" : "location";
        return (
            <div className={s.grid}>
                <SidePanel
                    title={formatMessage(messages.stepThreeCommonHeading)}
                    landingContent={formatMessage(messages.instantPanelText)}
                />
                <div className={cx(s.landingMainContent)}>
                    <form onSubmit={handleSubmit}>
                        <FormGroup className={s.space5}>
                            <Field
                                name="bookingType"
                                component={this.renderFields}
                                className={cx(s.formControlInput, s.jumboInput)}
                            ></Field>
                        </FormGroup>
                        <FooterButton
                            isDisabled={isDisabled}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            nextPagePath={this.props.nextPagePath}
                            previousPagePath={this.props.previousPagePath}
                            formPage={formPage}
                            step={step}
                        />
                    </form>

                </div>
            </div>
        )
    }
}


ReservationRule = reduxForm({
    form: 'ListPlaceStep3', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: update
})(ReservationRule);

// Decorate with connect to read form values
const selector = formValueSelector('ListPlaceStep1'); // <-- same as form name

const mapState = (state) => ({
    isExistingList: state.location.isExistingList,
    listingFields: state.listingFields.data,
    _listingType: state.listing.listingType
});

const mapDispatch = {
    change,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(ReservationRule)));
