import React from 'react';
import s from '../style/progress-bar.css';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import bt from "../../commonStyle.css";
import {FormattedMessage, injectIntl} from "react-intl";
import messages from "../../../locale/messages";
import {Button} from "react-bootstrap";
import {formValues, getFormSyncErrors, submit} from "redux-form";
import {connect} from "react-redux";
import validateLocations from "../validations/validateLocations";
import validateAbout from "../validations/validateAbout";
import validateCharacteristics from "../validations/validateCharacteristics";
import validatePrice from "../validations/validatePrice";
import Loader from "../../Loader";
import Link from '../../Link';

class ProgressPanel extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            isLoading: false
        }
    }

    async handleSubmit() {
        this.setState({isLoading: true});
        const res = await this.props.submit('ListingNewForm');
        console.log(res, 'res', {isLoading: this.state.isLoading})
        this.setState({isLoading: false});
    }

    render() {
        const {formatMessage} = this.props.intl;
        const {isLoading} = this.state
        const {errors, existingList, formValues, listingPhotos} = this.props;
        const items = [
            {
                id: 1,
                label: formatMessage(messages.location),
                isCompleted: Object.keys(validateLocations(formValues)).length < 1,
                isRequired: true
            },
            {
                id: 2,
                label: formatMessage(messages.baseInformations),
                isCompleted: Object.keys(validateAbout(formValues)).length < 1,
                isRequired: true
            },
            {
                id: 3,
                label: formatMessage(messages.characteristics),
                isCompleted: Object.keys(validateCharacteristics(formValues)).length < 1,
                isRequired: true
            },
            {
                id: 6,
                label: formatMessage(messages.pricing),
                isCompleted: Object.keys(validatePrice(formValues)).length < 1,
                isRequired: true
            },
            {id: 4, label: formatMessage(messages.photos1), isCompleted: listingPhotos.length > 3, isRequired: true},
            {id: 7, label: formatMessage(messages.rules), isCompleted: formValues && formValues.houseRules?.length > 0 && formValues.cancellationPolicy&& formValues.bookingType, isRequired: false},
            {id: 5, label: formatMessage(messages.description1), isCompleted: formValues && formValues.description?.length > 1, isRequired: false},
        ];
        const errorsCount = 0
        const isDisabled = items.filter(item => item.isRequired).every(item => item.isCompleted)
        return (
            <>
                <div className={cx(s.progressBar, 'bgBlackTwo')}>
                    <div>
                        <h4>
                            <FormattedMessage {...messages.requiredFields} />
                        </h4>

                        <div className={cx(s.checklistContainer)}>
                            {items.map((item) => (
                                <div key={item.id} className={cx(s.checklistItem)}>
                                    <span
                                        className={cx(s.checklistItemIcon, item.isCompleted ? s.iconCheck : s.iconCross)}>
                                        {item.isCompleted ? '✓' : '✕'}
                                    </span>
                                    <span className={item.isRequired ? s.requiredText : s.optionalText}>
                                      {item.label} {item.isRequired && <span className={s.required}>*</span>}
                                    </span>
                                </div>
                            ))}
                        </div>

                        {existingList && <Loader
                            type={"button"}
                            label={formatMessage(messages.save)}
                            show={isLoading}
                            disabled={!isDisabled}
                            className={cx(bt.btnPrimary, bt.btnLarge, 'arButtonLoader', s.submitBtn)}
                            handleClick={this.handleSubmit}

                        />}


                    </div>
                </div>
                <Link to={'/rooms'}
                      className={cx(s.backLink)}>
                    <span style={{marginLeft: '8px'}}>
                    <FormattedMessage {...messages.hostingPage} />

                    </span>
                </Link>
            </>
        )
    }
}

const mapState = (state) => ({
    formValues: state.form.ListingNewForm.values || {},
    listingPhotos: state.location.listPhotos || [],
    errors: getFormSyncErrors('ListingNewForm')(state),
    existingList: state.location.isExistingList,
})
const mapDispatch = {
    submit,
}

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(ProgressPanel)));
