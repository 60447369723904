import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import moment from 'moment';
import {connect} from 'react-redux';
import {change} from 'redux-form';

import Row from 'react-bootstrap/lib/Row';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Col from 'react-bootstrap/lib/Col';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import Badge from 'react-bootstrap/lib/Badge';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

// Component
import CurrencyConverter from '../../CurrencyConverter';
// Helper
import {convert} from '../../../helpers/currencyConvertion';
// Locale
import messages from '../../../locale/messages';
//Images
import Faq from '/public/SiteIcons/question.svg'

import s from './Calendar.css';

class BillDetails extends Component {
    static propTypes = {
        basePrice: PropTypes.number.isRequired,
        monthlyPrice: PropTypes.number,
        cleaningPrice: PropTypes.number,
        tax: PropTypes.number,
        currency: PropTypes.string.isRequired,
        monthlyDiscount: PropTypes.number,
        weeklyDiscount: PropTypes.number,
        startDate: PropTypes.object.isRequired,
        endDate: PropTypes.object.isRequired,
        serviceFees: PropTypes.shape({
            guest: PropTypes.shape({
                type: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
                currency: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        base: PropTypes.string.isRequired,
        rates: PropTypes.object.isRequired,
        formatMessage: PropTypes.any,
        specialPricing: PropTypes.array,
    };

    static defaultProps = {
        basePrice: 0,monthlyPrice: 0, cleaningPrice: 0, tax: 0, monthlyDiscount: 0, weeklyDiscount: 0,
        startDate: null, endDate: null, specialPricing: [],
    }

    render() {
        const {
            basePrice: _basePrice,
            cleaningPrice,
            tax,
            currency,
            monthlyDiscount,
            weeklyDiscount,
            endDate
        } = this.props;
        const {
            serviceFees,
            base,
            rates,
            specialPricing,
            taxRate,
            change,
            deposit,
            paymentType,
            leaseType,
            publicUtilitiesType,
            tariff,
            monthlyPrice
        } = this.props;
        const {formatMessage} = this.props.intl;
        let {startDate} = this.props;

        let serviceFee = 0, basePrice = _basePrice, taxes = 0, totalWithoutTax = 0, currentDay,
            bookingSpecialPricing = [],
            isSpecialPriceAssigned = false, totalWithoutServiceFee = 0;
        let priceForDays = 0, isAverage = 0, isDayTotal = 0, momentStartDate, momentEndDate, dayDifference, discount,
            discountType, total, discountLessBasePrice = 0;
        let insurance = 0, agentCommission = 0


        const isMonthlyInclude = leaseType && leaseType.some(i => i.listsettings.otherItemName === 'month');
        let firstMonthPayment = 0, lastMonthPayment = 0, firstMonthDays = 0, lastMonthDays = 0,
            firstMonthSpecialPrice = false, lastMonthSpecialPrice = false;
        let totalMonthCounts = 0, monthCounts = 0;
        window.moment = moment
        const isMonthlyRent = leaseType && leaseType.some(item => item.listsettings.otherItemName === 'month')
        const onlyMonthlyRent = leaseType && leaseType.every(item => item.listsettings.otherItemName === 'month')
        const isDailyRent = leaseType && leaseType.some(item => item.listsettings.otherItemName === 'day')
        const onlyDailyRent = leaseType && leaseType.every(item => item.listsettings.otherItemName === 'day')
        let dailyCalc = true
        let monthlyCalc = false
        startDate = moment(startDate).format('YYYY-MM-DD');
        momentStartDate = moment(startDate);
        momentEndDate = moment(endDate);
        // if (onlyMonthlyRent) {
        //     const today = formStartDate || moment();
        //     let minPeriod = today.date() === 1 ? leaseMinValue - 1 : leaseMinValue;
        //     let minMonth = moment(today).add(minPeriod, 'months')
        //
        //     if (today.date() === 1) {
        //         minNight = moment(minMonth).date(minMonth.endOf('months').date()).diff(today, 'days');
        //     } else {
        //
        //         minNight = moment(minMonth).diff(moment(today), 'days')
        //     }
        // }
        let minMonthDays = 30
        let daysCount = 0
        const currentMonth = momentStartDate.month()
        let serviceFeeMonthly = 0
        let serviceFeeDaily = 0
        if (currentMonth === 1) {
            minMonthDays = momentStartDate.daysInMonth()
        }

        if (onlyMonthlyRent) {
            const daysDiff = momentEndDate.diff(momentStartDate, 'days')
            monthCounts = Math.floor(daysDiff / minMonthDays)
            const endOfLastMonth = moment(startDate).add(monthCounts, 'months').date()
            daysCount = momentEndDate.date() - endOfLastMonth
            dailyCalc = true

        } else if (isMonthlyInclude) {
            const daysDiff = momentEndDate.diff(momentStartDate, 'days')

            if (daysDiff < minMonthDays) {
                dailyCalc = true
                daysCount = daysDiff
            } else if (daysDiff >= minMonthDays) {
                monthCounts = Math.floor(daysDiff / minMonthDays)
                daysCount = daysDiff - minMonthDays
            }
        } else {
            startDate = moment(startDate).format('YYYY-MM-DD');
            momentStartDate = moment(startDate);
            momentEndDate = moment(endDate);
            dayDifference = momentEndDate.diff(momentStartDate, 'days');
            daysCount = dayDifference

            if (dayDifference > 0) {

                let stayedNights = [];
                // Find stayed nights
                for (let i = 0; i < dayDifference; i++) {
                    let currentDate = moment(startDate).add(i, 'day');
                    stayedNights.push(currentDate);
                }

                if (stayedNights?.length > 0) {
                    stayedNights.map((item, key) => {
                        let isSpecialPricing;
                        if (item) {
                            let pricingRow, currentPrice;
                            currentDay = (moment(item).format('dddd').toLowerCase());
                            isSpecialPricing = specialPricing?.length > 0 && specialPricing?.find(o => moment(item)
                                .format('MM/DD/YYYY') == moment(o.blockedDates).format('MM/DD/YYYY'));
                            if (isSpecialPricing?.isSpecialPrice) {
                                isSpecialPriceAssigned = true;
                                currentPrice = Number(isSpecialPricing?.isSpecialPrice);
                            } else {
                                currentPrice = Number(_basePrice);
                            }
                            // Price object
                            pricingRow = {
                                blockedDates: item,
                                isSpecialPrice: currentPrice,
                            };
                            bookingSpecialPricing.push(pricingRow);
                        }
                    });
                }
            }
            priceForDays = bookingSpecialPricing.reduce(
                (total, item) => total + Number(item.isSpecialPrice), 0
            );
            discount = 0;
            discountType = null;
            total = 0;

            isAverage = Number(priceForDays) / Number(dayDifference);
            isDayTotal = isAverage.toFixed(2) * dayDifference;
            priceForDays = isDayTotal;
        }

        if (tariff && tariff.guest_commision) {
            if (onlyMonthlyRent) {
                if (tariff.guest_is_percent) {
                    serviceFeeMonthly = _basePrice * (Number(tariff.guest_commision) / 100)
                } else {
                    serviceFeeMonthly = convert(base, rates, tariff.guest_commision, tariff.guest_currency, currency)
                }
            } else if (onlyDailyRent) {
                if (tariff.guest_is_percent) {
                    serviceFeeDaily = (basePrice * daysCount) * (Number(tariff.guest_commision) / 100)
                } else {
                    serviceFeeDaily = convert(base, rates, tariff.guest_commision, tariff.guest_currency, currency)
                }
            } else if (isMonthlyInclude && monthCounts > 0) {
                if (tariff.guest_is_percent) {
                    serviceFeeMonthly = (monthlyPrice * monthCounts) * (Number(tariff.guest_commision) / 100)
                } else {
                    serviceFeeMonthly = convert(base, rates, tariff.guest_commision, tariff.guest_currency, currency)
                }
            }

            if (daysCount > 0) {
                let price = _basePrice

                if (onlyMonthlyRent) {
                    price = _basePrice / momentEndDate.daysInMonth()
                } else if (isAverage > 0) {
                    price = isAverage
                }

                if (tariff.guest_is_percent) {
                    serviceFeeDaily = (price * daysCount) * (Number(tariff.guest_commision) / 100)
                } else {
                    serviceFeeDaily = convert(base, rates, tariff.guest_commision, tariff.guest_currency, currency)
                }
            }
        }



        /*if (isMonthlyInclude) {

            // Current date 14
            // selectedDays 120
            // monthCounts parseInt(dayDifference / 30.44 4.****)


            startDate = moment(startDate).format('YYYY-MM-DD');
            momentStartDate = moment(startDate);
            momentEndDate = moment(endDate);
            dayDifference = momentEndDate.diff(momentStartDate, 'days');
            let monthDifference = momentEndDate.diff(momentStartDate, 'months');
            monthCounts = monthDifference <= 1 ? 0 : monthDifference + 1
            console.log(momentEndDate, momentStartDate, {monthCounts, monthDifference})



            if (monthCounts) {
                for (let i = 0; i < monthCounts; i++) {

                    const stayedNights = []
                    const currentMonth = moment(momentStartDate).add(i, 'months')
                    const getMonthDays = currentMonth.daysInMonth();
                    const pricePerDay = _basePrice / getMonthDays;
                    const month = currentMonth.date(1)

                    for (let i = 0; i < getMonthDays; i++) {
                        let currentDate = moment(month).add(i, 'day');
                        stayedNights.push(currentDate);
                    }

                    stayedNights.map((item, key) => {
                        let isSpecialPricing;
                        if (item) {
                            let pricingRow, currentPrice;
                            isSpecialPricing = specialPricing?.length > 0 && specialPricing?.find(o => moment(item)
                                .format('MM/DD/YYYY') == moment(o.blockedDates).format('MM/DD/YYYY'));
                            if (isSpecialPricing?.isSpecialPrice) {
                                isSpecialPriceAssigned = true;
                                currentPrice = isSpecialPricing?.isSpecialPrice;
                            } else {
                                currentPrice = pricePerDay;
                            }
                            // Price object
                            pricingRow = {
                                blockedDates: item,
                                isSpecialPrice: currentPrice,
                            };
                            bookingSpecialPricing.push(pricingRow);

                        }
                    });
                    totalMonthCounts += 1
                    console.log(stayedNights, lastMonthPayment, pricePerDay * currentMonth.date(), 'test el')
                }
            }

            priceForDays = bookingSpecialPricing.reduce(
                (total, item) => total + item.isSpecialPrice, 0
            );
            discount = 0;
            discountType = null;
            total = 0;

            isAverage = priceForDays / totalMonthCounts;
            isDayTotal = isAverage.toFixed(2) * totalMonthCounts;
            priceForDays = isDayTotal + firstMonthPayment + lastMonthPayment;
            console.log(moment(startDate).daysInMonth(), moment(startDate).date(), momentEndDate, {
                firstMonthPayment,
                lastMonthPayment,
                priceForDays,
                totalMonthCounts
            }, bookingSpecialPricing)
        } else if (startDate && endDate) {
            startDate = moment(startDate).format('YYYY-MM-DD');
            momentStartDate = moment(startDate);
            momentEndDate = moment(endDate);
            dayDifference = momentEndDate.diff(momentStartDate, 'days');

            if (dayDifference > 0) {

                let stayedNights = [];
                // Find stayed nights
                for (let i = 0; i < dayDifference; i++) {
                    let currentDate = moment(startDate).add(i, 'day');
                    stayedNights.push(currentDate);
                }

                if (stayedNights?.length > 0) {
                    stayedNights.map((item, key) => {
                        let isSpecialPricing;
                        if (item) {
                            let pricingRow, currentPrice;
                            currentDay = (moment(item).format('dddd').toLowerCase());
                            isSpecialPricing = specialPricing?.length > 0 && specialPricing?.find(o => moment(item)
                                .format('MM/DD/YYYY') == moment(o.blockedDates).format('MM/DD/YYYY'));
                            if (isSpecialPricing?.isSpecialPrice) {
                                isSpecialPriceAssigned = true;
                                currentPrice = Number(isSpecialPricing?.isSpecialPrice);
                            } else {
                                currentPrice = Number(_basePrice);
                            }
                            // Price object
                            pricingRow = {
                                blockedDates: item,
                                isSpecialPrice: currentPrice,
                            };
                            bookingSpecialPricing.push(pricingRow);
                        }
                    });
                }
            }
            priceForDays = bookingSpecialPricing.reduce(
                (total, item) => total + Number(item.isSpecialPrice), 0
            );
            discount = 0;
            discountType = null;
            total = 0;

            isAverage = Number(priceForDays) / Number(dayDifference);
            isDayTotal = isAverage.toFixed(2) * dayDifference;
            priceForDays = isDayTotal;

        }*/


        if (dayDifference >= 7) {
            if (monthlyDiscount > 0 && dayDifference >= 28) {
                discount = (Number(priceForDays) * Number(monthlyDiscount)) / 100;
                discountType = monthlyDiscount + "% " + formatMessage(messages.monthlyDiscount);
            } else {
                discount = (Number(priceForDays) * Number(weeklyDiscount)) / 100;
                discountType = weeklyDiscount + "% " + formatMessage(messages.weeklyDiscount);
            }
        }
        // discountLessBasePrice = isDayTotal - discount;
        // totalWithoutServiceFee = (isDayTotal + firstMonthPayment + lastMonthPayment + cleaningPrice) - discount;
        totalWithoutTax = (isDayTotal + firstMonthPayment + lastMonthPayment) - discount;
        let guestCommissionPercent = tariff.guest_commision && tariff.guest_is_percent ? tariff.guest_commision : 0
        // if (tariff.guest_commision) {
        //     if (tariff.guest_is_percent) {
        //         serviceFee = totalWithoutServiceFee * (Number(tariff.guest_commision) / 100)
        //         guestCommissionPercent = tariff.guest_commision
        //     } else {
        //         serviceFee = convert(base, rates, tariff.guest_commision, tariff.guest_currency, currency)
        //     }
        // }

        if (tax) taxes = totalWithoutTax * (Number(tax) / 100);

        total = (priceForDays + serviceFee + cleaningPrice + taxes) - discount;

        function LinkWithTooltip({id, children, href, tooltip}) {
            return (
                <OverlayTrigger
                    overlay={<Tooltip className={s.tooltip} id={id}>{tooltip}</Tooltip>}
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                >
                    {children}
                </OverlayTrigger>
            );
        }

        if (isAverage) {
            change("BookingForm", 'averageBasePrice', isAverage);
        }

        const paymentTypeTitle = paymentType.reduce((acc, item) => {
            return acc + (acc.length > 0 ? ', ' : '') + item.listsettings.itemName;
        }, '')

        return (
            <FormGroup className={s.noMargin}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className={'viewListingCalender'}>
                        <table className={cx('table')}>
                            <tbody>
                            {isMonthlyRent && monthCounts > 0 &&
                                <>

                                    <tr className={cx(s.positionR)}>
                                        <td className={cx(s.noBorder)}>
                                            <div className={cx(s.specialPriceText, 'directionLtr')}>
                                                {
                                                    formatMessage(messages.paymentPerMonth)
                                                }
                                            </div>
                                        </td>
                                        <td className={cx(s.noBorder, 'text-right', 'textAlignLeftRtl')}>
                                            <CurrencyConverter
                                                amount={!onlyMonthlyRent ? monthlyPrice : basePrice}
                                                from={currency}
                                            />
                                        </td>
                                    </tr>
                                </>
                            }
                            {isDailyRent &&
                                <>

                                    <tr className={cx(s.positionR)}>
                                        <td className={cx(s.noBorder)}>
                                            <div className={cx(s.specialPriceText, 'directionLtr')}>
                                                {
                                                    formatMessage(messages.paymentPerDay)
                                                }
                                            </div>
                                            {
                                                isSpecialPriceAssigned &&
                                                <div className={cx(s.specialPriceIcon, 'specialpriceRtl')}>
                                                    <span>
                                                        <img src={Faq} className={cx(s.faqImage, 'specialpriceRtl')}/>
                                                    </span>
                                                    <div
                                                        className={cx(s.tltip, s.relativeSection, 'tltipDarkMode', 'relativeSectionRTL')}>
                                                        <FormattedMessage {...messages.averagePricePerNight} />
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        <td className={cx(s.noBorder, 'text-right', 'textAlignLeftRtl')}>
                                            <CurrencyConverter
                                                amount={isSpecialPriceAssigned ? isAverage : basePrice}
                                                from={currency}
                                            />
                                        </td>
                                    </tr>
                                </>
                            }
                            <tr className={cx(s.positionR)}>
                                <td className={cx(s.noBorder)}>
                                    <div className={cx(s.specialPriceText, 'directionLtr')}>
                                        {
                                            formatMessage(messages.rentPeriod)
                                        }
                                    </div>
                                </td>
                                <td className={cx(s.noBorder, 'text-right', 'textAlignLeftRtl')}>
                                    {
                                        monthCounts && monthCounts > 0 ?
                                            <>
                                                {monthCounts} {(monthCounts > 1 ? formatMessage(messages.months) :
                                                formatMessage(messages.month)).toLowerCase()}
                                                <br/>
                                            </>

                                            : ''
                                    }

                                    {
                                        daysCount && daysCount > 0 ?
                                            <>
                                                {daysCount} {(daysCount > 1 ? formatMessage(messages.nights) :
                                                formatMessage(messages.night)).toLowerCase()}
                                                <br/>
                                            </>

                                            : ''
                                    }
                                </td>
                            </tr>
                            {
                                discount > 0 &&
                                <tr>
                                    <td className={s.noBorder}>{discountType}</td>
                                    <td className={cx('text-right', s.discountText, s.noBorder, 'textAlignLeftRtl')}>
                                        <span>- </span>
                                        <span className={cx(s.displayCell, s.priceCss)}>
                                                <CurrencyConverter
                                                    amount={discount}
                                                    from={currency}
                                                />
                                            </span>
                                    </td>
                                </tr>
                            }

                            {
                                <tr>
                                    <td className={s.noBorder}><FormattedMessage {...messages.deposit} /></td>
                                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                                        <CurrencyConverter
                                            amount={deposit || 0}
                                            from={currency}
                                        />
                                    </td>
                                </tr>
                            }

                            { serviceFeeDaily > 0 &&
                                <tr>
                                    <td className={s.noBorder}><FormattedMessage {...messages.serviceFee} />
                                        {tariff.guest_is_percent &&
                                            <Badge className={cx(s.serviceFeeBadge)}>
                                                {guestCommissionPercent}%
                                            </Badge>
                                        }
                                    </td>
                                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                                        <div
                                            className={cx(s.specialPriceText, s.paddingLeft, 'paddingLeftRtl', 'directionLtr')}>
                                            <CurrencyConverter
                                                amount={serviceFeeDaily}
                                                from={currency}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            }

                            { serviceFeeMonthly > 0 &&
                                <tr>
                                    <td className={s.noBorder}><FormattedMessage {...messages.serviceFeeMonthly} />
                                        {tariff.guest_is_percent &&
                                            <Badge className={cx(s.serviceFeeBadge)}>
                                                {guestCommissionPercent}%
                                            </Badge>
                                        }
                                    </td>
                                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                                        <div
                                            className={cx(s.specialPriceText, s.paddingLeft, 'paddingLeftRtl', 'directionLtr')}>
                                            <CurrencyConverter
                                                amount={serviceFeeMonthly}
                                                from={currency}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            }
                            {
                                <tr>
                                    <td className={s.noBorder}><FormattedMessage {...messages.insurance} /></td>
                                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                                        <CurrencyConverter
                                            amount={insurance}
                                            from={currency}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                <tr>
                                    <td className={s.noBorder}><FormattedMessage {...messages.agentCommission} /></td>
                                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                                        <CurrencyConverter
                                            amount={agentCommission}
                                            from={currency}
                                        />
                                    </td>
                                </tr>
                            }
                            {
                                <tr>
                                    <td className={s.noBorder}><FormattedMessage {...messages.publicUtilities} /></td>
                                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                                        {publicUtilitiesType.listsettings.itemName}
                                    </td>
                                </tr>
                            }
                            {
                                taxes > 0 && <tr>
                                    <td className={s.noBorder}><FormattedMessage {...messages.tax} /></td>
                                    <td className={cx('text-right', s.noBorder, 'textAlignLeftRtl')}>
                                        <CurrencyConverter
                                            amount={taxes}
                                            from={currency}
                                        />
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </FormGroup>
        );
    }
}

const mapState = (state) => ({
    specialPricing: state.viewListing.specialPricing
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BillDetails)));
