// Fetch request
import fetch from '../../../core/fetch';

// Redux
import {getSearchResults, loadingSearchResults} from '../../../actions/getSearchResults';

async function submit(values, dispatch) {
    dispatch(loadingSearchResults());
    const query =
        `query(
      $offset: Int
      $loadCount: Int
      $personCapacity: Int,
      $dates: String,
      $currentPage: Int,
      $lat: Float,
      $lng: Float,
      $roomType: [Int],
      $bedrooms: Int,
      $bathrooms: Int,
      $beds: Int,
      $amenities: [Int],
      $safetyAmenities: [Int],
      $spaces: [Int],
      $houseRules: [Int],
      $priceRange: [Float],
      $geography: String,
      $bookingType: String,
      $geoType: String,
      $searchByMap: Boolean,
      $sw_lat: Float,
      $sw_lng: Float,
      $ne_lat: Float,
      $ne_lng: Float,
      $leaseType: Int,
      $rooms: [Int],
      $isOneTotalToggle: Boolean,
      $paymentType: [Int],
      $layoutType: [Int],
      $wallMaterial: [Int],
      $equipment: [Int],
      $floorsRange: [Float],
      $leaseValueRange: [Float],
      $livingAreaRange: [Float],
      $totalAreaRange: [Float],
      $bathroomType: [Int],
      $publicUtilitiesType: [Int],
      $publicUtilities: [Int],
      $cancellationPolicies: [Int],
      $houseType: [Int],
      
    ){
      SearchListing(
        offset: $offset
        loadCount: $loadCount
        personCapacity: $personCapacity,
        dates: $dates,
        currentPage: $currentPage
        lat: $lat,
        lng: $lng,
        roomType: $roomType,
        bedrooms: $bedrooms,
        bathrooms: $bathrooms,
        beds: $beds,
        amenities: $amenities,
        safetyAmenities: $safetyAmenities,
        spaces: $spaces,
        houseRules: $houseRules,
        priceRange: $priceRange,
        geography: $geography,
        bookingType: $bookingType,
        geoType: $geoType,
        searchByMap: $searchByMap,
        sw_lat: $sw_lat,
        sw_lng: $sw_lng,
        ne_lat: $ne_lat,
        ne_lng: $ne_lng,
        leaseType: $leaseType,
        rooms: $rooms,
        paymentType: $paymentType,
        layoutType: $layoutType,
        wallMaterial: $wallMaterial,
        bathroomType: $bathroomType,
        publicUtilitiesType: $publicUtilitiesType,
        publicUtilities: $publicUtilities
        isOneTotalToggle: $isOneTotalToggle,
        floorsRange: $floorsRange,
        livingAreaRange: $livingAreaRange,
        totalAreaRange: $totalAreaRange,
        cancellationPolicies: $cancellationPolicies,
        equipment: $equipment
        leaseValueRange: $leaseValueRange
        houseType: $houseType
      ) {
        count
        results {
          id
          title
          personCapacity
          lat
          lng
          beds
          coverPhoto
          bookingType
          reviewsCount,
          reviewsStarRating,
          listPhotos {
            id
            name
            type
            status
          }
          listingData {
            basePrice
            currency
            minNight
            maxNight
            cleaningPrice
            weeklyDiscount
            monthlyDiscount
            checkInStart
            checkInEnd
            dates
            isOneTotalToggle
            monthlyPrice
            livingArea
            totalArea
            floors
            floor
            leaseMinValue
            leaseMaxValue
            leaseMaxValueType
            leaseMinValueType
            oneTotalPrice
          }
          serviceFees{
            guestType
            guestValue
            currency
          }
          blockedDates{
            blockedDates
            calendarStatus
            isSpecialPrice
          }
          settingsData {
            id
            settingsId
            listsettings {
                id
                itemName
                settingsType {
                    typeName
                    isMultiValue
                }
            }
          }
          wishListStatus
          isListOwner
        }
      }
    }
  `;

    const resp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables: values
        }),
        credentials: 'include',
    });

    const {data} = await resp.json();

    if (data?.SearchListing) {
        dispatch(getSearchResults(data?.SearchListing));
    }

}

export default submit;
