import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Redux
import {connect} from 'react-redux';
import {change, formValueSelector} from 'redux-form';

import CurrencyConverter from '../CurrencyConverter'
import {injectIntl} from 'react-intl';

// External Component
import DayPicker, {DateUtils} from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
    Row,
    Col
} from 'react-bootstrap';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '!isomorphic-style-loader!css-loader!./DayDragCalendar.css';

import SaveCalendar from './SaveCalendar';

// Local
import {isRTL} from '../../helpers/formatLocale';

//Helper
import {getDateUsingTimeZone} from '../../helpers/dateRange';

class DayDragCalendar extends Component {

    static propTypes = {
        change: PropTypes.func,
        formName: PropTypes.string,
        disabledDates: PropTypes.array,
        blockedDates: PropTypes.array,
    };

    static defaultProps = {
        disabledDates: [],
        blockedDates: [],
        listId: null,
        sources: []
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedDays: [],
            from: undefined,
            to: undefined,
            dateRange: [],
            //availableDates: [],
            chooseRangeDate: [],
            isPrice: [],
            sources: []
        };
        this.isDaySelected = this.isDaySelected.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.resetCalendar = this.resetCalendar.bind(this);
        this.renderDay = this.renderDay.bind(this);
        this.resetDatePickerChange = this.resetDatePickerChange.bind(this);
        this.scrollTop = this.scrollTop.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {blockedDates, sources, availableDatesPrices} = this.props;
        if (blockedDates != undefined) {
            this.setState({selectedDays: blockedDates});
        }

        let sourcesValue = [];
        let sourceObject = {};

        availableDatesPrices && availableDatesPrices.map((item, key) => {
            sourceObject = {};
            sourceObject["isSpecialPrice"] = item.isSpecialPrice;
            sourceObject["blockedDates"] = item.date;
            sourcesValue.push(sourceObject);
        });

        this.setState({sources: sourcesValue});
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.onSave()

    }

    scrollTop() {
        window.scrollTo({
            top: 700,
            behavior: 'smooth'
        })
    }

    renderDay(day) {
        const {currency, baseCurrency, isAdminCurrency} = this.props;
        const {dateRange, sources} = this.state;
        const date = day.getDate();
        let dateRangeValue = moment(day).format('YYYY-MM-DD');

        return (
            <div className={s.responsiveDisplay}>
                <span className={'dateFontWeight'}>{date}</span>
                <div>
                    {
                        sources && sources.map((item, key) => {

                            let dateValue = moment(item.blockedDates).format('YYYY-MM-DD');
                            if (dateRangeValue == dateValue) {
                                return (
                                    <div className={'priceAlignment'}>
                                        <CurrencyConverter
                                            amount={item.isSpecialPrice}
                                            from={currency}
                                        />
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>
        );
    }


    isDaySelected(day) {
        const {selectedDays} = this.state;

        if (selectedDays && selectedDays.length > 0) {
            return selectedDays.some(selectedDay =>
                DateUtils.isSameDay(selectedDay, day),
            );
        }
    }

    async handleDayClick(day, {start, end, selected, disabled}) {
        const {blockedDates, change, formName} = this.props;
        let selectedDays = blockedDates.slice();
        let startDate, endDate;
        let dateRange = [], rangeStart, rangeEnd;

        if (disabled) {
            return;
        }

        const range = DateUtils.addDayToRange(day, this.state);
        startDate = range.from;
        endDate = range.to;

        if (startDate && !endDate) {
            rangeStart = new Date(startDate);
            dateRange.push(rangeStart);
        } else if (startDate && endDate) {
            rangeStart = new Date(startDate);
            rangeEnd = new Date(endDate);

            if (!DateUtils.isSameDay(rangeStart, rangeEnd)) {
                dateRange.push(rangeStart);

                rangeStart = new Date(+rangeStart);

                while (rangeStart < endDate) {

                    dateRange.push(rangeStart);
                    var newDate = rangeStart.setDate(rangeStart.getDate() + 1);
                    rangeStart = new Date(newDate);
                }
            } else {
                startDate = null;
                endDate = null;
                dateRange, selectedDays = [];
            }
        }

        const isBrowser = typeof window !== 'undefined';
        let smallDevice = isBrowser ? window.matchMedia('(max-width: 768px)').matches : true;
        if (smallDevice) this.scrollTop();

        this.setState({selectedDays, dateRange, from: startDate, to: endDate});

        change('ListingNewForm', 'startDate', startDate)
        change('ListingNewForm', 'endDate', endDate)
    }

    resetCalendar() {
        const {change} = this.props;
        // this.setState({ dateRange: [], from: null, to: null, startDate: null, endDate: null });
        this.setState({dateRange: [], from: null, to: null, startDate: null, endDate: null});
        change('ListingNewForm', 'startDate', null)
        change('ListingNewForm', 'endDate', null)
    }

    onSave () {
        const {blockedDates, sources, availableDatesPrices} = this.props;
        if (blockedDates != undefined) {
            this.setState({selectedDays: blockedDates});
        }
        let sourcesValue = [];
        let sourceObject = {};

        availableDatesPrices && availableDatesPrices.map((item, key) => {
            sourceObject = {};
            sourceObject["isSpecialPrice"] = item.isSpecialPrice;
            sourceObject["blockedDates"] = item.date;
            sourcesValue.push(sourceObject);
        });

        this.setState({sources: sourcesValue});
    }

    resetDatePickerChange() {
        const {change} = this.props;
        this.setState({dateRange: [], from: null, to: null});
    }

    render() {
        const {selectedDays, from, to, dateRange} = this.state;
        const {disabledDates, formName, listId, availableDates, todayLabel, halfDates, listingFields: {leaseType: _leaseTypes}, leaseTypes} = this.props;
        const {availableDatesPrices} = this.props;
        const {sources} = this.state;
        const {minNight, maxNight, houseRules, checkInEnd, checkInStart, locale} = this.props;
        const {cancellationPolicy, maxDaysNotice, bookingNoticeTime, weeklyDiscount, monthlyDiscount} = this.props;
        const {cleaningPrice, basePrice, currency, tax} = this.props;
        const {isStartDate, isEndDate, country} = this.props;

        let listCountryDate = getDateUsingTimeZone(country, false), isExistMonthlyType = false;
        let today = new Date(listCountryDate.get('year'), listCountryDate.get('month'), listCountryDate.get('date'), listCountryDate.get('hour'), listCountryDate.get('minute'), listCountryDate.get('second'));
        if (_leaseTypes) {
            isExistMonthlyType = _leaseTypes.filter(item => leaseTypes.includes(item.id)).some(item => item.otherItemName === 'month');
        }
        const modifiers = {
            start: from,
            end: to,
            selected: selectedDays,
            selecting: dateRange,
            available: availableDates,
            availableStart: halfDates && halfDates.secondHalfAvailable,
            availableEnd: halfDates && halfDates.firstHalfAvailable,
            selectedStart: halfDates && halfDates.secondHalfBlocked,
            selectedEnd: halfDates && halfDates.firstHalfBlocked,
            bookedStart: halfDates && halfDates.secondHalfDisabled,
            bookedEnd: halfDates && halfDates.firstHalfDisabled,
            booked: disabledDates,
            secondHalfBlocked: halfDates && halfDates.secondHalfBlocked,
        };

        return (
            <Row>
                <Col lg={8} md={12} sm={12} xs={12} className={'saveCalender'}>

                    <DayPicker
                        selectedDays={[this.isDaySelected, from, {from, to}]}
                        onDayClick={this.handleDayClick}
                        modifiers={modifiers}
                        disabledDays={[DateUtils.isPastDay, ...disabledDates]}
                        fromMonth={today}
                        renderDay={this.renderDay}
                        locale={isRTL(locale) ? locale : 'en-US'}
                        localeUtils={MomentLocaleUtils}
                        todayButton={todayLabel}
                        className={'BecomeCalendar'}
                        transitionDuration={0}
                        dir={isRTL(locale) ? 'rtl' : 'ltr'}
                    />
                </Col>
                <Col lg={4} md={4} sm={6} xs={12}>

                    <SaveCalendar
                        selectedDays={dateRange}
                        start={from}
                        end={to}
                        formName={formName}
                        resetCalendar={this.resetCalendar}
                        onSave={this.onSave}
                        resetDatePickerChange={this.resetDatePickerChange}
                        listId={listId}
                        minNight={minNight}
                        maxNight={maxNight}
                        houseRules={houseRules}
                        checkInEnd={checkInEnd}
                        checkInStart={checkInStart}
                        showBlockingTab={!isExistMonthlyType}
                        cancellationPolicy={cancellationPolicy}
                        maxDaysNotice={maxDaysNotice}
                        bookingNoticeTime={bookingNoticeTime}
                        cleaningPrice={cleaningPrice}
                        basePrice={basePrice}
                        currency={currency}
                        isStartDate={isStartDate}
                        isEndDate={isEndDate}
                        tax={tax}
                        weeklyDiscount={weeklyDiscount}
                        monthlyDiscount={monthlyDiscount}
                    />
                </Col>
            </Row>
        );
    }

}


const selector = formValueSelector('ListingNewForm');
const step1FormSelector = formValueSelector('ListingNewForm');

const mapState = (state) => ({
    isStartDate: selector(state, 'startDate'),
    isEndDate: selector(state, 'endDate'),
    locale: state.intl.locale,
    country: step1FormSelector(state, 'country'),
    halfDates: selector(state, 'halfDates'),
    leaseTypes: step1FormSelector(state, 'leaseType') || [],
    listingFields: state.listingFields.data
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DayDragCalendar)));

