import {LISTING_TYPE, LISTING_STEPS} from '../constants';

export function setListingType({ name, value }) {
    return {
        type: LISTING_TYPE,
        payload: {
            name,
            value,
        },
    };
}

export function setListingStep({ name, value }) {
    return {
        type: LISTING_STEPS,
        payload: {
            value,
            name
        },
    };
}
