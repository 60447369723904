import {gql} from 'react-apollo';
import {
    GET_LISTING_DATA_START,
    GET_LISTING_DATA_SUCCESS,
    GET_LISTING_DATA_ERROR
} from '../../constants';
import {initialize, change} from 'redux-form';
import moment from "moment/moment";

const query = gql`
    query ($listId:String!, $preview: Boolean) {
        UserListing (listId:$listId, preview: $preview) {
            id
            userId
            country
            street
            buildingName
            city
            bookingType
            isPublished
            listApprovalStatus
            state
            zipcode
            lat
            lng
            isMapTouched
            bedrooms
            residenceType
            beds
            personCapacity
            bathrooms
            description
            coverPhoto
            kuulaLink
            tariff_id
            cadastralNumber
            district_id
            region_id
            floors
            houseRules {
                houseRulesId
            }
            listingData {
                livingArea
                basePrice
                floor
                floors
                totalArea
                currency
                bookingNoticeTime,
                checkInStart,
                checkInEnd,
                maxDaysNotice,
                minNight,
                maxNight,
                cleaningPrice,
                tax,
                weeklyDiscount,
                monthlyDiscount,
                cancellationPolicy,
                taxRate,
                leaseMinValue,
                leaseMaxValue,
                deposit,
                priceAuction
                monthlyPrice
                leaseMinValueType
                leaseMaxValueType
            }
            user {
                id
                email
                userBanStatus
                profile{
                    firstName
                    lastName
                    dateOfBirth
                }
            }
            userAmenities {
                amenitiesId
                listsettings{
                    itemName
                    settingsType {
                        typeName
                    }
                }
            }
            userSafetyAmenities {
                safetyAmenitiesId
                listsettings{
                    itemName
                    settingsType {
                        typeName
                    }
                }
            }
            userSpaces {
                spacesId
                listsettings{
                    itemName
                    settingsType {
                        typeName
                    }
                }
            }
            settingsData {
                id
                settingsId
                listsettings {
                    id
                    itemName
                    settingsType {
                        typeName
                        isMultiValue
                    }
                }
            }
            userBedsTypes{
                id
                listId
                bedCount
                bedType
            }
            blockedDates {
                blockedDates
                reservationId
                calendarStatus
                isSpecialPrice
                dayStatus
            }
            calendars {
                id
                name
                url
                listId
                status
            }
            listBlockedPrice {
                listId
                calendarStatus
                isSpecialPrice
                blockedDates
                calendarId
                reservationId
                dayStatus
            }
            equipmentsValues {
                id
                listId
                settingsId
                model
                brand
                year
                speed
                listsettings {
                    id
                    typeId
                    itemName
                }
            }
        }
    }
`;

export function getListingData(listId) {
    return async (dispatch, getState, {client}) => {
        dispatch({
            type: GET_LISTING_DATA_START,
        });
        try {
            // Send Request to get listing data
            const {data} = await client.query({
                query,
                variables: {listId, preview: true},
                fetchPolicy: 'network-only',
            });
            let formValues = null, settingFieldsData = {}, listData = {}, calendars = {},
                updatedAvailableDatesPrices = [], bedTypes = [];
            const houseRules = [], updatedBlockedDates = [], updatedDisabledDates = [], updatedAvailableDates = [],
                firstHalfBlocked = [];
            const secondHalfBlocked = [], firstHalfAvailable = [], secondHalfAvailable = [], firstHalfDisabled = [],
                secondHalfDisabled = [], fullBlockedDates = [], safetyAmenities = [], amenities = [], spaces = [];
            settingFieldsData = {
                id: data.UserListing.id,
                description: data.UserListing.description,
                coverPhoto: data.UserListing.coverPhoto,
                kuulaLink: data.UserListing.kuulaLink,
                userId: data.UserListing.userId,
            };
            listData = data?.UserListing?.listingData;
            calendars = data?.UserListing?.calendars;
            if (data && data.UserListing) {
                // Preparing for list settings data
                data.UserListing.settingsData.map((item, value) => {
                    if (item.listsettings.settingsType.isMultiValue) {
                        if (settingFieldsData[item.listsettings.settingsType.typeName] === undefined) {
                            settingFieldsData[item.listsettings.settingsType.typeName] = [];
                        }
                        settingFieldsData[item.listsettings.settingsType.typeName].push(item.settingsId);
                    } else {
                        settingFieldsData[item.listsettings.settingsType.typeName] = item.settingsId;
                    }
                });
                // Preparing for house rules
                if (data?.UserListing?.houseRules?.length > 0) {
                    data?.UserListing?.houseRules?.map((item, value) => {
                        houseRules.push(parseInt(item?.houseRulesId));
                    });
                    settingFieldsData = Object.assign({}, settingFieldsData, listData, {houseRules});
                }

                if (data?.UserListing?.blockedDates?.length > 0) {

                    const todayDate = moment.utc(new Date()).format('YYYY-MM-DD');
                    data?.UserListing?.blockedDates?.map((item, value) => {
                        const itemDate = moment.utc(item?.blockedDates).format('YYYY-MM-DD');
                        const newItemDate = new Date(
                            moment.utc(new Date(item?.blockedDates)).format('l LT')
                        );
                        if (itemDate >= todayDate) {
                            if (item?.reservationId) {
                                updatedDisabledDates.push(newItemDate);
                                if (item?.dayStatus === 'firstHalf') {
                                    firstHalfDisabled.push(newItemDate);
                                } else if (item?.dayStatus === 'secondHalf') {
                                    secondHalfDisabled.push(newItemDate);
                                } else if (item?.reservationId && (item?.dayStatus !== 'firstHalf' || item?.dayStatus !== 'secondHalf')) {
                                    fullBlockedDates.push(newItemDate);
                                }
                            }
                            if (item?.calendarStatus === 'available') {
                                updatedAvailableDates.push(newItemDate);
                                updatedAvailableDatesPrices.push({
                                    date: newItemDate,
                                    isSpecialPrice: item?.isSpecialPrice,
                                });
                                if (item?.dayStatus === 'firstHalf') {
                                    firstHalfAvailable.push(newItemDate);
                                } else if (item?.dayStatus === 'secondHalf') {
                                    secondHalfAvailable.push(newItemDate);
                                }

                            }
                            if (item?.calendarStatus && item?.calendarStatus === 'blocked' && !item.reservationId) {
                                updatedBlockedDates.push(newItemDate);
                                if (item?.dayStatus === 'firstHalf') {
                                    firstHalfBlocked.push(newItemDate);
                                } else if (item?.dayStatus === 'secondHalf') {
                                    secondHalfBlocked.push(newItemDate);
                                }
                            }
                        }
                    });
                }
                updatedAvailableDatesPrices = updatedAvailableDatesPrices.reduce(
                    (data, o) => {
                        if (
                            !data.some(
                                (obj) =>
                                    moment(obj.date).format('YYYY-MM-DD') ===
                                    moment(o.date).format('YYYY-MM-DD')
                            )
                        ) {
                            data.push(o);
                        }
                        return data;
                    },
                    []
                );
                settingFieldsData = Object.assign({}, settingFieldsData, listData, {
                    disabledDates: updatedDisabledDates,
                    blockedDates: updatedBlockedDates,
                    availableDates: updatedAvailableDates,
                    availableDatesPrices: updatedAvailableDatesPrices,
                    halfDates: {
                        firstHalfAvailable,
                        secondHalfAvailable,
                        firstHalfBlocked,
                        secondHalfBlocked,
                        firstHalfDisabled,
                        secondHalfDisabled,
                    },
                    fullBlockedDates,
                });
                // Preparing for user amenities
                if (data.UserListing.userAmenities.length > 0) {
                    data.UserListing.userAmenities.map((item, value) => {
                        amenities.push(parseInt(item.amenitiesId));
                    });
                    settingFieldsData = Object.assign({}, settingFieldsData, {amenities});
                }
                // Preparing for user safety amenities
                if (data.UserListing.userSafetyAmenities.length > 0) {
                    data.UserListing.userSafetyAmenities.map((item, value) => {
                        safetyAmenities.push(parseInt(item.safetyAmenitiesId));
                    });
                    settingFieldsData = Object.assign({}, settingFieldsData, {safetyAmenities});
                }
                // Preparing for User Spaces
                if (data.UserListing.userSpaces.length > 0) {
                    data.UserListing.userSpaces.map((item, value) => {
                        spaces.push(parseInt(item.spacesId));
                    });
                    settingFieldsData = Object.assign({}, settingFieldsData, {spaces});
                }

                if (data.UserListing.listingData.livingArea) {
                    settingFieldsData.livingArea = data.UserListing.listingData.livingArea;
                }


                if (data.UserListing.listingData.totalArea) {
                    settingFieldsData.totalArea = data.UserListing.listingData.totalArea;
                }

                if (data.UserListing.listingData.floor) {
                    settingFieldsData.floor = data.UserListing.listingData.floor;
                }

                if (data.UserListing.listingData.floors) {
                    settingFieldsData.floors = data.UserListing.listingData.floors;
                }

                if (data.UserListing.listingData.basePrice) {
                    settingFieldsData.basePrice = data.UserListing.listingData.basePrice;
                }
                if (data.UserListing.listingData.currency) {
                    settingFieldsData.currency = data.UserListing.listingData.currency;
                }
                let equipmentsValues = {}
                if (data.UserListing.equipmentsValues) {
                    data.UserListing.equipmentsValues.forEach(item => {

                        equipmentsValues[`${item.listsettings.itemName}|${item.settingsId}`] = {
                            model: item.model,
                            brand: item.brand,
                            year: item.year,
                            speed: item.speed
                        }
                    })
                }

                bedTypes = data.UserListing.userBedsTypes;
                settingFieldsData = Object.assign({}, settingFieldsData, {bedTypes});
                // Combining values for initializing the edit form
                await dispatch(change('ListingNewForm', 'calendars', calendars));
                if (updatedBlockedDates) {
                    await dispatch(change('ListingNewForm', 'blockedDates', updatedBlockedDates));
                } else if (updatedAvailableDates) {
                    await dispatch(change('ListingNewForm', 'blockedDates', updatedAvailableDates));
                } else if (updatedAvailableDatesPrices) {
                    await dispatch(change('ListingNewForm', 'blockedDates', updatedAvailableDatesPrices));
                }
                formValues = Object.assign({}, {id: listId}, data.UserListing, settingFieldsData, listData, calendars, {equipmentValues: equipmentsValues});
                if (formValues != null) {
                    // Reinitialize the form values
                    dispatch(initialize('ListingNewForm', formValues));
                    // Dispatch a success action
                    dispatch({
                        type: GET_LISTING_DATA_SUCCESS,
                        step1DataIsLoaded: true,
                        isExistingList: true,
                        initialValuesLoaded: false,
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: GET_LISTING_DATA_ERROR,
                payload: {
                    error,
                },
            });
            return false;
        }
        return true;
    };
}
