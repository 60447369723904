import {gql} from "react-apollo";

import {
    GET_DISTRICT_SUCCESS,
    GET_DISTRICT_ERROR,
    GET_DISTRICT_START,
} from "../constants";

const query = gql`
    {
        getDistricts {
            id
            isEnable
            regionId
            districtName
        }
    }
`;

export function getDistricts() {
    return async (dispatch, getState, {client}) => {
        dispatch({
            type: GET_DISTRICT_START,
        });

        try {
            // Send Request to get listing data
            const {data} = await client.query({
                query,
                fetchPolicy: "network-only",
            });

            if (data && data.getDistricts) {
                dispatch({
                    type: GET_DISTRICT_SUCCESS,
                    districts: data.getDistricts,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_DISTRICT_ERROR,
            });
            return false;
        }

        return true;
    };
}
