import BaseForm from '../base/BaseForm';
import {reduxForm, Field} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import CurrencyConverter from '../../CurrencyConverter'
class Tariff extends BaseForm {

    renderTariffCards = ({input, meta: {error, touched}, className, options}) => {
        const {formatMessage} = this.props.intl;
        const {base} = this.props


        return (
            <div className={cx(s.pricingContainer)}>
                {options && options.map((item) => {
                    let price = formatMessage(messages.free)
                    let tariffPeriod = ''
                    let currency = item.currency
                    if (!currency) {
                        currency = base
                    }
                    if (item.host_commision) {
                        if (item.host_is_percent) {
                            price = `${item.host_commision} %`
                        } else {
                            price = <CurrencyConverter
                                amount={item.host_commision}
                                from={currency}
                            />
                        }

                        if (item.type === 'monthly') {
                            tariffPeriod = formatMessage(messages.monthly)
                        } else if (item.type === 'yearly') {
                            tariffPeriod = formatMessage(messages.yearly)
                        } else {
                            tariffPeriod = formatMessage(messages.daily)
                        }

                    }
                    return (
                        <div key={item.id}
                             className={cx(s.pricingCard, {[s.featured]: item.id == input.value}, 'bgBlackTwo', 'shadow')}>
                            <div className={cx(s.planHeader)}>
                                <h2 className={cx(s.planName)}>{item.name}</h2>
                                <div className={cx(s.planPrice, "textWhite")}>{price}</div>
                                <div className={cx(s.planPeriod)}>{tariffPeriod}</div>
                                {item.description &&
                                    <p className={cx(s.planDescription, "textWhite")}>{item.description}</p>}
                            </div>
                            <ul className={cx(s.featuresList)}>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.advertising ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.advertising ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.advertising)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.customer_support ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.customer_support ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.customerSupport)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.risk_insurance ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.risk_insurance ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.riskInsurance)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.apartment_tour ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.apartment_tour ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.apartmentTour)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.checkIn_tenants ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.checkIn_tenants ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.checkInTenants)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.premium_sections ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.premium_sections ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.premiumSections)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.search_priority ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.search_priority ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.searchPriority)}
                                    </span>
                                </li>
                                <li className={cx(s.featureItem)}>
                                    <span
                                        className={cx(s.featureIcon, item.service_guarantee ? s.iconCheck : s.iconCross, "textWhite")}>
                                        {item.service_guarantee ? '✓' : '✕'}
                                    </span>
                                    <span className={cx(s.featureText, "textWhite")}>
                                        {formatMessage(messages.serviceGuarantee)}
                                    </span>
                                </li>
                            </ul>
                            <a href="#" onClick={(e) => {
                                e.preventDefault()
                                input.onChange(item.id);

                            }}
                               className={cx(s.planButton, {[s.primary]: item.id == input.value}, item.id !== input.value ? 'textWhite' : '')}>
                                <FormattedMessage {...messages.select} />
                            </a>
                        </div>
                    )

                })}
            </div>
        )
    }

    render() {
        const {
            handleSubmit,
            nextPage,
            previousPage,
            formPage,
            step,
            createNewListing,
            aviableTariffs,
        } = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <div >
                <FormGroup className={cx(s.formGroup, s.space4)}>
                    <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                        <FormattedMessage {...messages.tariff} /> <span className={s.required}>*</span>
                    </ControlLabel>

                    <Field
                        name="tariff_id"
                        component={this.renderTariffCards}
                        options={aviableTariffs}
                    >

                    </Field>
                </FormGroup>
            </div>
        );
    }

}

Tariff = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Tariff)

const mapState = (state) => ({
    aviableTariffs: state.tariff?.aviableTariffs,
    base: state.currency.base
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Tariff)));
