import {SET_PERSONALIZED_VALUES, SET_ROOMS_COUNT} from '../constants';

export function setRoomsCount({ name, value }) {
  return {
    type: SET_ROOMS_COUNT,
    payload: {
      name,
      value,
    },
  };
}
