import BaseForm from '../base/BaseForm';
import {reduxForm, Field, formValueSelector, change} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import InputMask from "react-input-mask";
import validateAbout from "../validations/validateAbout";
import {DateUtils} from "react-day-picker";

class About extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],

        }
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedLeaseType: [],
        };
        this.onChangeLease = this.onChangeLease.bind(this)
        this.handleLeaseTypeChange = this.handleLeaseTypeChange.bind(this)
        this.onChangeLease = this.onChangeLease.bind(this)
    }

    componentDidMount() {
        const {listBlockedPrice, listingFields} = this.props;
        if (listingFields != undefined && listingFields.leaseType) {
            const {leaseTypeValue} = this.props;
            const initialLeaseType = leaseTypeValue ||
                (listingFields.leaseType.find(item => !item.parentId)?.id);

            if (initialLeaseType) {
                this.onChangeLease(leaseTypeValue || [initialLeaseType]);
            }
        }
    }


    handleLeaseTypeChange = (selectedLeaseTypeId) => {
        const {listingFields} = this.props;
        const selectedLeaseType = selectedLeaseTypeId ? listingFields.leaseType.find(item => item.id === parseInt(selectedLeaseTypeId, 10)) : listingFields.leaseType[0];
        this.setState({selectedLeaseType});
    }

    renderCadastrNumberField = ({input, label, type, meta: {touched, error}, className, changeCadastalNumber}) => {
        function beforeMaskedStateChange({nextState}) {
            let {value} = nextState;
            if (value.endsWith(":")) {
                value = value.slice(0, -1);
            }

            return {
                ...nextState,
                value
            };
        }

        return (
            <InputMask
                name="cadastralNumber"
                value={input.value}
                onChange={(e) => {
                    changeCadastalNumber(e)
                    input.onChange(e)
                }}
                maskChar={null}
                alwaysShowMask="true"
                className={cx(s.formControlInput, s.jumboInput, 'form-control')}
                mask={"99:99:99:99:99:9999:9999"}
                style={{
                    width: "100%"
                }}
            />
        )
    }

    changeCadastalNumber = (e) => {
        this.setState({
            cadastralNumber: e.target.value
        })
    }

    renderLeaseTypes = ({label, name, options, input, className, handleChange}) => {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex, s.space0)}>
                {options && options.map((option, index) => {
                    if (option.isEnable == "1") {
                        return (
                            <li className={cx('amenitiesCheckBox')} key={index}>
                                <div className={cx(s.checkBoxOutline, className)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: input.value.indexOf(option.id) !== -1},
                                        {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                         onClick={() => {
                                             const newValue = [...input.value];
                                             if (newValue.indexOf(option.id) !== -1) {
                                                 newValue.splice(newValue.indexOf(option.id), 1);
                                             } else {
                                                 newValue.push(option.id);
                                             }
                                             handleChange(newValue)
                                             input.onChange(newValue);

                                         }}>
                                        <div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listBlockedPrice, listingFields} = nextProps;
        if (listingFields != undefined && listingFields.leaseType) {
            const {leaseTypeValue} = nextProps;
            const initialLeaseType = leaseTypeValue ||
                (listingFields.leaseType.find(item => !item.parentId)?.id);

            if (initialLeaseType) {
                this.onChangeLease(leaseTypeValue || [initialLeaseType]);
            }
        }
    }


    onChangeLease(values) {
        const {listingFields: {leaseType}} = this.props;
        const isExistMonthlyType = leaseType.filter(item => values.includes(item.id))
            this.setState({
                selectedLeaseType: isExistMonthlyType
            })

    }

    render() {
        const {
            listingFields: {
                rooms,
                layoutType, repairType, wallMaterial, ceilingHeight, elevatorType,
                leaseType,
                bookingGoals,
                houseType
            }, handleSubmit, change
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {selectedLeaseType, isLoading} = this.state;

        const isMonthlyIncluded = selectedLeaseType.some(item => item.otherItemName == 'month')
        const isDailyIncluded = selectedLeaseType.some(item => item.otherItemName == 'day')
        const dailyLease = leaseType.find(item => item.otherItemName === 'day')
        const monthlyLease = leaseType.find(item => item.otherItemName === 'month')

        let singularLabelMinPeriod = ''
        let singularLabelMaxPeriod = ''
        let pluralLabelMinPeriod = ''
        let pluralLabelMaxPeriod = ''

        let leaseMinPeriod =  1, leaseMaxPeriod = 31

        if (isMonthlyIncluded && isDailyIncluded) {

            leaseMinPeriod = dailyLease.startValue
            leaseMaxPeriod = monthlyLease.endValue
            singularLabelMinPeriod  = formatMessage(messages.day_)
            pluralLabelMinPeriod  = formatMessage(messages.days_)
            singularLabelMaxPeriod  = formatMessage(messages.month)
            pluralLabelMaxPeriod  = formatMessage(messages.months)

        } else {

            const lease = selectedLeaseType[0] || dailyLease
            leaseMinPeriod = lease.startValue
            leaseMaxPeriod = lease.endValue
            const label = [
                lease.otherItemName === 'day' ? formatMessage(messages.day_) : formatMessage(messages.month),
                lease.otherItemName === 'day' ? formatMessage(messages.days_) : formatMessage(messages.months),
            ]
            singularLabelMinPeriod  = label[0]
            pluralLabelMinPeriod  = label[1]
            singularLabelMaxPeriod  = label[0]
            pluralLabelMaxPeriod  = label[1]
        }


        return (
            <div className={cx(s.listingForm, 'bgBlackTwo')}>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.whatTypeOfProperty} /> <span
                                className={s.required}>*</span>
                            </ControlLabel>
                            <Field name="houseType" component={this.renderRadioGroup} options={houseType}
                                   className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.leaseType} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="leaseType"
                                component={this.renderLeaseTypes}
                                options={leaseType}
                                className={cx(s.small, s.maxContent)}
                                handleChange={this.onChangeLease}
                            >
                            </Field>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.leaseValueMin} /> <span
                                className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="leaseMinValue"
                                type="text"
                                component={this.renderIncrementButton}
                                labelSingluar={singularLabelMinPeriod}
                                labelPlural={pluralLabelMinPeriod}
                                maxValue={leaseMaxPeriod || 31}
                                minValue={leaseMinPeriod || 1}
                                incrementBy={1}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.leaseValueMax} /> <span
                                className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="leaseMaxValue"
                                type="text"
                                component={this.renderIncrementButton}
                                labelSingluar={singularLabelMaxPeriod}
                                labelPlural={pluralLabelMaxPeriod}
                                maxValue={leaseMaxPeriod || 31}
                                minValue={leaseMinPeriod || 1}
                                incrementBy={1}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.bookingGoals} />
                            </ControlLabel>
                            <Field
                                name="bookingGoals"
                                component={this.renderButtonGroup}
                                options={bookingGoals}
                                className={cx(s.small, s.maxContent)}
                                handleChange={this.onChangeLease}
                            >
                            </Field>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.cadastralNumber} />
                            </ControlLabel>
                            <Field
                                name="cadastralNumber"
                                component={this.renderCadastrNumberField}
                                changeCadastalNumber={this.changeCadastalNumber}
                                className={cx(s.formControlInput, s.jumboInput)}
                                label={formatMessage(messages.cadastralNumber)}
                            />
                        </FormGroup>
                    </Col>

                </Row>
            </div>
        )
    }

}

About = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateAbout
})(About)
const selector = formValueSelector('ListingNewForm')
const mapState = (state) => ({
    listingFields: state.listingFields.data,
    leaseTypeValue: selector(state, 'leaseType')
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(About)));
