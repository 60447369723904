import { gql } from 'react-apollo';
import { initialize } from 'redux-form';
import {
  GET_LISTING_STEPS_DATA_START,
  GET_LISTING_STEPS_DATA_SUCCESS,
  GET_LISTING_STEPS_DATA_ERROR,
  RESET_LISTING_STEPS_DATA_START,
  RESET_LISTING_STEPS_DATA_SUCCESS,
  RESET_LISTING_STEPS_DATA_ERROR
} from '../../constants';
// Action
// For Step#1
import { getListingData } from './getListing';
// For Step#2
import { getListingFieldsValues } from './getListingFieldsValues';
import getCancelPolicies from '../../routes/siteadmin/cancellationPolicies/getCancelPolicies.graphql';
import { getListPhotos } from './getListingPhotos';
const query = gql`
  query ($listId:String!) {
    showListingSteps (listId:$listId) {
      id
      listId
      step1
      step2
      step3
      step4
      listing {
        id
        isReady
        isPublished
        listApprovalStatus
        user {
          id
          email
          userBanStatus
          userDeletedAt
        }
      }
    }
  }
`;
export function getListingSteps(listId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_LISTING_STEPS_DATA_START,
      listingSteps: undefined,
      isExistingList: undefined,
      isLocationChosen: undefined,
      step1DataIsLoaded: undefined,
      step2DataIsLoaded: undefined,
      step3DataIsLoaded: undefined,
      initialValuesLoadedStep2: undefined,
      initialValuesLoadedStep3: undefined,
      photosCount: undefined,
      listPhotos: undefined,
      stepsLoading: true,
    });
    // For Adding new list
    if (listId === undefined || listId === null) {
      // Load initial data for step#1
      dispatch(getListingFieldsValues());
      const cancelData = await client.query({
        query: getCancelPolicies,
        fetchPolicy: 'network-only'
      });
      dispatch({
        type: GET_LISTING_STEPS_DATA_SUCCESS,
        cancellationPolicies: cancelData && cancelData.data.getCancelPolicies && cancelData.data.getCancelPolicies.status == 200 ? cancelData.data.getCancelPolicies.results : []
      });
      return true;
    }
    try {
      // Send Request to get listing data
      const { data } = await client.query({
        query,
        variables: { listId },
        fetchPolicy: 'network-only'
      });

      const cancelData = await client.query({
        query: getCancelPolicies,
        fetchPolicy: 'network-only'
      });
      if (data && data.showListingSteps) {
        // Assign steps data to a const

        if (listId) {
          dispatch(getListingData(listId));
          dispatch(getListPhotos(listId));

        } else {
          dispatch(getListingFieldsValues());
        }

        // Update List Steps on the store & mark it as existing list
        dispatch({
          type: GET_LISTING_STEPS_DATA_SUCCESS,
          listingSteps: data.showListingSteps,
          isExistingList: true,
          stepsLoading: false,
          cancellationPolicies: cancelData && cancelData.data.getCancelPolicies && cancelData.data.getCancelPolicies.status == 200 ? cancelData.data.getCancelPolicies.results : []
        });
      }
    } catch (error) {
      dispatch({
        type: GET_LISTING_STEPS_DATA_ERROR,
        error,
        stepsLoading: false,
      });
      return false;
    }
    return true;
  };
}
export function resetListingSteps() {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: RESET_LISTING_STEPS_DATA_START,
      stepsLoading: false,
    });

    try {
      await dispatch(initialize('ListPlaceStep1', {}));
      await dispatch(initialize('ListPlaceStep2', {}));
      await dispatch(initialize('ListPlaceStep3', {}));

      await dispatch({
        type: RESET_LISTING_STEPS_DATA_SUCCESS,
        listingSteps: undefined,
        isExistingList: undefined,
        isLocationChosen: undefined,
        step1DataIsLoaded: undefined,
        step2DataIsLoaded: undefined,
        step3DataIsLoaded: undefined,
        initialValuesLoadedStep2: undefined,
        initialValuesLoadedStep3: undefined,
        photosCount: undefined,
        listPhotos: undefined,
        stepsLoading: false,
      });
    } catch (error) {
      await dispatch({
        type: RESET_LISTING_STEPS_DATA_ERROR,
        stepsLoading: false,
      });
      return false;
    }
    return true;
  };
}
