import React, {Component} from "react";
import PropTypes from "prop-types";
import {injectIntl, FormattedMessage} from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Button from "react-bootstrap/lib/Button";
import cx from "classnames";
import {reduxForm, change, submit as submitForm, getFormValues, Field, formValueSelector} from "redux-form";
import {connect} from "react-redux";

import messages from "../../../locale/messages";
import s from "./MoreFilters.css";
import {ControlLabel, FormControl, FormGroup, Modal} from "react-bootstrap";
import {Range} from "rc-slider";
import SearchForm from "./SearchFormV2";
import {closeSearchModal, openSearchModal} from "../../../actions/modalActions";


class SearchModal extends Component {
    static propTypes = {
        className: PropTypes.any,
        handleTabToggle: PropTypes.any,
        isExpand: PropTypes.bool,
    };

    static defaultProps = {
        isExpand: false,
        fieldsSettingsData: {
            roomType: [],
            essentialsAmenities: [],
            safetyAmenities: [],
            spaces: [],
            houseRules: [],
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            tabs: {
                dates: false,
                guests: false,
                price: false,
                instantBook: false,
                moreFilters: false,
            },
            overlay: false,
            smallDevices: false,
            verySmallDevice: false,
            tabletDevices: false,
        };
    }

    componentDidMount() {
        const {isExpand} = this.props;
        let isBrowser = typeof window !== "undefined";
        if (isBrowser) {
            this.handleResize();
            window.addEventListener("resize", this.handleResize);
        }
        if (isExpand) {
            document.querySelector("body").setAttribute("style", "overflow: hidden");
        }
    }

    componentWillUnmount() {
        let isBrowser = typeof window !== "undefined";
        if (isBrowser) {
            window.removeEventListener("resize", this.handleResize);
        }
        document.querySelector("body").removeAttribute("style");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {isExpand} = nextProps;
        if (isExpand) {
            document.querySelector("body").setAttribute("style", "overflow: hidden");
        } else {
            document.querySelector("body").removeAttribute("style");
        }
    }

    handleResize = (e) => {
        const {tabs} = this.state;
        let isBrowser, smallDevices, verySmallDevice, tabletDevices;
        isBrowser = typeof window !== "undefined";
        smallDevices = isBrowser ? window.matchMedia("(max-width: 767px)").matches : false;
        verySmallDevice = isBrowser ? window.matchMedia("(max-width: 480px)").matches : false;
        tabletDevices = isBrowser ? window.matchMedia("(max-width: 1024px)").matches : false;

        for (let key in tabs) {
            tabs[key] = false;
        }

        this.setState({
            smallDevices,
            verySmallDevice,
            tabs,
            overlay: false,
            tabletDevices,
        });
    };

    handleTabToggle = (currentTab, isExpand) => {
        const {tabs} = this.state;
        for (let key in tabs) {
            if (key == currentTab) {
                tabs[key] = isExpand;
            } else {
                tabs[key] = false;
            }
        }

        this.setState({
            tabs,
            overlay: isExpand,
        });
    };

    handleSubmitForm = async () => {
        const {closeMoreFiltersModal, handleSubmit, change} = this.props;
        await change("currentPage", 1);
        handleSubmit();
        closeMoreFiltersModal();
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleOpen = async () => {
        const {openMoreFiltersModal} = this.props;
        openMoreFiltersModal();
    };


    handleReset = async () => {
        const {change, smallDevice, tabletDevice, setPersonalizedValues, fieldsSettingsData} = this.props;
        await Promise.all([
            change("houseRules", []),
            change("rooms", []),
            change("leaseType", []),
            change("paymentType", []),
            change("publicUtilities", []),
            change("publicUtilitiesType", []),
            change("layoutType", []),
            change("bathroomType", []),
            change("wallMaterialType", []),
            change("bookingType", ""),
            change("priceRange", null),
            change("priceRangeLabel", null),
            change("cancellationPolicies", []),
            change("equipment", []),
            change("houseType", []),
            change("floorsRange", []),
            change("livingAreaRange", []),
            change("totalAreaRange", []),
        ]);

        if (smallDevice || tabletDevice) {
            await Promise.all([
                change("roomType", []),
                change("priceRange", null),
                change("priceRangeLabel", null),
                change("bookingType", ""),
                change("dates", null)
            ]);
            setPersonalizedValues({name: 'startDate', value: null});
            setPersonalizedValues({name: 'endDate', value: null});
            setPersonalizedValues({name: 'isOneTotalToggle', value: false});
            setPersonalizedValues({name: 'totalPrice', value: false});
            if (fieldsSettingsData?.personCapacity?.length > 0 && fieldsSettingsData?.personCapacity[0]?.startValue) {
                change("personCapacity", fieldsSettingsData?.personCapacity[0]?.startValue);
            } else {
                change("personCapacity", 0);
            }
        }
    };

    renderFormControl = ({input, label, type, meta: {touched, error}, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl {...input} placeholder={label} type={type} className={className}/>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        )
    }

    async onAfterChange(e) {
        console.log(e)
    }

    async updateFloorsValue(values) {
        const {change} = this.props;

        await change('floorsRange')
    }


    renderRange = ({
                       input,
                       label,
                       meta: {touched, error},
                       className,
                       min,
                       max,
                       rangeCurrency,
                       minValue, maxValue
                   }) => {
        const {formatMessage} = this.props.intl;
        const {handleSubmit, change} = this.props;
        return (
            <div className={cx(s.space5, s.priceRangePadding)}>
                <Range
                    min={min}
                    max={max}
                    defaultValue={[min, max]}
                    value={[minValue, maxValue]}
                    onChange={(value) => {
                        input.onChange(value)
                    }}
                    keyboard={false}
                    marks={{0: minValue, [maxValue]: maxValue}}
                    step={0.1}
                />
            </div>
        );
    };

    renderFloorsRange() {

    }

    renderButtonGroup = ({label, name, options, input, className}) => {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex, s.space0)}>
                {options && options.map((option, index) => {
                    return (
                        <li className={cx('amenitiesCheckBox')} key={index}>
                            <div className={cx(s.checkBoxOutline, s.small, className)}>
                                <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                    {[s.checked]: input.value.indexOf(option.id) !== -1},
                                    {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                     onClick={() => {
                                         const newValue = [...input.value];
                                         if (newValue.indexOf(option.id) !== -1) {
                                             newValue.splice(newValue.indexOf(option.id), 1);
                                         } else {
                                             newValue.push(option.id);
                                         }
                                         input.onChange(newValue);

                                     }}>
                                    <div className={s.checkoutText}>{option.policyName}</div>
                                </div>
                            </div>
                        </li>
                    )
                })
                }
            </ul>
        )
    }




    render() {
        const {cancellationPolicies, closeSearchModal, className, formValues, searchSettings, smallDevice, tabletDevice} = this.props;
        const {tabs, smallDevices, verySmallDevices} = this.state;
        const {
            showSearchModal
        } = this.props;
        const {floorsRange, floorsMin, floorsMax} = this.props
        const {livingAreaRange, livingAreaMin, livingAreaMax} = this.props
        const {totalAreaRange, totalAreaMin, totalAreaMax} = this.props
        let minFloorsValue, maxFloorsValue, minLivingAreaValue, maxLivingAreaValue, minTotalAreaValue,
            maxTotalAreaValue;

        minFloorsValue = floorsRange && floorsRange[0] ? floorsRange[0] : floorsMin
        maxFloorsValue = floorsRange && floorsRange[1] ? floorsRange[1] : floorsMax
        minLivingAreaValue = livingAreaRange && livingAreaRange[0] ? livingAreaRange[0] : livingAreaMin
        maxLivingAreaValue = livingAreaRange && livingAreaRange[1] ? livingAreaRange[1] : livingAreaMax
        minTotalAreaValue = totalAreaRange && totalAreaRange[0] ? totalAreaRange[0] : totalAreaMin
        maxTotalAreaValue = totalAreaRange && totalAreaRange[1] ? totalAreaRange[1] : totalAreaMax

        return (
            <Modal
                show={showSearchModal}
                animation={false}
                onHide={() => closeSearchModal()}
                dialogClassName={cx(s.logInModalContainer, 'moreFilterModal', 'moreFilter')}
                backdrop="static"
            >
                <Modal.Header closeButton className={s.panelHeader} >
                    <Modal.Title>
                        <span><FormattedMessage {...messages.search} /></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body bsClass={s.logInModalBody}>
                    <div>
                        <SearchForm className={'isModal'}></SearchForm>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}


const mapState = (state) => ({
    showSearchModal: state?.modalStatus?.searchModal
});

const mapDispatch = {
    change,
    submitForm,
    openSearchModal,
    closeSearchModal,
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SearchModal)));
