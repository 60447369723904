import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {flowRight as compose} from 'lodash';

// Redux
import {connect} from 'react-redux';

// Translation
import {FormattedMessage, injectIntl} from 'react-intl';
import {submit as submitForm, change, formValueSelector, getFormValues} from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import {
    Button,
    FormControl,
} from 'react-bootstrap';
import {Field, reduxForm} from 'redux-form';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';

// Components
import DateRange from '../../Home/DateRange/DateRange';
import PlaceGeoSuggest from '../../Home/PlaceGeoSuggest/PlaceGeoSuggest';
import MobileDateRange from '../../Home/MobileDateRange/MobileDateRange';
import CurrencyConverter from '../../CurrencyConverter';
// Redux Action
import {getSpecificSettings} from '../../../actions/getSpecificSettings';
import {setPersonalizedValues} from '../../../actions/personalized';

// Helper
import history from '../../../core/history';
import detectMobileBrowsers from '../../../helpers/detectMobileBrowsers';
// Locale
import messages from '../../../locale/messages';

import bt from '../../commonStyle.css';
import s from './SearchFormV2.css';
import {changePersonalizedData} from '../../../actions/toggleControl';
import {setRoomsCount} from "../../../actions/roomsCount";
import submit from '../../SearchListing/SearchFormV2/submit'
import {openMoreFiltersModal} from "../../../actions/modalActions";
import filterIcon from '/public/SiteIcons/moreFilterIcon.svg';
import filterSubmit from '../../SearchListing/SearchForm/submit'

class SearchForm extends React.Component {
    static propTypes = {
        setPersonalizedValues: PropTypes.any.isRequired,
        getSpecificSettings: PropTypes.any.isRequired,
        personalized: PropTypes.shape({
            location: PropTypes.string,
            lat: PropTypes.number,
            lng: PropTypes.number,
            chosen: PropTypes.number,
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            personCapacity: PropTypes.number,
            formatMessage: PropTypes.any,
            isOneTotalToggle: PropTypes.bool,
        }),
        settingsData: PropTypes.shape({
            listSettings: PropTypes.array.isRequired
        }).isRequired
    };

    static defaultProps = {
        listingFields: []
    };

    static defaultProps = {
        personalized: {
            location: null,
            lat: null,
            lng: null,
            startDate: null,
            endDate: null,
            personCapacity: null,
            chosen: null
        },
        settingsData: {
            listSettings: []
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            mobileDevice: false,
            personCapacity: [],
            isLoad: false,
            smallDevice: false,
            verySmallDevice: false,
            showRooms: false,
            showPrices: false,
            isOneTotalToggle: false
        };
        this.roomsRef = createRef()
        this.pricesRef = createRef()
        this.handleClick = this.handleClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.setIsOpen = this.setIsOpen.bind(this);
        this.setIsOpenPrices = this.setIsOpenPrices.bind(this);
        this.updatePrices = this.updatePrices.bind(this);
        this.openFilterModal = this.openFilterModal.bind(this);
    }

    componentDidMount() {
        this.setState({isLoad: false});
        let isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listingFields, personalized} = nextProps;
        if (listingFields != undefined) {
            this.setState({
                personCapacity: listingFields.personCapacity
            });
        }
        if (personalized.isOneTotalToggle) this.setState({isOneTotalToggle: personalized.isOneTotalToggle})
    }

    UNSAFE_componentWillMount() {
        const {listingFields, personalized} = this.props;
        if (listingFields != undefined) {
            this.setState({
                personCapacity: listingFields.personCapacity
            });
        }
        if (detectMobileBrowsers.isMobile() === true) {
            this.setState({mobileDevice: true});
        }
        if (personalized.isOneTotalToggle) this.setState({isOneTotalToggle: personalized.isOneTotalToggle})
    }

    componentWillUnmount() {
        let isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize(e) {
        let isBrowser = typeof window !== 'undefined';
        let smallDevice = isBrowser ? window.matchMedia('(max-width: 767px)').matches : true;
        let verySmallDevice = isBrowser ? window.matchMedia('(max-width: 480px)').matches : false;
        this.setState({
            smallDevice,
            verySmallDevice
        });
    }

    openFilterModal() {
        const {openMoreFiltersModal} = this.props
        openMoreFiltersModal()
    }

    handleClick() {
        const {personalized, page, submitForm, setPersonalizedValues, change, dispatch} = this.props;
        let updatedURI, uri = '/s?';
        if (page != 'search' || (page == 'search' && (personalized?.chosen || personalized?.location))) {

            if (personalized?.chosen) {
                uri = uri + '&address=' + personalized?.location + '&chosen=' + personalized?.chosen;
            } else if (personalized?.location) {
                uri = uri + '&address=' + personalized?.location;
            }
            if (personalized?.startDate && personalized?.endDate) {
                setPersonalizedValues({name: 'isOneTotalToggle', value: true});
                change('SearchForm', 'isOneTotalToggle', true);
                uri = uri + '&startdate=' + personalized?.startDate + '&enddate=' + personalized?.endDate;
            }
            if (personalized?.prices) {
                if (personalized?.prices[0]) {
                    uri = uri + '&price_min=' + personalized?.prices[0];
                }
                if (personalized?.prices[1]) {
                    uri = uri + '&price_max=' + personalized?.prices[1];
                }
            }
            if (personalized?.rooms) {
                uri = uri + '&rooms=' + personalized?.rooms.join(',');
            }
            updatedURI = encodeURI(uri);
            history.push(updatedURI);
        } else {
            if (personalized?.startDate && personalized?.endDate) {
                setPersonalizedValues({name: 'isOneTotalToggle', value: true});
                change('SearchForm', 'isOneTotalToggle', true);
            }
            console.log(getFormValues('SearchForm'))
            filterSubmit(getFormValues('SearchForm'), dispatch);
        }
    }

    renderFormControl = ({input, label, type, meta: {touched, error}, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                <FormControl {...input} placeholder={label} type={type} className={className}/>
            </div>
        )
    }

    loadField = () => {
        const {formatMessage} = this.props.intl;
        return (
            <div className={cx('searchHeaderForm', s.divider, s.dividerLoading)}>
                <Field
                    component={this.renderFormControl}
                    label={formatMessage(messages.homeWhere)}
                    className={cx(s.formControlInput, s.input, s.loadfield)}
                    name="location"
                />
            </div>
        )
    }

    formValue = (e) => {
        const {setPersonalizedValues, changePersonalizedData} = this.props;
        changePersonalizedData('SearchForm', 'personCapacity', e);
        setPersonalizedValues({name: 'personCapacity', value: Number(e)})
    }

    setIsOpen(value) {
        const {showRooms} = this.state;
        const handleClickOutside = (event) => {
            if (this.roomsRef.current && !this.roomsRef.current.contains(event.target)) {
                this.setIsOpen(false)
                document.removeEventListener("mousedown", handleClickOutside);
            }
        };


        if (value) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        this.setState({showRooms: value})

    }

    setIsOpenPrices(value) {
        const handleClickOutside = (event) => {
            if (this.pricesRef.current && !this.pricesRef.current.contains(event.target)) {
                this.setIsOpenPrices(false)
                document.removeEventListener("mousedown", handleClickOutside);
            }

        };

        if (value) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        this.setState({showPrices: value})

    }

    renderPriceInput = ({
                            input, label, type, meta: {touched, error}, className,
                            prices,
                            showPrices,
                            setIsOpen,
                            updateValue
                        }) => {
        let value = 'Price'

        if (prices[0] && prices[1]) {
            value = prices[0] + ' - ' + prices[1]
        } else if (prices[0]) {
            value = 'from ' + prices[0]

        } else if (prices[1]) {
            value = 'to ' + prices[1]
        }
        return (
            <div ref={this.pricesRef} className={cx(s.roomsField)}>
                <button className={cx(s.roomsFieldToggle)}
                        onClick={() => setIsOpen(!showPrices)}>
                    <span className={cx(s.roomsFieldToggleLabel, 'textWhite')}>{value}</span> <span
                    className={cx(s.selectChevron)}>
                  <FontAwesome.FaChevronDown className={cx(s.iconStyle, 'textWhite')}/>

                                </span>
                </button>
                {showPrices && (
                    <div className={cx(s.roomsButtonsMenu, 'bgBlackTwo', 'borderNone')}>
                        <div
                            className={cx(s.roomsButtonsWrapper)}
                            style={{gridTemplateColumns: "repeat(2, 1fr)"}}
                        >
                            <FormControl
                                type={type}
                                className={className}
                                value={prices[0]}
                                placeholder="from"
                                onChange={(e) => {
                                    updateValue(e, 'minPrice')
                                }}
                            />
                            <FormControl
                                type={type}
                                className={className}
                                placeholder="to"
                                value={prices[1]}
                                onChange={(e) => {
                                    updateValue(e, 'maxPrice')
                                }}
                            />
                        </div>
                    </div>
                )}


            </div>
        )
    }

    renderRoomsField = ({input, options, setIsOpen, roomsCount, showRooms}) => {
        const {change} = this.state;

        const values = roomsCount || []
        let value = roomsCount && roomsCount.length > 0 ? options.filter(item => roomsCount.includes(item.id)).sort(item => !item.itemName).map(item => item.itemName).join(', ') : 'Rooms'

        const {setRoomsCount, changePersonalizedData, setPersonalizedValues} = this.props;
        let newValue

        function handleChange(e) {
            if (values.includes(e.id)) {
                newValue = values.filter(v => v !== e.id)
            } else {
                newValue = [...values, e.id]
            }
            changePersonalizedData('HeaderSearchForm', 'roomsCount', newValue);
            changePersonalizedData('SearchForm', 'rooms', newValue);
            setPersonalizedValues({name: 'rooms', value: newValue})
        }

        return (
            <div ref={this.roomsRef} className={cx(s.roomsField)}>
                <button className={cx(s.roomsFieldToggle)}
                        onClick={() => setIsOpen(!showRooms)}>
                    <span className={cx(s.roomsFieldToggleLabel, 'textWhite')}>{value}</span> <span
                    className={cx(s.selectChevron)}>
                  <FontAwesome.FaChevronDown className={cx(s.iconStyle, 'textWhite')}/>

                                </span>
                </button>
                {showRooms && (
                    <div
                        className={cx(s.roomsButtonsMenu, 'bgBlackTwo', 'borderNone')}
                    >
                        <div
                            className={cx(s.roomsButtonsWrapper)}
                        >
                            {options && options.map((option) => (
                                <button
                                    key={option.id}
                                    className={cx(s.roomsButton, {[s.active]: values.includes(option.id)}, 'bgBlackTwo', 'textWhite')}
                                    onClick={() => handleChange(option)}
                                >
                                    {option.itemName}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    updatePrices(e, key) {
        const {prices, changePersonalizedData, setPersonalizedValues} = this.props;
        const value = parseFloat(e.target.value);
        if (key === 'minPrice') {
            changePersonalizedData('HeaderSearchForm', 'prices', [value, prices[1]])
            changePersonalizedData('SearchForm', 'priceRange', [value, prices[1]])
            setPersonalizedValues({name: 'prices', value: [value, prices[1]]})
        } else {
            changePersonalizedData('HeaderSearchForm', 'prices', [prices[0], value])
            changePersonalizedData('SearchForm', 'priceRange', [prices[0], value])
            setPersonalizedValues({name: 'prices', value: [prices[0], value]})
        }
        e.target.value = value;
    }

    render() {
        const {
            setPersonalizedValues,
            viewListingHeader,
            guests,
            personalized,
            change,
            roomsCount,
            prices,
            isModal,
            page,
            listingFields
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {personCapacity, smallDevice, verySmallDevice, showRooms, showPrices} = this.state;
        let rows = [], guestCount = 1, rooms = [];
        const {minPrice, maxPrice} = this.state;
        const showFilterBtn = page === 'search'
        const options = listingFields?.rooms

        return (
            <div className={cx('searchHeaderForm', {[s.isModal]: isModal})}>
                <div className={cx(s.formWrapper)}>
                    <div className={cx(s.grid, 'searchHeaderPaddingRTL')}>
                        <div className={cx(s.location, 'tableCellRTL', s.divider)}>
                            <PlaceGeoSuggest
                                label={formatMessage(messages.homeWhere)}
                                className={cx(s.formControlInput, s.input)}
                                containerClassName={s.geoSuggestContainer}
                                loadField={this.loadField}
                            />
                        </div>
                        <div className={cx(s.guests, s.guestPadding, s.mobilePadding, 'tableCellLeftRTL')}>
                            <Field
                                name="roomsCount"
                                component={this.renderRoomsField}
                                options={options}
                                setIsOpen={this.setIsOpen}
                                showRooms={showRooms}
                                roomsCount={roomsCount}
                            />
                        </div>
                        <div className={cx(s.guests, s.guestPadding, s.mobilePadding, 'tableCellLeftRTL')}>
                            <Field
                                name="prices"
                                component={this.renderPriceInput}
                                className={cx(s.priceInput)}
                                showPrices={showPrices}
                                setIsOpen={this.setIsOpenPrices}
                                prices={prices}
                                updateValue={this.updatePrices}

                            />
                        </div>
                        <div className={cx(s.search, s.noBroderRight, 'layOut5SearchBtnBottom', 'noBroderRightRTL')}>
                            <Button className={cx(bt.btnPrimary, s.btnBlock, s.searchButton, 'layOut5SearchBtn')}
                                    onClick={this.handleClick}>
                                <span>
                                  <FontAwesome.FaSearch className={cx(s.iconStyle, 'textWhite')}/>
                                </span>
                            </Button>
                        </div>
                    </div>
                    {page === 'search' && <div
                        className={cx(s.searchFilterBtn, s.noBroderRight, 'layOut5SearchBtnBottom', 'noBroderRightRTL')}>
                        <Button className={cx(
                            "searchBtnDark",
                            s.filterBtn
                        )}
                                onClick={this.openFilterModal}>
                                <span className={cx(s.btnFlex, 'svgImg')}>
                                                          <img src={filterIcon}/>
                                </span>
                        </Button>
                    </div>}
                </div>
            </div>
        );
    }
}

SearchForm = reduxForm({
    form: 'HeaderSearchForm', // a unique name for this form
    destroyOnUnmount: false,
    onSubmit: submit,
    forceUnregisterOnUnmount: true,
})(SearchForm);
const selector = formValueSelector("HeaderSearchForm"); // <-- same as form name

const mapState = (state) => ({
    personalized: state.personalized,
    settingsData: state.viewListing.settingsData,
    listingFields: state.listingFields.data,
    isOneTotalToggle: state.personalized.isOneTotalToggle,
    roomsCount: selector(state, "roomsCount") || [],
    prices: selector(state, 'prices') || []
});

const mapDispatch = {
    getSpecificSettings,
    setPersonalizedValues,
    submitForm,
    change,
    changePersonalizedData,
    openMoreFiltersModal,
    setRoomsCount
};

export default compose(
    injectIntl,
    withStyles(s, bt),
    connect(mapState, mapDispatch),
)(SearchForm);
