import BaseForm from '../base/BaseForm';
import {reduxForm, Field, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import InputMask from "react-input-mask";
import PlaceMap from '../../PlaceMap';
import Loader from "../../Loader";

class Map extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],

        }
    }

    constructor(props) {
        super(props);
    }

    renderPlaceMap = ({input, label, meta: {touched, error}, lat, lng, isMapTouched, mapWarning, mapSuccess}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div className={'stpeOneMap'}>
                {touched && error && <span>{formatMessage(error)}</span>}
                <PlaceMap
                    {...input}
                    lat={lat}
                    lng={lng}
                    isMapTouched={isMapTouched}
                    mapWarning={mapWarning}
                    mapSuccess={mapSuccess}
                />
            </div>
        )
    }


    render() {
        const {
            error,
            handleSubmit,
            submitting,
            createNewListing,
            previousPage,
            nextPage,
            lat,
            lng,
            className
        } = this.props;
        const {locationMap, isMapTouched, formPage, step} = this.props;
        const {formatMessage} = this.props.intl;
        let isDisabled = true;
        if (isMapTouched === true || locationMap != undefined) {
            isDisabled = false;
        }


        return (
            <div >
                {error && <strong>{formatMessage(error)}</strong>}
                <FormGroup className={s.formGroup}>
                    {
                        !lat && !lng && <Loader type={"text"}/>
                    }
                    {
                        lat && lng &&
                        <Field
                            name="locationMap"
                            component={this.renderPlaceMap}
                            lat={lat}
                            lng={lng}
                            isMapTouched={isMapTouched}
                            mapWarning={formatMessage(messages.mapWarning)}
                            mapSuccess={formatMessage(messages.mapSuccess)}
                        />
                    }
                </FormGroup>
            </div>
        )
    }

}

Map = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Map)
const selector = formValueSelector('ListingNewForm'); // <-- same as form name

const mapState = (state) => {
    const locationMap = selector(state, 'locationMap');
    const isMapTouched = selector(state, 'isMapTouched');
    const lat = selector(state, 'lat');
    const lng = selector(state, 'lng');
    const street = selector(state, 'street');
    const district = selector(state, 'district');
    const _state = selector(state, 'state');
    return {
        locationMap,
        isMapTouched,
        lat,
        lng,
        street,
        district,
        state: _state
    }
};

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Map)));
