export function becomeHostStepInfo(formPage) {
    let step;
    const step1Pages = [
        "room",
        "bedrooms",
        "pricing",
        "tariff",
        "map",
    ];

    const step2Pages = ["photos", "cover-photo", "description", "title", "conveniences"];
    const step3Pages = [
        'house-rules',
        'reservation-rule',
        'cancellation-rules',
        'calendar',
        'guest-requirements',
        'local-laws',
    ];

    if (step1Pages.indexOf(formPage) > -1) {
        step = 1;
    } else if (step2Pages.indexOf(formPage) > -1) {
        step = 2;
    } else if (step3Pages.indexOf(formPage) > -1) {
        step = 3;
    }
    return step;
}
