import {GET_DISTRICT_SUCCESS, GET_REGIONS_SUCCESS} from '../constants';

export default function regions(state = {}, action) {
    switch (action.type) {
        case GET_DISTRICT_SUCCESS:
            return {
                ...state,
                districts: action.districts,
            };
        case GET_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.regions,
            };
        default:
            return state;
    }
}
