import React from "react";
import PropTypes from "prop-types";
import {graphql, gql, compose} from "react-apollo";

// Style
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Styles.css";
import "./Styles.css";

import history from "../../../core/history";

// Component
import {Table, Button} from "react-bootstrap";

import Loader from "../../../components/Loader";
import messages from "../../../locale/messages";
import {FormattedMessage, injectIntl} from "react-intl";
import {Td} from "reactable";
import bt from "../../../components/commonStyle.css";
import Confirm from "react-confirm-bootstrap";
import {removeTariff} from "../../../actions/siteadmin/Tariff/removeTariff";
import {connect} from "react-redux";

class Tariffs extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.shape({
            loading: PropTypes.bool,
            getAllTariffs: PropTypes.object,
        }),
    };

    static defaultProps = {
        data: {
            loading: true,
            getAllTariffs: [],
        },
    };

    deleteTariff = async (id, isEnable) => {
        const {removeTariff} = this.props;
        const {
            data: {refetch},
        } = this.props;

        let variables = {};
        await removeTariff(id, isEnable ? 0 : 1);
        await refetch(variables);
    };

    render() {
        const {
            data: {loading, getAllTariffs},
        } = this.props;
        const {formatMessage} = this.props.intl;
        const showEditModal = () => {
        }
        if (loading) {
            return <Loader type={"text"}/>;
        } else {
            return (
                <div className={cx(s.pagecontentWrapper, "pagecontentAR")}>
                    <div>
                        <div className={cx(s.dFlex, s.dJcr, s.mb3)}>
                            <Button
                                bsStyle="primary"
                                onClick={(e) => {
                                    history.push("/siteadmin/tariffs/add");
                                }}
                            >
                                Добавить
                            </Button>
                        </div>
                        <Table striped bordered condensed hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Название</th>
                                <th>Описание</th>
                                <th>Тип для хоста</th>
                                <th>Комиссия хоста</th>
                                <th>Тип комиссии</th>
                                <th>Тип для гостья</th>
                                <th>Комиссия гостья</th>
                                <th>Только комиссия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {getAllTariffs.map((x) => {
                                let commissionType = x.type === 'daily' ? formatMessage(messages.daily) : x.type === 'yearly' ? formatMessage(messages.yearly) : formatMessage(messages.monthly)

                                return (
                                    <tr key={"tItem-" + x.id}>
                                        <td>{x.id}</td>
                                        <td>{x.name}</td>
                                        <td>{x.description}</td>
                                        <td>
                                            {x.host_is_percent == 1 ? "Процент" : "Фиксированная сумма"}
                                        </td>
                                        <td>{x.host_commision}</td>
                                        <td>{commissionType}</td>
                                        <td>
                                            {x.guest_is_percent == 1 ? "Процент" : "Фиксированная сумма"}
                                        </td>
                                        <td>{x.guest_commision}</td>
                                        <td>{x.is_only_commission == 1 ? "Да" : "Нет"}</td>
                                        <td>
                                            <Button className={cx(bt.btnPrimaryBorder, 'textCenterAdmin', 'bgBlack')}
                                                    onClick={(e) => {
                                                        history.push(`/siteadmin/tariffs/${x.id}`);
                                                    }}>
                                                <FormattedMessage {...messages.manageLabel} />
                                            </Button>
                                        </td>
                                        <td>
                                            {!x.isEnable ? <Button
                                                    className={cx(bt.btnPrimaryBorder, 'textCenterAdmin', 'bgBlack')}
                                                    onClick={(e) => {
                                                        this.deleteTariff(x.id, x.isEnable);
                                                    }}>
                                                    <FormattedMessage {...messages.enableLabel} />
                                                </Button> :
                                                <Confirm
                                                    onConfirm={() =>
                                                        this.deleteTariff(x.id, x.isEnable)
                                                    }
                                                    body={formatMessage(
                                                        messages.areYouSureDeleteWishList
                                                    )}
                                                    confirmText={formatMessage(messages.confirmDelete)}
                                                    cancelText={formatMessage(messages.cancel)}
                                                    title={formatMessage(messages.deletingTariffTitle)}
                                                >
                                                    <Button href="javascript:void(0)"
                                                            className={cx(bt.btnPrimaryBorder, 'textCenterAdmin', 'bgBlack')}
                                                    >
                                                        <FormattedMessage {...messages.disableLabel} />
                                                    </Button>
                                                </Confirm>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            );
        }
    }
}

const mapState = () => ({})
const mapDispatch = {
    removeTariff
}
export default compose(
    injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(
        gql`
      {
        getAllTariffs {
          id
          name
          description
          host_commision
          guest_commision
          host_is_percent
          guest_is_percent
          is_only_commission
          advertising
          customer_support
          risk_insurance
          apartment_tour
          checkIn_tenants
          premium_sections
          search_priority
          service_guarantee
          isEnable
          type
        }
      }
    `,
        {
            options: {
                fetchPolicy: "network-only",
            },
        }
    )
)(Tariffs);
