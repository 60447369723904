import {gql} from 'react-apollo';
import {
    SITE_ADMIN_REMOVE_TARIFF_START,
    SITE_ADMIN_REMOVE_TARIFF_ERROR,
    SITE_ADMIN_REMOVE_TARIFF_SUCCESS
} from '../../../constants';
import showToaster from '../../../helpers/showToaster';

export function removeTariff(tariffId, isEnable) {

    return async (dispatch, getState, {client}) => {

        dispatch({
            type: SITE_ADMIN_REMOVE_TARIFF_START,
        });

        try {
            const mutation = gql`
            mutation adminRemoveTariff($tariffId:Int!, $isEnable: Int!) {
              adminRemoveTariff (id:$tariffId, isEnable: $isEnable) {
                id
              }
            }
          `;
            // Send Request to get listing data
            const {data} = await client.mutate({
                mutation,
                variables: {tariffId, isEnable},
            });

            if (data && data.adminRemoveListing) {
                dispatch({
                    type: SITE_ADMIN_REMOVE_TARIFF_SUCCESS,
                });

            } else {
                dispatch({
                    type: SITE_ADMIN_REMOVE_TARIFF_ERROR,
                });
            }
        } catch (error) {
            dispatch({
                type: SITE_ADMIN_REMOVE_TARIFF_ERROR,
                payload: {
                    error
                }
            });
            return false;
        }

        return true;
    };
}
