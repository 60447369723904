import BaseForm from '../base/BaseForm';
import {reduxForm, Field} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import InputMask from "react-input-mask";

class Description extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],

        }
    }

    render() {
        const {formatMessage} = this.props.intl;

        return (
            <div className={cx(s.listingForm, 'bgBlackTwo')}>
                <Row>
                    <Col xs={12}>
                        <h3 className={cx(s.sidePanelTitle, s.space6)}>
                            <FormattedMessage {...messages.description1} />
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.description} />
                            </ControlLabel>
                            <Field name="description"
                                   component={this.renderFormControlTextArea}
                                   className={s.textareaInput}
                                   label={formatMessage(messages.descriptionLabel)}
                            />
                        </FormGroup>

                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.kuulaLink} />
                            </ControlLabel>
                            <Field name="kuulaLink"
                                   type="text"
                                   component={this.renderFormControl}
                                   label={formatMessage(messages.kuulaLink)}
                                   style={{width: "100%"}}
                                   className={cx(s.formControlInput, s.jumboInput)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>

        )
    }

}

Description = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Description)

const mapState = (state) => ({
    listingFields: state.listingFields.data
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Description)));
