import React from 'react';

import NewListLayout from '../../components/Layout/NewListLayout';
import ListLayout from '../../components/Layout/ListLayout';
import BecomeHost from './BecomeHost';
import NotFound from '../notFound/NotFound';
import ListingNew from "./ListingNew";
import {checkListing} from '../../actions/Listing/checkListing';
import {getSideMenu} from '../../actions/siteadmin/getSideMenu';

import {getListingSteps, resetListingSteps} from '../../actions/NewListing/getListingSteps';
import {getListingFields} from '../../actions/NewListing/getListingFields';
import {getListingStepTwo} from '../../actions/Listing/getListingStepTwo';

import {restrictUrls} from '../../helpers/adminPrivileges';
import {becomeHostStepInfo} from '../../helpers/BecomeAHost/becomeAHostHeader';

import messages from '../../locale/messages';
import {getAllTariffs} from '../../actions/getAllTariffs';
import {setListingType} from "../../actions/listing";
import {getRegions} from "../../actions/getRegions";
import {getDistricts} from "../../actions/getDistricts";

export default async function action({params, store, intl, query}) {

    const title = intl?.formatMessage(messages.becomeAHost);
    const isAuthenticated = store.getState().runtime.isAuthenticated;
    const isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    const listingFields = store.getState().listingFields.data;
    const listingSteps = store.getState().location.listingSteps;
    const initialValuesLoaded = store.getState().location.initialValuesLoaded;
    const baseCurrency = store.getState().currency.base;
    const listId = params.listId;
    const formPage = params.formPage;
    const formBaseURI = "/become-a-host/";

    let adminPrivileges, mode;
    adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

    await store.dispatch(getSideMenu());
    await store.dispatch(getAllTariffs());
    await store.dispatch(getRegions());
    await store.dispatch(getDistricts());

    if ("mode" in query) {
        if (query.mode === "new") {
            mode = query.mode;
        }
    }

    if (!isAuthenticated && !isAdminAuthenticated) {
        return {redirect: '/login'};
    }

    // Admin restriction
    if (isAdminAuthenticated && !restrictUrls('/become-a-host/', adminPrivileges)) {
        return {redirect: '/siteadmin'};
    }

    // Fetch all settings fields
    if (listingFields === undefined) {
        store.dispatch(getListingFields());
    }

    if (listId != undefined && !isNaN(listId)) {
        var response;
        //checkListing
        await store.dispatch(checkListing(listId, 'listing')).then(function (res) {
            response = isAdminAuthenticated ? true : res;
        });

        if (response === true) {
            // Fetch All steps status
            if (listingSteps === undefined) {
                await store.dispatch(getListingSteps(listId));
            } else {
                // Fetch All steps status for another list
                await store.dispatch(getListingSteps(listId));
            }
            store.dispatch(getListingStepTwo(listId));
        } else {
            return {
                title,
                component: <NewListLayout><NotFound/></NewListLayout>
            }
        }
    } else {
        if (initialValuesLoaded != true || (mode && mode == 'new')) {
            await store.dispatch(resetListingSteps());
            await store.dispatch(getListingSteps());
        }
    }

    if (listId && !isNaN(listId)) {
        let step = becomeHostStepInfo(formPage);
        if (step) {
            return {
                title,
                component: <ListLayout
                    listId={Number(listId)}
                    formPage={formPage}
                    formBaseURI={formBaseURI}
                    step={step}
                >
                    <ListingNew
                        listId={Number(listId)}
                        title={title}
                        formPage={formPage}
                        formBaseURI={formBaseURI}
                        isLoaded={isLoaded}
                        mode={mode}
                        baseCurrency={baseCurrency}
                    />
                </ListLayout>,
            };
        }
    }

    return {
        title,
        component: <NewListLayout>
            <ListingNew
                listId={Number(listId)}
                title={title}
                formPage={formPage}
                formBaseURI={formBaseURI}
                mode={mode}
                baseCurrency={baseCurrency}
            />
        </NewListLayout>
    };
}
