// General
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Redux Form
import {Field, reduxForm, formValueSelector, FieldArray, change} from 'redux-form';
// Redux
import {connect} from 'react-redux';
// Translation
import {injectIntl, FormattedMessage} from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    FormGroup,
    ControlLabel,
    FormControl, Row, Col, Nav, NavItem, Tab
} from 'react-bootstrap';

// Internal Component
import IncrementButton from '../IncrementButton';
import IncrementBtn from './IncrementBtn';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

// Style
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

import updateStep2 from './updateStep2';
import messages from '../../locale/messages';
import validate from "./validate";

class Conveniences extends Component {

    static propTypes = {
        initialValues: PropTypes.object,
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        isExistingList: PropTypes.bool,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
    };

    static defaultProps = {
        nextPagePath: 'description',
        previousPagePath: 'photos',
    }


    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
        }
        this.handleClick = this.handleClick.bind(this);
        this.renderAdditionalFields = this.renderAdditionalFields.bind(this);
    }


    componentDidMount() {
        const {valid, leaseTypeValue} = this.props;

        // Set initial filter based on first lease type if no value is selected
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listingFields, valid} = nextProps;
        if (valid) {
            this.setState({isDisabled: false});
        } else {
            this.setState({isDisabled: true});
        }
    }


    renderIncrementButton = (field) => (
        <IncrementButton
            {...field}
        />
    );

    renderIncrementBtn = (input, dispatch) => (
        <IncrementBtn
            {...input}
            onChange={this.handleClick}
        />
    );
    checkboxGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable === "1") {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: input.value.indexOf(option.id) !== -1},
                                        {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                         onClick={() => {
                                             const newValue = [...input.value];
                                             if (newValue.indexOf(option.id) !== -1) {
                                                 newValue.splice(newValue.indexOf(option.id), 1);
                                             } else {
                                                 newValue.push(option.id);
                                             }
                                             input.onChange(newValue);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    renderSelectField = ({input, label, meta: {touched, error}, children}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <select
                    {...input}
                >
                    {children}
                </select>
                {touched && error && <span>{formatMessage(error)}</span>}
            </div>
        )
    }

    renderFormControlSelect = ({input, label, meta: {touched, error}, children, className}) => {
        return (
            <div>
                <FormControl componentClass="select" {...input} className={className}>
                    {children}
                </FormControl>
            </div>
        )
    }

    renderFormControl = ({input, label, type, meta: {touched, error}, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl {...input} placeholder={label} type={type} className={className}/>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        )
    }

    async handleClick(e) {
        const {beds, bedTypes, change} = this.props;
        const {bedType} = this.state;

        let bedTypesValue = Object.keys(bedTypes).map(function (key) {
            return bedTypes[key];
        });

        let updatedBedTypesArray = (bedTypesValue && bedTypesValue.length > 0) ? bedTypesValue.slice(0) : [];
        let updatedBedCount = beds + 1;
        let obj = {};

        if (Number(beds) < Number(e)) {
            obj = {
                bedCount: updatedBedCount,
                bedType: bedType.length > 0 ? bedType[0].id : null,
            };
            try {
                updatedBedTypesArray.push(obj);
            } catch (e) {

            }
        } else if ((Number(beds) >= Number(e)) || (Number(e) > 2)) {
            updatedBedTypesArray.splice(bedTypesValue.length - 1, 1);
        }

        await change('bedTypes', []);
        await change('bedTypes', updatedBedTypesArray);
    }

    radioGroup({label, name, options, input}) {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex)}>
                {options.map((option, index) => {
                    if (option.isEnable) {
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, s.small)}>
                                    <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                        {[s.checked]: parseInt(input.value) === option.id},
                                        {["darkModeAmenitiesChecked"]: parseInt(input.value) === option.id})}
                                         onClick={() => {
                                             input.onChange(option.id);

                                         }}>
                                        < div className={s.checkoutText}>{option.itemName}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    renderAdditionalField({input, name}) {
        const {formatMessage} = this.props.intl;
        return (
            <Row>
                <Col xs={12} sm={6} md={4} lg={4}>
                    <Field
                        name={name}
                        component={this.renderFormControl}
                        type="text"
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.brand)}
                    />
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                    <Field
                        name={name + 'brand'}
                        component={this.renderFormControl}
                        type="text"
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.model)}
                    />
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                    <Field
                        name={name + 'year'}
                        component={this.renderFormControl}
                        type="number"
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.manufactureYear)}
                    />
                </Col>
            </Row>
        )
    }

    renderInternetFields({input, name}) {
        const {formatMessage} = this.props.intl;

        return (
            <Row>
                <Col xs={12} sm={6} md={4} lg={4}>
                    <Field
                        name={name}
                        component={this.renderFormControl}
                        type="text"
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.brand)}
                    />
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                    <Field
                        name={name + 'speed'}
                        component={this.renderFormControl}
                        type="number"
                        className={cx(s.formControlInput, s.jumboInput)}
                        label={formatMessage(messages.speed)}
                    />
                </Col>
                <Col xs={12}>
                    <Field
                        name={name + 'limit'}
                        options={[
                            {id: 1, isEnable: 1, itemName: 'Unlimited', value: 'Unlimited'},
                            {id: 2, isEnable: 1, itemName: 'limited', value: 'Limited'}
                        ]}
                        component={this.radioGroup}
                        className={cx(s.formControlInput, s.jumboInput)}
                    />
                </Col>
            </Row>
        )
    }

    renderAdditionalFields() {
        const options =     [
            {
                name: 'cooker',
                label: 'Cooker',
            },
            {
                name: 'dishwasher',
                label: 'Dishwasher',
            },
            {
                name: 'microwaveOven',
                label: 'Microwave Oven',
            },
            {
                name: 'tv',
                label: 'TV',
            },
            {
                name: 'washingMachine',
                label: 'Washing Machine',
            },
            {
                name: 'refrigerator',
                label: 'Refrigerator',
            },
            {
                name: 'airConditioner',
                label: 'Air Conditioner',
            }
        ]
        return (
            <div className={s.spaceTop5}>
                {options.map((option, index) => {
                    return (
                        <FormGroup className={s.space5} key={index}>
                            <ControlLabel style={{textTransform: 'capitalize'}}
                                          className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage  {...messages['deviceType.' + option.name]} />
                            </ControlLabel>
                            {this.renderAdditionalField({name: option.name})}
                        </FormGroup>
                    )
                })}
                <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                        <FormattedMessage {...messages['deviceType.internet']} />
                    </ControlLabel>
                    {this.renderInternetFields({name: 'internet'})}
                </FormGroup>
            </div>
        )
    }

    renderSecurityFields() {
        const {formatMessage,} = this.props.intl;
        const {listingFields: {security}} = this.props


        return (
            <FormGroup className={s.space5}>
                <Field
                    name="security"
                    component={this.checkboxGroup}
                    className={cx(s.formControlInput, s.jumboInput)}
                    label={formatMessage(messages.security)}
                    options={security}
                ></Field>
            </FormGroup>
        )
    }

    renderParkingFields() {
        const {formatMessage} = this.props.intl;
        const {listingFields: {parking}} = this.props


        return (
            <FormGroup className={s.space5}>
                <Field
                    name="parking"
                    component={this.checkboxGroup}
                    className={cx(s.formControlInput, s.jumboInput)}
                    label={formatMessage(messages.parking)}
                    options={parking}
                ></Field>
            </FormGroup>
        )
    }

    renderLandscapingFields() {
        const {formatMessage} = this.props.intl;
        const {listingFields: {landscaping}} = this.props


        return (
            <FormGroup className={s.space5}>
                <Field
                    name="landscaping"
                    component={this.checkboxGroup}
                    className={cx(s.formControlInput, s.jumboInput)}
                    label={formatMessage(messages.parking)}
                    options={landscaping}
                ></Field>
            </FormGroup>
        )
    }

    renderInfrastructureFields() {
        const {formatMessage} = this.props.intl;
        const {listingFields: {infrastructure}} = this.props


        return (
            <FormGroup className={s.space5}>
                <Field
                    name="infrastructure"
                    component={this.checkboxGroup}
                    className={cx(s.formControlInput, s.jumboInput)}
                    label={formatMessage(messages.parking)}
                    options={infrastructure}
                ></Field>
            </FormGroup>
        )
    }


    render() {
        const {
            handleSubmit,
            previousPage,
            nextPage,
            formPage,
            step,
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {isDisabled} = this.state;

        return (
            <div className={s.grid}>
                <SidePanel
                    title={formatMessage(messages.stepTwoCommonHeading)}
                    landingContent={formatMessage(messages.conveniencesLabel)}
                />
                <div className={cx(s.landingMainContent)}>
                    <form onSubmit={handleSubmit}>
                        <Tab.Container id="tabs-with-dropdown" defaultActiveKey="first" className={'amenitiesTab'}>
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav bsStyle="tabs">
                                        <NavItem eventKey="first"><FormattedMessage {...messages.equipment} /></NavItem>
                                        <NavItem eventKey="second"><FormattedMessage {...messages.security} /></NavItem>
                                        <NavItem
                                            eventKey="third"><FormattedMessage {...messages.landscaping} /></NavItem>
                                        <NavItem
                                            eventKey="fourth"><FormattedMessage {...messages.infrastructure} /></NavItem>
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <Tab.Content animation>
                                        <Tab.Pane eventKey="first">
                                            {this.renderAdditionalFields()}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            {this.renderSecurityFields()}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            {this.renderLandscapingFields()}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fourth">
                                            {this.renderInfrastructureFields()}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <FooterButton
                            isDisabled={isDisabled}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            nextPagePath={this.props.nextPagePath}
                            previousPagePath={this.props.previousPagePath}
                            formPage={formPage}
                            step={step}
                        />
                    </form>

                </div>
            </div>
        )
    }
}


Conveniences = reduxForm({
    form: 'ListPlaceStep2', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: updateStep2
})(Conveniences);

// Decorate with connect to read form values

const mapState = (state) => ({
    isExistingList: state.location.isExistingList,
    listingFields: state.listingFields.data,
    _listingType: state.listing.listingType
});

const mapDispatch = {
    change,
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Conveniences)));
