import BaseForm from '../base/BaseForm';
import {reduxForm, Field, Fields, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import InputMask from "react-input-mask";
import {url} from "../../../config";
import DefaultIcon from "../../../../public/SiteIcons/defaultIcon.png";
import * as FontAwesome from "react-icons/lib/fa";

class Conveniences extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],

        }
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedEquipments: []
        }
        this.onChangeEquipment = this.onChangeEquipment.bind(this);
        this.renderEquipmentInputs = this.renderEquipmentInputs.bind(this);
    }


    componentDidMount() {
        const {equipments} = this.props;

        if (equipments != undefined) {
            this.onChangeEquipment(equipments)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {equipments} = nextProps;

        if (equipments != undefined) {
            this.onChangeEquipment(equipments)
        }
    }

    _renderButtonGroup = ({label, name, options, input, className, handleChange}) => {
        const {selectedEquipments} = this.state
        const {formatMessage} = this.props.intl
        let showFormEdit = false
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex, s.space0)}>
                {options && options.map((option, index) => {
                    if (option.isEnable == "1") {
                        const isSelected = (selectedEquipments?.findIndex(item => item.id === option.id) >= 0)
                        console.log(isSelected, selectedEquipments, selectedEquipments?.findIndex(item => item.id === option.id), 'isSelected')
                        return (
                            <li className={cx('amenitiesCheckBox', s.flex)} key={index}>
                                <div className={cx(s.checkBoxOutline, className)}>
                                    <div
                                        className={cx(s.amenitiesCheckBoxWrapper, s.equipment, "darkModeAmenitiesCheckBoxWrapper",
                                            {[s.checked]: input.value.indexOf(option.id) !== -1},
                                            {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                        onClick={(e) => {
                                            console.log(e.target);
                                            const newValue = [...input.value];
                                            if (newValue.indexOf(option.id) !== -1) {
                                                newValue.splice(newValue.indexOf(option.id), 1);
                                            } else {
                                                newValue.push(option.id);
                                            }
                                            if (handleChange) {
                                                handleChange(newValue)
                                            }
                                            input.onChange(newValue);

                                        }}>
                                        <div className={s._flex}>
                                            {option.image ? (
                                                <img src={url + '/images/amenities/' + option.image}
                                                     className={cx(s.imgSection, 'imgSectionRtl', 'darkModeFilterNone')}/>
                                            ) : (
                                                <img src={DefaultIcon} className={cx(s.imgSection, 'imgSectionRtl')}/>
                                            )}
                                            <div className={s.checkoutText}>{option.itemName}</div>
                                        </div>
                                        {isSelected && <button className={cx(s.editButton)} onClick={() => showFormEdit = !showFormEdit}>
                                            <FontAwesome.FaEdit className={cx(s.iconStyle, 'textWhite')}/>
                                        </button>}
                                        {showFormEdit && <div key={option.id} className={cx(s.editForm, s.spaceTop4)}>
                                            <Row>
                                                <Col xs={12} md={4}>
                                                    <Field
                                                        name={`equipmentValues.${option.itemName}|${option.id}.brand`}
                                                        component={this.renderFormControl}
                                                        label={formatMessage(messages.brand)}
                                                        className={cx(s.formControlInput, s.jumboInput)}

                                                    >
                                                    </Field>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Field
                                                        name={`equipmentValues.${option.itemName}|${option.id}.model`}
                                                        component={this.renderFormControl}
                                                        label={formatMessage(messages.model)}
                                                        className={cx(s.formControlInput, s.jumboInput)}

                                                    >
                                                    </Field>

                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Field
                                                        name={`equipmentValues.${option.itemName}|${option.id}.year`}
                                                        type={'number'}
                                                        component={this.renderFormControl}
                                                        label={formatMessage(messages.manufactureYear)}
                                                        className={cx(s.formControlInput, s.jumboInput)}

                                                    >
                                                    </Field>
                                                </Col>


                                            </Row>
                                        </div>}
                                    </div>
                                </div>
                            </li>
                        )
                    }
                })
                }
            </ul>
        )
    }

    onChangeEquipment(values) {
        const {listingFields: {equipment}} = this.props;
        const selectedEquipments = values.map((value) => {
            const equipmentItem = equipment.filter((item) => item.id === value);
            return equipmentItem[0];
        });

        this.setState({
            selectedEquipments
        })
    }

    renderEquipmentInputs() {
        const {selectedEquipments} = this.state;
        const {formatMessage} = this.props.intl;

        return (
            selectedEquipments.map(item => {
                return (
                    <div key={item.id} className={cx(s.spaceTop4)}>
                        <Row>
                            <Col xs={12}>
                                <ControlLabel style={{textTransform: 'capitalize'}}
                                              className={cx(s.landingLabel, 'textWhite')}>
                                    <span>{item.itemName}</span>
                                </ControlLabel>
                            </Col>
                            <Col xs={12} md={4}>
                                <Field
                                    name={`equipmentValues.${item.itemName}|${item.id}.brand`}
                                    component={this.renderFormControl}
                                    label={formatMessage(messages.brand)}
                                    className={cx(s.formControlInput, s.jumboInput)}

                                >
                                </Field>
                            </Col>
                            <Col xs={12} md={4}>
                                <Field
                                    name={`equipmentValues.${item.itemName}|${item.id}.model`}
                                    component={this.renderFormControl}
                                    label={formatMessage(messages.model)}
                                    className={cx(s.formControlInput, s.jumboInput)}

                                >
                                </Field>

                            </Col>
                            <Col xs={12} md={4}>
                                <Field
                                    name={`equipmentValues.${item.itemName}|${item.id}.year`}
                                    type={'number'}
                                    component={this.renderFormControl}
                                    label={formatMessage(messages.manufactureYear)}
                                    className={cx(s.formControlInput, s.jumboInput)}

                                >
                                </Field>
                            </Col>


                        </Row>
                    </div>
                )
            })
        )
    }

    render() {
        const {
            listingFields: {
                security, landscaping, parking, infrastructure, equipment

            }, handleSubmit
        } = this.props;
        const {formatMessage} = this.props.intl;
        return (<div className={cx(s.listingForm, 'bgBlackTwo')}>
            <Row>
                <Col xs={12}>
                    <h3 className={cx(s.sidePanelTitle, s.space6)}>
                        <FormattedMessage {...messages.conveniencesLabel} />

                    </h3>
                </Col>
                <Col xs={12}>
                    <FormGroup className={cx(s.space5)}>
                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                            <FormattedMessage {...messages.equipment} />
                        </ControlLabel>
                        <Field
                            name="equipment"
                            component={this._renderButtonGroup}
                            options={equipment}
                            handleChange={this.onChangeEquipment}>
                        </Field>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <div className={cx(s.space6,)}>
                        {this.renderEquipmentInputs()}

                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                            <FormattedMessage {...messages.security} />
                        </ControlLabel>
                        <Field
                            name="security"
                            component={this.renderButtonGroup}
                            options={security}
                            className={cx(s.small, s.maxContent)}>
                        </Field>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                            <FormattedMessage {...messages.landscaping} />
                        </ControlLabel>
                        <Field
                            name="landscaping"
                            component={this.renderButtonGroup}
                            options={landscaping}
                            className={cx(s.small, s.maxContent)}>
                        </Field>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                            <FormattedMessage {...messages.parking} />
                        </ControlLabel>
                        <Field
                            name="parking"
                            component={this.renderRadioGroup}
                            options={parking}
                            className={cx(s.small, s.maxContent)}>
                        </Field>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                        <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                            <FormattedMessage {...messages.infrastructure} />
                        </ControlLabel>
                        <Field
                            name="infrastructure"
                            component={this.renderButtonGroup}
                            options={infrastructure}
                            className={cx(s.small, s.maxContent)}>
                        </Field>
                    </FormGroup>
                </Col>
            </Row>
        </div>)
    }

}

Conveniences = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Conveniences)
const selector = formValueSelector('ListingNewForm')
const mapState = (state) => ({
    listingFields: state.listingFields.data,
    equipments: selector(state, 'equipment') || [],
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Conveniences)));
