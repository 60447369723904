import Link from '../Link'
import SearchIconDark from '/public/SiteIcons/searchIconHomeDark.svg';
import WishlistIcon from '/public/SiteIcons/wishlist.svg'
import PersonIcon from '/public/SiteIcons/person.svg'
import messagesIcon from '/public/SiteIcons/messages.svg'
import React from 'react'
import {flowRight as compose} from "lodash";
import {FormattedMessage, injectIntl} from "react-intl";
import history from "../../core/history";
import cx from "classnames";
import messages from "../../locale/messages";
import s from './MobileMenuAfterLogin.css'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {toggleClose, toggleOpen} from "../../actions/Menu/toggleControl";
import {connect} from "react-redux";

class MobileMenuAfterLogin extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {location} = this.props;
        return (
            <div className={cx(s.mobileNav, 'bgBlack',)}>
                <div className={cx(s.mobileNavContainer)}>
                    <Link
                        to="/"
                        className={cx(s.mobileNavItem, 'textWhite', (location === "/" ? 'mobile-nav__item--active' : ''))}
                    >
                        <svg className={cx(s.mobileNavIcon, 'svgWhite')} id="_x32_-Magnifying_Glass"
                             xmlns="http://www.w3.org/2000/svg" width="14.232" height="14.235"
                             viewBox="0 0 14.232 14.235"
                             fill="none">
                            <path id="Path_22044" data-name="Path 22044"
                                  d="M21.114,19.921l-2.736-2.736a6.283,6.283,0,1,0-1.193,1.193l2.736,2.736a.844.844,0,0,0,1.193-1.193ZM10.155,16.633a4.581,4.581,0,1,1,3.239,1.342,4.587,4.587,0,0,1-3.239-1.342Z"
                                  transform="translate(-7.129 -7.125)"/>
                        </svg>
                        <span className={cx(s.mobileNavText)}>
                            <FormattedMessage {...messages.search} />
                            </span>
                    </Link>
                    <Link
                        to="/wishlists"
                        className={cx(s.mobileNavItem, 'textWhite', (location === "/wishlists" ? 'mobile-nav__item--active' : ''))}
                    >
                        <svg className={cx(s.mobileNavIcon, 'svgWhite')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor"
                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path
                                d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" fill="none"></path>
                        </svg>


                        <span className={cx(s.mobileNavText)}>
                            <FormattedMessage {...messages.wishLists} />
                            </span>
                    </Link>
                    <Link
                        to="/inbox"
                        className={cx(s.mobileNavItem, 'textWhite', (location === "/inbox" ? 'mobile-nav__item--active' : ''))}
                    >
                        <svg className={cx(s.mobileNavIcon, 'svgWhite')} xmlns="http://www.w3.org/2000/svg"
                             height="24px" viewBox="0 0 24 24" width="24px"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M20 17.17L18.83 16H4V4h16v13.17zM20 2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4V4c0-1.1-.9-2-2-2z"

                            />
                        </svg>

                        <span className={cx(s.mobileNavText)}>
                            <FormattedMessage {...messages.reviews} />
                            </span>
                    </Link>
                    <Link
                        to="/dashboard"
                        className={cx(s.mobileNavItem, 'textWhite', (location === "/dashboard" ? 'mobile-nav__item--active' : ''))}
                    >
                        <svg className={cx(s.mobileNavIcon, 'svgWhite')} xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" fill="none"></path>
                            <circle cx="12" cy="7" r="4" fill="none"></circle>
                        </svg>

                        <span className={cx(s.mobileNavText)}>
                            <FormattedMessage {...messages.profile} />
                            </span>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        showMenu: state.toggle.showMenu,
        account: state.account.data
    }
};

const mapDispatch = {
    toggleOpen,
    toggleClose
};
export default compose(
    injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
)(MobileMenuAfterLogin);
