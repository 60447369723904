// Redux Form
import {change} from 'redux-form';

// Fetch request
import fetch from '../../../../core/fetch';

// Locale
import messages from '../../../../locale/messages';

// For Redirect
import history from '../../../../core/history';
import showToaster from "../../../../helpers/showToaster";
import {setLoaderComplete, setLoaderStart} from "../../../../actions/loader/loader";
import {gql} from "react-apollo";
const checkMapQuery = gql`
  query ($tariff_id: Int) {
    getTariffById(tariff_id: $tariff_id) {
      id
      name
      description
      host_commision
      guest_commision
      host_is_percent
      guest_is_percent
      is_only_commission
      advertising
      customer_support
      risk_insurance
      apartment_tour
      checkIn_tenants
      premium_sections
      search_priority
      service_guarantee
      type
    }
  }
`;
async function getTariffById(tariff_id) {

    const res = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: checkMapQuery,
            variables: {
                tariff_id: Number(tariff_id)
            }
        }),
        credentials: 'include'
    });

    const response = await res.json();
    console.log(response, 'restest')

    if (!response && !response.data && !response.data.getTariffById) {
        return Promise.reject(false);
    } else {
        return Promise.resolve(response.data.getTariffById)
    }
}

export default getTariffById;
