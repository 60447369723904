import React from "react";
import PropTypes from "prop-types";
import {graphql, gql, compose} from "react-apollo";

import fetch from "../../../../core/fetch";

// Style
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../Styles.css";
import "../Styles.css";

// Component

import Loader from "../../../../components/Loader";

import history from "../../../../core/history";
import BaseForm from "../../../../components/NewListing/base/BaseForm";
import {Field, initialize, reduxForm} from "redux-form";
import {ControlLabel, FormGroup} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import messages from "../../../../locale/messages";

class Tariffs extends BaseForm {
    constructor() {
        super();
        this.values = {
            name: "",
            description: "",
            host_commision: "",
            guest_commision: "",
            host_is_percent: 1,
            guest_is_percent: 1,
            is_only_commission: 0,
            advertising: 0,
            customer_support: 0,
            risk_insurance: 0,
            apartment_tour: 0,
            checkIn_tenants: 0,
            premium_sections: 0,
            search_priority: 0,
            service_guarantee: 0,
            type: 'monthly'
        };
    }

    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.shape({
            loading: PropTypes.bool,
            getAllTariffs: PropTypes.object,
        }),
    };

    static defaultProps = {
        data: {
            loading: true,
            getAllTariffs: [],
        },
    };

    async onSubmit(e) {
        const query = `
    query (
        $id:Int,
        $name:String,
        $description:String,
        $host_commision:Int,
        $guest_commision:Int,
        $host_is_percent:Int,
        $guest_is_percent:Int,
        $is_only_commission:Int,
        $advertising:Int,
        $customer_support:Int,
        $risk_insurance:Int,
        $apartment_tour:Int,
        $checkIn_tenants:Int,
        $premium_sections:Int,
        $search_priority:Int,
        $service_guarantee:Int,
        $type:String,
        
      ) {
          addTariff (
            id: $id,
            name:$name,
            description:$description,
            host_commision:$host_commision,
            guest_commision:$guest_commision,
            host_is_percent:$host_is_percent,
            guest_is_percent:$guest_is_percent,
            is_only_commission:$is_only_commission,
            advertising: $advertising
            customer_support: $customer_support
            risk_insurance: $risk_insurance
            apartment_tour: $apartment_tour
            checkIn_tenants: $checkIn_tenants
            premium_sections: $premium_sections
            search_priority: $search_priority
            service_guarantee: $service_guarantee
            type: $type
          ) {
            id,
          }
        }
  `;

        const resp = await fetch("/graphql", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: query,
                variables: this.values,
            }),
            credentials: "include",
        });

        const {data} = await resp.json()
        if (data && data.addTariff && data.addTariff.id) {
            history.push("/siteadmin/tariffs");
        }

    }

    render() {
        const {
            tariffId,
            data: {loading, getAllTariffs},
        } = this.props;

        if (!isNaN(tariffId)) {
            const tariff = getAllTariffs.find(item => item.id === Number(tariffId))
            if (tariff) {
                this.values = {
                    id: tariffId,
                    name: tariff.name,
                    description: tariff.description,
                    host_commision: tariff.host_commision,
                    guest_commision: tariff.guest_commision,
                    host_is_percent: tariff.host_is_percent,
                    guest_is_percent: tariff.guest_is_percent,
                    is_only_commission: tariff.is_only_commission,
                    advertising: tariff.advertising,
                    customer_support: tariff.customer_support,
                    risk_insurance: tariff.risk_insurance,
                    apartment_tour: tariff.apartment_tour,
                    checkIn_tenants: tariff.checkIn_tenants,
                    premium_sections: tariff.premium_sections,
                    search_priority: tariff.search_priority,
                    service_guarantee: tariff.service_guarantee,
                    type: tariff.type
                }
            }
        }
        if (loading) {
            return <Loader type={"text"}/>;
        } else {
            return (
                <div className={cx(s.pagecontentWrapper, "pagecontentAR")}>
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div>
                                        <form onSubmit={() => {
                                        }}>
                                            <div className="form-group">
                                                <label htmlFor="nameInput">Название</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="nameInput"
                                                    defaultValue={this.values.name}
                                                    onChange={(e) => (this.values.name = e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="descriptionInput">Описание</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="descriptionInput"
                                                    defaultValue={this.values.description}
                                                    onChange={(e) =>
                                                        (this.values.description = e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Тип для хоста</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="hostPercentType"
                                                            id="po1"
                                                            defaultValue={this.values.host_is_percent}
                                                            defaultChecked={this.values.host_is_percent === 0}
                                                            onChange={(e) => (this.values.host_is_percent = 0)}
                                                        />
                                                        Фиксированная сумма
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="hostPercentType"
                                                            id="po2"
                                                            defaultValue={this.values.host_is_percent}
                                                            defaultChecked={this.values.host_is_percent === 1}
                                                            onChange={(e) => (this.values.host_is_percent = 1)}
                                                        />
                                                        Процент
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Комиссия хоста</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="percentInput"
                                                    defaultValue={this.values.host_commision}
                                                    onChange={(e) =>
                                                        (this.values.host_commision = e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Тип комиссии для хоста</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="type"
                                                            id="po1"
                                                            defaultValue={this.values.type}
                                                            defaultChecked={this.values.type === 'monthly'}
                                                            onChange={(e) => (this.values.type = 'monthly')}
                                                        />
                                                        Ежемесячно
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="type"
                                                            id="po1"
                                                            defaultValue={this.values.type}
                                                            defaultChecked={this.values.type === 'daily'}
                                                            onChange={(e) => (this.values.type = 'daily')}
                                                        />
                                                        Ежедневно
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="type"
                                                            id="po1"
                                                            defaultValue={this.values.type}
                                                            defaultChecked={this.values.type === 'yearly'}
                                                            onChange={(e) => (this.values.type = 'yearly')}
                                                        />
                                                        Ежегодно
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Тип для гостья</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="guestPercentType"
                                                            id="po1"
                                                            defaultValue={this.values.guest_is_percent}
                                                            defaultChecked={this.values.guest_is_percent === 0}
                                                            onChange={(e) => (this.values.guest_is_percent = 0)}
                                                        />
                                                        Фиксированная сумма
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="guestPercentType"
                                                            id="po2"
                                                            defaultValue={this.values.guest_is_percent}
                                                            defaultChecked={this.values.guest_is_percent === 1}
                                                            onChange={(e) => (this.values.guest_is_percent = 1)}
                                                        />
                                                        Процент
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Комиссия гостья</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="percentInput"
                                                    defaultValue={this.values.guest_commision}
                                                    onChange={(e) =>
                                                        (this.values.guest_commision = e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Только комиссия</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="onlyCommission"
                                                            id="po1"
                                                            defaultValue={this.values.is_only_commission}
                                                            defaultChecked={this.values.is_only_commission === 1}
                                                            onChange={(e) => (this.values.is_only_commission = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="onlyCommission"
                                                            id="po2"
                                                            defaultValue={this.values.is_only_commission}
                                                            defaultChecked={this.values.is_only_commission === 0}
                                                            onChange={(e) => (this.values.is_only_commission = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput"> Реклама в квартире</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="advertising"
                                                            id="po1"
                                                            defaultValue={this.values.advertising}
                                                            defaultChecked={this.values.advertising === 1}
                                                            onChange={(e) => (this.values.advertising = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="advertising"
                                                            id="po2"
                                                            defaultValue={this.values.advertising}
                                                            defaultChecked={this.values.advertising === 0}
                                                            onChange={(e) => (this.values.advertising = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Клиентская поддержка</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="customer_support"
                                                            id="po1"
                                                            defaultValue={this.values.customer_support}
                                                            defaultChecked={this.values.customer_support === 1}
                                                            onChange={(e) => (this.values.customer_support = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="customer_support"
                                                            id="po2"
                                                            defaultValue={this.values.customer_support}
                                                            defaultChecked={this.values.customer_support === 0}
                                                            onChange={(e) => (this.values.customer_support = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Страхование бытовых рисков</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="risk_insurance"
                                                            id="po1"
                                                            defaultValue={this.values.risk_insurance}
                                                            defaultChecked={this.values.risk_insurance === 1}
                                                            onChange={(e) => (this.values.risk_insurance = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="risk_insurance"
                                                            id="po2"
                                                            defaultValue={this.values.risk_insurance}
                                                            defaultChecked={this.values.risk_insurance === 0}
                                                            onChange={(e) => (this.values.risk_insurance = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">3D-тур по квартире</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="apartment_tour"
                                                            id="po1"
                                                            defaultValue={this.values.apartment_tour}
                                                            defaultChecked={this.values.apartment_tour === 1}
                                                            onChange={(e) => (this.values.apartment_tour = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="apartment_tour"
                                                            id="po2"
                                                            defaultValue={this.values.apartment_tour}
                                                            defaultChecked={this.values.apartment_tour === 0}
                                                            onChange={(e) => (this.values.apartment_tour = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput"> Заезд & Выезд арендатора</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="checkIn_tenants"
                                                            id="po1"
                                                            defaultValue={this.values.checkIn_tenants}
                                                            defaultChecked={this.values.checkIn_tenants === 1}
                                                            onChange={(e) => (this.values.checkIn_tenants = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="checkIn_tenants"
                                                            id="po2"
                                                            defaultValue={this.values.checkIn_tenants}
                                                            defaultChecked={this.values.checkIn_tenants === 0}
                                                            onChange={(e) => (this.values.checkIn_tenants = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Размещение в премиум секциях</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="premium_sections"
                                                            id="po1"
                                                            defaultValue={this.values.premium_sections}
                                                            defaultChecked={this.values.premium_sections === 1}
                                                            onChange={(e) => (this.values.premium_sections = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="premium_sections"
                                                            id="po2"
                                                            defaultValue={this.values.premium_sections}
                                                            defaultChecked={this.values.premium_sections === 0}
                                                            onChange={(e) => (this.values.premium_sections = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Приоритет в поиске</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="search_priority"
                                                            id="po1"
                                                            defaultValue={this.values.search_priority}
                                                            defaultChecked={this.values.search_priority === 1}
                                                            onChange={(e) => (this.values.search_priority = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="search_priority"
                                                            id="po2"
                                                            defaultValue={this.values.search_priority}
                                                            defaultChecked={this.values.search_priority === 0}
                                                            onChange={(e) => (this.values.search_priority = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="percentInput">Поручительство сервиса</label>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="service_guarantee"
                                                            id="po1"
                                                            defaultValue={this.values.service_guarantee}
                                                            defaultChecked={this.values.service_guarantee === 1}
                                                            onChange={(e) => (this.values.service_guarantee = 1)}
                                                        />
                                                        Да
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="service_guarantee"
                                                            id="po2"
                                                            defaultValue={this.values.service_guarantee}
                                                            defaultChecked={this.values.service_guarantee === 0}
                                                            onChange={(e) => (this.values.service_guarantee = 0)}
                                                        />
                                                        Нет
                                                    </label>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-success w-100"
                                                onClick={(e) => this.onSubmit()}
                                            >
                                                Сохранить
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

/*Tariffs = reduxForm({
    name: 'Tariffs',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Tariffs)*/

export default compose(
    withStyles(s),
    graphql(
        gql`
      {
        getAllTariffs {
          id
          name
          description
          host_commision
          guest_commision
          host_is_percent
          guest_is_percent
          is_only_commission
          advertising
          customer_support
          risk_insurance
          apartment_tour
          checkIn_tenants
          premium_sections
          search_priority
          service_guarantee
          type
        }
      }
    `,
        {
            options: {
                fetchPolicy: "network-only",
            },
        }
    )
)(Tariffs);
