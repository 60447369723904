import BaseForm from '../base/BaseForm';
import {reduxForm, Field} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import validateCharacteristics from "../validations/validateCharacteristics";

class AboutHome extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],

        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: true,
        }
    }


    componentDidMount() {
        const { listingFields } = this.props;

        this.setState({
            bedrooms: listingFields.bedrooms[0],
            bathrooms: listingFields.bathrooms[0],
        })
    }


    UNSAFE_componentWillMount() {
        const {listingFields, beds, change, bedTypes} = this.props;

        if (listingFields != undefined) {
            this.setState({
                bedrooms: listingFields.bedrooms[0],
                bathrooms: listingFields.bathrooms[0],
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {listingFields, valid} = nextProps;
        if (listingFields != undefined) {
            this.setState({
                bedrooms: listingFields.bedrooms[0],
                bathrooms: listingFields.bathrooms[0],
            });
        }
    }


    render() {
        const {
            listingFields: {
                rooms,
                bathroomType,
                layoutType,
                repairType,
                wallMaterial,
                ceilingHeight,
                elevatorType
            }, handleSubmit
        } = this.props;
        const {formatMessage} = this.props.intl;
        const { bathrooms, bedrooms } = this.state;

        return (
            <div className={cx(s.listingForm, 'bgBlackTwo')}>
                <Row>
                    <Col xs={12}>
                        <h3 className={cx(s.sidePanelTitle, s.space6)}>
                            <FormattedMessage {...messages.aboutHome} />
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.rooms} /> <span
                                className={s.required}>*</span>
                            </ControlLabel>
                            <Field name="rooms" component={this.renderRadioGroup} options={rooms}
                                   className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>

                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.area} /> <FormattedMessage {...messages.areaSymbol} />
                                <span
                                    className={s.required}>*</span>
                            </ControlLabel>
                            <div className={cx( s.multiInputs)}>
                                <FormGroup className={cx(s.space0)}>
                                    <Field
                                        name="livingArea"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                        label={formatMessage(messages.livingArea)}
                                    />
                                </FormGroup>
                                <FormGroup className={cx(s.space0)}>
                                    <Field
                                        name="totalArea"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                        label={formatMessage(messages.totalArea)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.floor} />
                                <span
                                    className={s.required}>*</span>
                            </ControlLabel>
                            <div className={cx(s.multiInputs)}>
                                <FormGroup className={cx(s.space0)}>

                                    <Field
                                        name="floor"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                    />
                                </FormGroup>
                                <div>
                                    <FormattedMessage {...messages.fromLabel} />
                                </div>
                                <FormGroup className={cx(s.space0)}>
                                    <Field
                                        name="floors"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.layoutType} />
                            </ControlLabel>
                            <Field
                                name="layoutType" component={this.renderRadioGroup} options={layoutType}
                                className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.repairType} />
                            </ControlLabel>
                            <Field
                                name="repairType" component={this.renderRadioGroup} options={repairType}
                                className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.wallMaterial} />
                            </ControlLabel>
                            <Field
                                name="wallMaterial" component={this.renderRadioGroup} options={wallMaterial}
                                className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>


                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.elevatorType} />
                            </ControlLabel>
                            <Field
                                name="elevatorType" component={this.renderRadioGroup} options={elevatorType}
                                   className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.ceilingHeight} />
                            </ControlLabel>
                            <Field
                                name="ceilingHeight" component={this.renderRadioGroup} options={ceilingHeight}
                                   className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.bathroomType} />
                            </ControlLabel>
                            <Field
                                name="bathroomType"
                                component={this.renderButtonGroup} options={bathroomType}
                                className={cx(s.small, s.maxContent)}>
                            </Field>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.howManyBathrooms} />
                            </ControlLabel>
                            <Field
                                name="bathrooms"
                                type="text"
                                component={this.renderIncrementButton}
                                labelSingluar={bathrooms.itemName}
                                labelPlural={bathrooms.otherItemName}
                                maxValue={bathrooms.endValue}
                                minValue={0}
                                incrementBy={1}
                            />
                        </FormGroup>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space6, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.howManyBedrooms} />
                            </ControlLabel>
                            <Field
                                name="bedrooms"
                                type="text"
                                component={this.renderIncrementButton}
                                labelSingluar={bedrooms.itemName}
                                labelPlural={bedrooms.otherItemName}
                                maxValue={bedrooms.endValue}
                                minValue={0}
                                incrementBy={1}
                            />
                        </FormGroup>

                    </Col>
                </Row>
            </div>

        )
    }

}

AboutHome = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateCharacteristics
})(AboutHome)

const mapState = (state) => ({
    listingFields: state.listingFields.data
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AboutHome)));
