import BaseFrom from '../base/BaseForm';
import {reduxForm, Field, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import React from "react";
import s from '../style/base.css'
import CountryList from '../../CountryList';
import {setListingStep} from '../../../actions/listing';
import bt from "../../commonStyle.css";
import Loader from '../../Loader';
import getLocation from '../scripts/getLocation';
import validateLocations from '../validations/validateLocations';
import Map from './Map';

class Locations extends BaseFrom {

    constructor(props) {
        super(props);
        this.state = {
            showMap: false,
            isLoading: false
        }
        this.renderCountryList = this.renderCountryList.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createListing = this.createListing.bind(this);
    }

    componentDidMount() {
        const {existingList, setListingStep} = this.props;
        if (existingList) {
            this.setState({showMap: true});
        }
    }

    renderCountryList({input, label, meta: {touched, error}, children, className}) {
        const {formatMessage} = this.props.intl;
        return <>
            <CountryList input={input} className={className} isEmptyFirst/>

        </>
    }

    async handleSubmit() {
        const values = this.props.locationFields;
        const dispatch = this.props.dispatch;
        const {regions, districts, change} = this.props;

        const region = regions.find(o => o.id == values.state);
        const district = districts.find(o => o.id == values.district_id);
        console.log(region, values)
        values.state = region.regionName
        values.district = district.districtName

        this.setState({isLoading: true});

        const res = await getLocation(values, dispatch)
        if (res?.locationItem) {
            change('lat', res.locationItem.lat);
            change('lng', res.locationItem.lng);
            this.setState({showMap: true});

        }
        this.setState({isLoading: false});

    }

    async createListing() {
        const {submit} = this.props;
        this.setState({isLoading: true});
        await submit();
        this.setState({isLoading: false});
    }

    render() {
        const {handleSubmit, valid, regions, districts, locationFields, existingList} = this.props
        const {isLoading, showMap} = this.state;
        const {formatMessage} = this.props.intl;
        const isValid = showMap ? locationFields.lat && locationFields.lng && locationFields.isMapTouched : valid;
        return (
            <>
                <div className={cx(s.listingForm, 'bgBlackTwo')} onSubmit={handleSubmit}>
                    <Row>
                        <h3 className={cx(s.sidePanelTitle, s.space6)}>Location</h3>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.country} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field name="country"
                                   component={this.renderCountryList}
                                   className={cx(s.formControlSelect, s.listCommonJumboSelect, 'listCommonJumboSelectRTL')}/>
                        </FormGroup>
                        <Row>

                            <Col xs={12}>
                                <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                        <FormattedMessage {...messages.state} /> <span
                                        className={s.required}>*</span>
                                    </ControlLabel>
                                    <Field
                                        name="state"
                                        component={this.renderFormControlSelect}
                                        className={cx(s.formControlInput, s.jumboInput)}
                                        label={formatMessage(messages.state)}
                                    >
                                        <option value="">{formatMessage(messages.chooseRegion)}</option>
                                        {
                                            regions && regions.map((value, key) => {
                                                return (
                                                    value.isEnable == 1 &&
                                                    <option value={value.id} key={key}>{value.regionName}</option>
                                                )
                                            })
                                        }
                                    </Field>
                                </FormGroup>
                            </Col>
                            <Col xs={12}><FormGroup className={cx(s.space5, s.listingFormGroup)}>
                                <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                    <FormattedMessage {...messages.district} /> <span
                                    className={s.required}>*</span>
                                </ControlLabel>
                                <Field
                                    name="district_id"
                                    component={this.renderFormControlSelect}
                                    className={cx(s.formControlInput, s.jumboInput)}
                                    label={formatMessage(messages.district)}
                                >
                                    <option value="">{formatMessage(messages.chooseDistrict)}</option>
                                    {
                                        districts && districts.map((value, key) => {
                                            return (
                                                value.isEnable == 1 &&
                                                <option value={value.id} key={key}>{value.districtName}</option>
                                            )
                                        })
                                    }
                                </Field>
                            </FormGroup>
                            </Col>
                        </Row>


                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.street} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="street"
                                component={this.renderFormControl}
                                className={cx(s.formControlInput, s.jumboInput)}
                                label={formatMessage(messages.street)}
                            />
                        </FormGroup>
                        {/*<FormGroup className={cx(s.space5, s.listingFormGroup)}>*/}
                        {/*    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>*/}
                        {/*        <FormattedMessage {...messages.buildingName} />*/}
                        {/*    </ControlLabel>*/}
                        {/*    <Field*/}
                        {/*        name="buildingName"*/}
                        {/*        component={this.renderFormControl}*/}
                        {/*        className={cx(s.formControlInput, s.jumboInput)}*/}
                        {/*        label={formatMessage(messages.buildingName)}*/}
                        {/*    />*/}
                        {/*</FormGroup>*/}
                        <Field
                            name="lat"
                            type={'hidden'}
                            component={this.renderFormControl}
                            className={cx(s.formControlInput, s.jumboInput)}
                        />
                        <Field
                            name="lng"
                            type={'hidden'}
                            component={this.renderFormControl}
                            className={cx(s.formControlInput, s.jumboInput)}
                        />


                        {showMap && <Map></Map>}


                    </Row>
                    {(!existingList) && <Row>
                        <Col xs={12}>
                            <Loader
                                type={"button"}
                                label={formatMessage(messages.next)}
                                show={isLoading}
                                disabled={!valid}
                                className={cx(bt.btnPrimary, bt.btnLarge, 'arButtonLoader')}
                                handleClick={showMap ? this.createListing : this.handleSubmit}
                                style={{
                                    textAlign: 'right'
                                }}
                            />
                        </Col>
                    </Row>}
                </div>
            </>
        )
    }
}

Locations = reduxForm({
    form: 'ListingNewForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateLocations
})(Locations);
const selector = formValueSelector('ListingNewForm');
const mapState = state => ({
    listingSteps: state.listing.listingSteps,
    locationFields: {
        country: selector(state, 'country'),
        state: selector(state, 'state'),
        district_id: selector(state, 'district_id'),
        street: selector(state, 'street'),
        lat: selector(state, 'lat'),
        lng: selector(state, 'lng'),
        isMapTouched: selector(state.locationMap, 'isMapTouched'),
        buildingName: selector(state, 'buildingName'),
    },
    existingList: state.location.isExistingList,

    regions: state.regions.regions,
    districts: state.regions.districts,
});
const mapDispatch = {
    setListingStep,
    getLocation
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Locations)));
