import React, {Component} from "react";
import PropTypes from "prop-types";
import {injectIntl, FormattedMessage} from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Button from "react-bootstrap/lib/Button";
import cx from "classnames";
import {reduxForm, change, submit as submitForm, getFormValues, Field, formValueSelector} from "redux-form";
import {connect} from "react-redux";

import RoomsBeds from "./RoomsBeds";
import CheckboxListItems from "./CheckboxListItems";
import Price from "./Price";
import InstantBook from "./InstantBook";
import Guests from "../Guests/Guests";
import Dates from "../Dates/Dates";

import messages from "../../../../locale/messages";
import submit from "../../SearchForm/submit";
import {openMoreFiltersModal, closeMoreFiltersModal} from "../../../../actions/modalActions";
import {setPersonalizedValues} from "../../../../actions/personalized";
import s from "./MoreFilters.css";
import {ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import {Range} from "rc-slider";
import rs from '!isomorphic-style-loader!css-loader!rc-slider/assets/index.css';
import Switch from "../ShowOneTotalPrice/Switch";


class MoreFilters extends Component {
    static propTypes = {
        className: PropTypes.any,
        handleTabToggle: PropTypes.any,
        isExpand: PropTypes.bool,
    };

    static defaultProps = {
        isExpand: false,
        fieldsSettingsData: {
            roomType: [],
            essentialsAmenities: [],
            safetyAmenities: [],
            spaces: [],
            houseRules: [],
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            tabs: {
                dates: false,
                guests: false,
                price: false,
                instantBook: false,
                moreFilters: false,
            },
            overlay: false,
            smallDevices: false,
            verySmallDevice: false,
            tabletDevices: false,
        };
    }

    componentDidMount() {
        const {isExpand} = this.props;
        let isBrowser = typeof window !== "undefined";
        if (isBrowser) {
            this.handleResize();
            window.addEventListener("resize", this.handleResize);
        }
        if (isExpand) {
            document.querySelector("body").setAttribute("style", "overflow: hidden");
        }
    }

    componentWillUnmount() {
        let isBrowser = typeof window !== "undefined";
        if (isBrowser) {
            window.removeEventListener("resize", this.handleResize);
        }
        document.querySelector("body").removeAttribute("style");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {isExpand} = nextProps;
        if (isExpand) {
            document.querySelector("body").setAttribute("style", "overflow: hidden");
        } else {
            document.querySelector("body").removeAttribute("style");
        }
    }

    handleResize = (e) => {
        const {tabs} = this.state;
        let isBrowser, smallDevices, verySmallDevice, tabletDevices;
        isBrowser = typeof window !== "undefined";
        smallDevices = isBrowser ? window.matchMedia("(max-width: 767px)").matches : false;
        verySmallDevice = isBrowser ? window.matchMedia("(max-width: 480px)").matches : false;
        tabletDevices = isBrowser ? window.matchMedia("(max-width: 1024px)").matches : false;

        for (let key in tabs) {
            tabs[key] = false;
        }

        this.setState({
            smallDevices,
            verySmallDevice,
            tabs,
            overlay: false,
            tabletDevices,
        });
    };

    handleTabToggle = (currentTab, isExpand) => {
        const {tabs} = this.state;
        for (let key in tabs) {
            if (key == currentTab) {
                tabs[key] = isExpand;
            } else {
                tabs[key] = false;
            }
        }

        this.setState({
            tabs,
            overlay: isExpand,
        });
    };

    handleSubmitForm = async () => {
        const {closeMoreFiltersModal, handleSubmit, change} = this.props;
        await change("currentPage", 1);
        handleSubmit();
        closeMoreFiltersModal();
    };

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleOpen = async () => {
        const {openMoreFiltersModal} = this.props;
        openMoreFiltersModal();
    };


    handleReset = async () => {
        const {change, smallDevice, tabletDevice, setPersonalizedValues, fieldsSettingsData} = this.props;
        await Promise.all([
            change("houseRules", []),
            change("rooms", []),
            change("leaseType", null),
            change("paymentType", []),
            change("publicUtilities", []),
            change("publicUtilitiesType", []),
            change("layoutType", []),
            change("bathroomType", []),
            change("wallMaterialType", []),
            change("bookingType", ""),
            change("priceRange", null),
            change("priceRangeLabel", null),
            change("cancellationPolicies", []),
            change("equipment", []),
            change("houseType", []),
            change("leaseValueRange", null),
            change("floorsRange", null),
            change("livingAreaRange", null),
            change("totalAreaRange", null),
        ]);

        if (smallDevice || tabletDevice) {
            await Promise.all([
                change("roomType", []),
                change("priceRange", null),
                change("priceRangeLabel", null),
                change("bookingType", ""),
                change("dates", null)
            ]);
            setPersonalizedValues({name: 'startDate', value: null});
            setPersonalizedValues({name: 'endDate', value: null});
            setPersonalizedValues({name: 'isOneTotalToggle', value: false});
            setPersonalizedValues({name: 'totalPrice', value: false});
            if (fieldsSettingsData?.personCapacity?.length > 0 && fieldsSettingsData?.personCapacity[0]?.startValue) {
                change("personCapacity", fieldsSettingsData?.personCapacity[0]?.startValue);
            } else {
                change("personCapacity", 0);
            }
        }
    };

    renderFormControl = ({input, label, type, meta: {touched, error}, className}) => {
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <FormControl {...input} placeholder={label} type={type} className={className}/>
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </div>
        )
    }

    async onAfterChange(e) {
        console.log(e)
    }

    async updateFloorsValue(values) {
        const {change} = this.props;

        await change('floorsRange')
    }


    renderRange = ({
                       input,
                       label,
                       meta: {touched, error},
                       className,
                       min,
                       max,
                       rangeCurrency,
                       minValue, maxValue
                   }) => {
        const {formatMessage} = this.props.intl;
        const {handleSubmit, change} = this.props;
        return (
            <div className={cx(s.space5, s.priceRangePadding)}>
                <Range
                    min={min}
                    max={max}
                    defaultValue={[min, max]}
                    value={[minValue, maxValue]}
                    onChange={(value) => {
                        input.onChange(value)
                    }}
                    keyboard={false}
                    marks={{1: minValue, [maxValue]: maxValue}}
                    step={1}
                />
            </div>
        );
    };

    renderSwitch = ({ input, label, meta: { touched, error }, className, value,  min, max, rangeCurrency }) => {
        const { formatMessage } = this.props.intl;
        const { change, cancellationPoliciesValue } = this.props;
        return (
            <div>
                <Switch
                    {...input}
                    checked={cancellationPoliciesValue.includes(input.value)}
                    type={'checkbox'}
                    formName={'SearchForm'}
                    fieldName={'cancellationPolicies'}
                    checkedValue={cancellationPoliciesValue.includes(input.value)}
                    unCheckedValue={!cancellationPoliciesValue.includes(input.value)}
                />
            </div>
        )
    }
    renderCancelPolicy () {
        const { className, handleTabToggle, isExpand, bookingType, cancellationPolicies } = this.props;
        return (
            <div>
                {cancellationPolicies && cancellationPolicies.map((option, index) => {
                    return (
                        <div className={className}>
                            <div className={cx(s.displayFlex, s.space4)}>
                                <div>
                                    <p className={cx(s.moreFilterTitle, s.textBold)}>
                                        {option.policyName} {option.id }
                                    </p>
                                    <p className={cx(s.captionTitle, s.noMarginBottom)}>
                                        <FormattedMessage {...messages.instantBookInfo} />
                                    </p>
                                </div>
                                <div className={cx('searchSwitch', 'directionLtr', 'textAlignLeftRtl')}>
                                    <Field
                                        type={'checkbox'}
                                        name="cancellationPolicies"
                                        min={'test'}
                                        max={'text'}
                                        value={'texxtxtxt'}
                                        component={this.renderSwitch}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </div>

        );
    }

    renderButtonGroup = ({label, name, options, input, className}) => {
        return (
            <ul className={cx(s.listContainer, s.safetyAmenitiesFlex, s.space0)}>
                {options && options.map((option, index) => {
                    return (
                        <li className={cx('amenitiesCheckBox')} key={index}>
                            <div className={cx(s.checkBoxOutline, s.small, className)}>
                                <div className={cx(s.amenitiesCheckBoxWrapper, "darkModeAmenitiesCheckBoxWrapper",
                                    {[s.checked]: input.value.indexOf(option.id) !== -1},
                                    {["darkModeAmenitiesChecked"]: input.value.indexOf(option.id) !== -1}, 'svgImg')}
                                     onClick={() => {
                                         const newValue = [...input.value];
                                         if (newValue.indexOf(option.id) !== -1) {
                                             newValue.splice(newValue.indexOf(option.id), 1);
                                         } else {
                                             newValue.push(option.id);
                                         }
                                         input.onChange(newValue);

                                     }}>
                                    <div className={s.checkoutText}>{option.policyName}</div>
                                </div>
                            </div>
                        </li>
                    )
                })
                }
            </ul>
        )
    }


    render() {
        const {cancellationPolicies, className, formValues, searchSettings, smallDevice, tabletDevice, searchResultCount} = this.props;
        const {tabs, smallDevices, verySmallDevices} = this.state;
        const {
            fieldsSettingsData: {
                essentialsAmenities, rooms, houseType, safetyAmenities, spaces, houseRules, leaseType, leaseValue,
                paymentTypes,
                wallMaterial,
                layoutType,
                repairType,
                bathroomType,
                publicUtilities,
                publicUtilitiesType,
                equipment
            },
            selectedLeaseType
        } = this.props;
        const {formatMessage} = this.props.intl;
        const {floorsMin, floorsMax, } = searchSettings
        const { livingAreaMin, livingAreaMax , totalAreaMin, totalAreaMax, } = searchSettings
        const {totalAreaRange, livingAreaRange, floorsRange,  leaseValueRange} = this.props
        let minFloorsValue, maxFloorsValue, minLivingAreaValue, maxLivingAreaValue, minTotalAreaValue,
            maxTotalAreaValue, minLeaseValue, maxLeaseValue, leaseValueMin, leaseValueMax;

        if (selectedLeaseType) {
            const selectedType = leaseType.find(item => item.id === selectedLeaseType)
            leaseValueMin = selectedType.startValue
            leaseValueMax = selectedType.endValue
        } else {
            leaseValueMin = 1
            leaseValueMax = 31
        }

        minFloorsValue = floorsRange && floorsRange[0] ? floorsRange[0] : floorsMin || 1
        maxFloorsValue = floorsRange && floorsRange[1] ? floorsRange[1] : floorsMax || 100
        minLeaseValue = leaseValueRange && leaseValueRange[0] ? leaseValueRange[0] : leaseValueMin || 1
        maxLeaseValue = leaseValueRange && leaseValueRange[1] ? leaseValueRange[1] : leaseValueMax || 12
        minLivingAreaValue = livingAreaRange && livingAreaRange[0] ? livingAreaRange[0] : livingAreaMin || 1
        maxLivingAreaValue = livingAreaRange && livingAreaRange[1] ? livingAreaRange[1] : livingAreaMax || 100
        minTotalAreaValue = totalAreaRange && totalAreaRange[0] ? totalAreaRange[0] : totalAreaMin || 1
        maxTotalAreaValue = totalAreaRange && totalAreaRange[1] ? totalAreaRange[1] : totalAreaMax || 100

        return (
            <div className={className} ref={this.setWrapperRef}>
                <div className={s.filterSection}>
                    <div>
                        {smallDevice && <Dates
                            handleTabToggle={this.handleTabToggle}
                            isExpand={tabs?.dates}
                            smallDevice={smallDevices}
                            verySmallDevice={verySmallDevices}
                        />}
                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"houseType"}
                            options={houseType}
                            captionTitle={formatMessage(messages.houseTypeLabel)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.close)}
                            isActive={formValues?.houseType?.length > 0}
                            showImage={false}
                        />
                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"rooms"}
                            options={rooms}
                            captionTitle={formatMessage(messages.rooms)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.close)}
                            isActive={formValues?.rooms?.length > 0}
                            showImage={false}
                        />
                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"leaseType"}
                            type={'radio'}
                            options={leaseType}
                            captionTitle={formatMessage(messages.leaseType)}
                            showLabel={formatMessage(messages.showAmenities)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.leaseType?.length > 0}
                            showImage={false}
                        />
                        {selectedLeaseType != undefined && <FormGroup className={[s.filters, s.space5]}>
                            <ControlLabel
                                className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold, 'textWhite')}>
                                <FormattedMessage {...messages.leaseValueLabel} />
                            </ControlLabel>
                            <Field
                                name="leaseValueRange"
                                component={this.renderRange}
                                min={leaseValueMin}
                                max={leaseValueMax}
                                minValue={minLeaseValue}
                                maxValue={maxLeaseValue}
                                className={cx(s.formControlInput, s.jumboInput)}
                                label={formatMessage(messages.floorShort)}
                            />
                        </FormGroup>}
                        <Price
                            className={cx(s.filters, s.space4, s.spaceTop4)}
                            searchSettings={searchSettings}
                            smallDevice={smallDevice}
                            tabletDevice={tabletDevice}
                        />
                        <InstantBook
                            className={cx(s.filters, s.space4)}
                            smallDevice={smallDevice}
                            tabletDevice={tabletDevice}
                        />
                        {/*<RoomsBeds className={cx(s.filters, s.space4)} />*/}

                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"paymentType"}
                            options={paymentTypes}
                            captionTitle={formatMessage(messages.paymentTypes)}
                            showLabel={formatMessage(messages.showAmenities)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.paymentTypes?.length > 0}
                            showImage={false}
                        />
                        <CheckboxListItems
                            fieldName={"houseRules"}
                            options={houseRules}
                            captionTitle={formatMessage(messages.houseRules)}
                            showLabel={formatMessage(messages.showAllHouseRules)}
                            hideLabel={formatMessage(messages.closeHouseRules)}
                            isActive={formValues?.houseRules?.length > 0}
                        />
                        {/*<CheckboxListItems*/}
                        {/*  className={cx(s.filters, s.space4)}*/}
                        {/*  fieldName={"amenities"}*/}
                        {/*  options={essentialsAmenities}*/}
                        {/*  captionTitle={formatMessage(messages.aminities)}*/}
                        {/*  showLabel={formatMessage(messages.showAmenities)}*/}
                        {/*  hideLabel={formatMessage(messages.closeAmenities)}*/}
                        {/*  isActive={formValues?.amenities?.length > 0}*/}
                        {/*  showImage={true}*/}
                        {/*/>*/}
                        {/*<CheckboxListItems*/}
                        {/*  className={cx(s.filters, s.space4)}*/}
                        {/*  fieldName={"safetyAmenities"}*/}
                        {/*  options={safetyAmenities}*/}
                        {/*  captionTitle={formatMessage(messages.safetyamenities)}*/}
                        {/*  showLabel={formatMessage(messages.showAmenities)}*/}
                        {/*  hideLabel={formatMessage(messages.closeAmenities)}*/}
                        {/*  isActive={formValues?.safetyAmenities?.length > 0}*/}
                        {/*  showImage={true}*/}
                        {/*/>*/}
                        {/*<CheckboxListItems*/}
                        {/*  className={cx(s.filters, s.space4)}*/}
                        {/*  fieldName={"spaces"}*/}
                        {/*  options={spaces}*/}
                        {/*  captionTitle={formatMessage(messages.sharedSpaces)}*/}
                        {/*  showLabel={formatMessage(messages.showAllFacilities)}*/}
                        {/*  hideLabel={formatMessage(messages.closeFacilities)}*/}
                        {/*  isActive={formValues?.spaces?.length > 0}*/}
                        {/*  showImage={true}*/}
                        {/*/>*/}
                        <FormGroup className={[s.filters, s.space5]}>
                            <ControlLabel
                                className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold, 'textWhite')}>
                                <FormattedMessage {...messages.floors} />
                            </ControlLabel>
                            <Field
                                name="floorsRange"
                                component={this.renderRange}
                                min={floorsMin}
                                max={floorsMax}
                                minValue={minFloorsValue}
                                maxValue={maxFloorsValue}
                                className={cx(s.formControlInput, s.jumboInput)}
                                label={formatMessage(messages.floorShort)}
                            />
                        </FormGroup>

                        <FormGroup className={[s.filters, s.space5]}>
                            <ControlLabel
                                className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold, 'textWhite')}>
                                <FormattedMessage {...messages.livingArea} />
                            </ControlLabel>
                            <Field
                                name="livingAreaRange"
                                component={this.renderRange}
                                min={livingAreaMin}
                                max={livingAreaMax}
                                minValue={minLivingAreaValue}
                                maxValue={maxLivingAreaValue}
                                className={cx(s.formControlInput, s.jumboInput)}
                            />
                        </FormGroup>
                        <FormGroup className={[s.filters, s.space5]}>
                            <ControlLabel
                                className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold, 'textWhite')}>
                                <FormattedMessage {...messages.totalArea} />
                            </ControlLabel>
                            <Field
                                name="totalAreaRange"
                                component={this.renderRange}
                                min={totalAreaMin}
                                max={totalAreaMax}
                                minValue={minTotalAreaValue}
                                maxValue={maxTotalAreaValue}
                                className={cx(s.formControlInput, s.jumboInput)}
                            />
                        </FormGroup>

                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"publicUtilities"}
                            options={publicUtilities}
                            captionTitle={formatMessage(messages.publicUtilities)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.publicUtilities?.length > 0}
                            showImage={false}
                        />

                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"publicUtilitiesType"}
                            options={publicUtilitiesType}
                            captionTitle={formatMessage(messages.publicUtilitiesType)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.publicUtilitiesType?.length > 0}
                            showImage={false}
                        />


                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"equipment"}
                            options={equipment}
                            captionTitle={formatMessage(messages.conveniences)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.publicUtilitiesType?.length > 0}
                            showImage={false}
                        />

                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"layoutType"}
                            options={layoutType}
                            captionTitle={formatMessage(messages.layoutType)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.layoutType?.length > 0}
                            showImage={false}
                        />
                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"bathroomType"}
                            options={bathroomType}
                            captionTitle={formatMessage(messages.bathroomType)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.bathroomType?.length > 0}
                            showImage={false}
                        />

                        <CheckboxListItems
                            className={cx(s.filters, s.space4)}
                            fieldName={"wallMaterial"}
                            options={wallMaterial}
                            captionTitle={formatMessage(messages.wallMaterial)}
                            showLabel={formatMessage(messages.showMore)}
                            hideLabel={formatMessage(messages.closeAmenities)}
                            isActive={formValues?.wallMaterial?.length > 0}
                            showImage={false}
                        />

                        {/*<FormGroup className={[s.space5]}>*/}
                        {/*    <ControlLabel*/}
                        {/*        className={cx(s.moreFilterTitle, s.space4, s.spaceTop4, s.textBold, 'textWhite')}>*/}
                        {/*        <FormattedMessage {...messages.cancellationPolicies} />*/}
                        {/*    </ControlLabel>*/}
                        {/*    {this.renderCancelPolicy()}*/}
                        {/*</FormGroup>*/}

                    </div>
                </div>
                <div className={s.footerSection}>
                    <Button
                        bsStyle="link"
                        className={cx(s.btnLink, s.linkText, "bgBlack")}
                        onClick={() => this.handleReset()}
                    >
                        <FormattedMessage {...messages.clear} />
                    </Button>
                    <div>
                        <FormattedMessage {...messages.searchResultCounts} values={{
                            count: searchResultCount
                        }} />
                    </div>
                    <Button
                        className={cx(s.btn, s.btnSecondary, s.applyBtn)}
                        onClick={this.handleSubmitForm}
                    >
                        <FormattedMessage {...messages.applyFilters} />
                    </Button>
                </div>
            </div>
        );
    }
}

MoreFilters = reduxForm({
    form: "SearchForm",
    onSubmit: submit,
    destroyOnUnmount: false,
})(MoreFilters);

const selector = formValueSelector('SearchForm')

const mapState = (state) => ({
    fieldsSettingsData: state?.listingFields?.data,
    formValues: getFormValues("SearchForm")(state),
    cancellationPolicies: state.location.cancellationPolicies || [],
    leaseValueRange: selector(state, 'leaseValueRange'),
    floorsRange: selector(state, 'floorsRange'),
    livingAreaRange: selector(state, 'livingAreaRange'),
    totalAreaRange: selector(state, 'totalAreaRange'),
    selectedLeaseType: selector(state, 'leaseType'),
    floorsMax: state.searchSettings?.floorsMax || 100,
    floorsMin: state.searchSettings?.floorsMin || 0,
    livingAreaMax: state.searchSettings?.livingAreaMax || 100,
    livingAreaMin: state.searchSettings?.livingAreaMin || 0,
    totalAreaMax: state.searchSettings?.totalAreaMax || 100,
    totalAreaMin: state.searchSettings?.totalAreaMin || 0,
    cancellationPoliciesValue: selector(state, 'cancellationPolicies' ) || [],
    searchResultCount: state.search?.count || 0
});

const mapDispatch = {
    change,
    submitForm,
    openMoreFiltersModal,
    closeMoreFiltersModal,
    setPersonalizedValues
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(MoreFilters)));
