import MobileMenuBeforeLogin from '../MobileMenuBeforeLogin'
import MobileMenuAfterLogin from '../MobileMenuAfterLogin'
import Link from '../Link'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React from 'react'
import s from './MobileMenu.css'
import {injectIntl} from "react-intl";
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux'
import {setUserLogout} from "../../actions/logout";
import {openLoginModal, openSignupModal} from "../../actions/modalActions";
import PropTypes from "prop-types";
import history from "../../core/history";

class MobileMenu extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        isAuthenticated: PropTypes.bool,
        openLoginModal: PropTypes.any,
    };

    render () {
        const { openLoginModal, isAuthenticated} = this.props
        let location ;
        if (history.location) location = history.location.pathname;

        if (isAuthenticated) {
            return <MobileMenuAfterLogin
                localtions={location}

            />
        }
        return <MobileMenuBeforeLogin
            localtions={location}
            openLoginModal={openLoginModal}
        >

        </MobileMenuBeforeLogin>
    }
}

const mapState = (state) => ({
    isAuthenticated: state.runtime.isAuthenticated,
});

const mapDispatch = {
    setUserLogout,
    openLoginModal,
    openSignupModal,
};

export default compose(
    injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch),
)(MobileMenu);
