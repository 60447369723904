// Redux Form
import {SubmissionError} from 'redux-form';

// Fetch request
import fetch from '../../../core/fetch';

// Locale
import messages from '../../../locale/messages';


// Redux Action
import {getListingData} from '../../../actions/NewListing/getListing';
import {getListingFieldsValues} from '../../../actions/NewListing/getListingFieldsValues';
import {setLoaderStart, setLoaderComplete} from '../../../actions/loader/loader';

// For Redirect
import history from '../../../core/history';
import showToaster from "../../../helpers/showToaster";

async function update(values, dispatch) {

    let bedTypes = JSON.stringify(values.bedTypes);
    const equipmentValues = values.equipmentValues ? JSON.stringify(values.equipmentValues) : null;
    let variables = Object.assign({}, values, {bedTypes, equipmentValues});
    let minNight, maxNight;
    dispatch(setLoaderStart('updateListing'));
    const query = `query (
  	$id: Int,
    $roomType:String,
    $houseType:String,
    $residenceType:String,
    $bedrooms:String,
    $buildingSize:String,
    $beds:Int,
    $personCapacity:Int,
    $bathrooms:Float,
    $bathroomType:[Int],
    $country:String,
    $street:String,
    $buildingName:String,
    $city:String,
    $state:String,
     $district_id: String,
        $region_id: String,
    $zipcode:String,
  	$lat: Float,
  	$lng: Float,
  	$isMapTouched: Boolean,
    $amenities: [Int],
    $safetyAmenities: [Int],
    $spaces: [Int],
    $bedTypes: String,
    $publicUtilities: [Int],
    $paymentTypes: [Int],
    $basePrice: Float,
    $currency: String,
    $floors: String,
    $rooms: Int,
    $publicUtilitiesType: Int,
    $livingArea: Float,
    $tariff_id: String,
    $cadastralNumber: String,
    $repairType: Int,
    $wallMaterial: Int,
    $ceilingHeight: Int,
    $elevatorType: Int,
    $layoutType: Int,
    $floor: String,
    $totalArea: Float,
    $deposit: String
    $priceAuction: Int,
    
     $description: String,
    $kuulaLink: String,
    $coverPhoto: Int,
    $equipment: [Int],
    $parking: [Int],
    $security: [Int],
    $landscaping: [Int],
    $infrastructure: [Int],
    $equipmentValues: String,
    
    $bookingGoals: [Int],
    $houseRules: [Int],
    $bookingNoticeTime:String,
    $checkInStart:String,
    $checkInEnd:String,
    $maxDaysNotice:String,
    $minNight:Int,
    $maxNight:Int,
    $cleaningPrice:Float,
    $tax:Float,
    $weeklyDiscount:Int,
    $monthlyDiscount:Int,
    $blockedDates: [String],
    $bookingType: String!,
    $cancellationPolicy: Int,
    $leaseMinValue:Int,
    $leaseMaxValue:Int,
    $leaseType: [Int],
    $monthlyPrice: Float
  ) {
      updateListingV2 (
        id: $id,
        roomType:$roomType,
        houseType:$houseType,
        residenceType: $residenceType,
        bedrooms: $bedrooms,
        buildingSize: $buildingSize
        beds: $beds
        personCapacity: $personCapacity
        bathrooms: $bathrooms
        bathroomType: $bathroomType
        country: $country
        street: $street
        buildingName: $buildingName
        city: $city
        state: $state
        district_id: $district_id
        region_id: $region_id
        zipcode: $zipcode
        lat: $lat
        lng: $lng
        isMapTouched: $isMapTouched,
        amenities: $amenities,
        safetyAmenities: $safetyAmenities,
        spaces: $spaces,
        bedTypes: $bedTypes,
        leaseType: $leaseType,
        publicUtilities: $publicUtilities,
        paymentTypes: $paymentTypes,
        basePrice: $basePrice,
        currency: $currency,
        floors: $floors,
        rooms: $rooms,
        livingArea: $livingArea,
        publicUtilitiesType: $publicUtilitiesType,
        tariff_id: $tariff_id,
        cadastralNumber: $cadastralNumber,
        repairType: $repairType,
        wallMaterial: $wallMaterial,
        ceilingHeight: $ceilingHeight,
        elevatorType: $elevatorType,
        layoutType: $layoutType,
        floor: $floor,
        totalArea: $totalArea,
        deposit: $deposit,
        priceAuction: $priceAuction,
        
        description: $description,
        kuulaLink: $kuulaLink,
        coverPhoto: $coverPhoto,
        equipment: $equipment,
        parking: $parking,
        security: $security,
        landscaping: $landscaping,
        infrastructure: $infrastructure,
        equipmentValues: $equipmentValues,
        
        bookingGoals: $bookingGoals,
        houseRules: $houseRules,
        bookingNoticeTime:$bookingNoticeTime,
        checkInStart:$checkInStart,
        checkInEnd:$checkInEnd,
        maxDaysNotice:$maxDaysNotice,
        minNight:$minNight,
        maxNight:$maxNight,
        cleaningPrice:$cleaningPrice,
        tax:$tax,
        weeklyDiscount:$weeklyDiscount,
        monthlyDiscount:$monthlyDiscount,
        blockedDates: $blockedDates,
        bookingType: $bookingType,
        cancellationPolicy: $cancellationPolicy,
        leaseMinValue: $leaseMinValue,
        leaseMaxValue: $leaseMaxValue,
        monthlyPrice: $monthlyPrice
      ) {
        status
      }
    }`;

    if (variables.cadastralNumber) {
        variables.cadastralNumber = variables.cadastralNumber.replaceAll(':', '')
    }

    if (values.leaseMinValue && values.leaseMaxValue) {
        const today = new Date();

// Get date 3 months from now
        const futureDate = new Date(today);
        const maxDate = new Date(today);
        futureDate.setMonth(today.getMonth() + values.leaseMinValue);
        maxDate.setMonth(today.getMonth() + values.leaseMaxValue);


// Calculate difference in days
        const diffTime = futureDate - today;
        const maxDiffTime = maxDate - today;
        minNight = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        maxNight = Math.ceil(maxDiffTime / (1000 * 60 * 60 * 24));
    }

    variables.minNight = minNight;
    variables.maxNight = maxNight;

    const resp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables
        }),
        credentials: 'include'
    });

    const {data} = await resp.json();

    if (data.updateListingV2.status == "success") {
        showToaster({ messageId: 'successUpdateListing', toasterType: 'success' })
        // await dispatch(getListingData(values.id));
        // await dispatch(getListingFieldsValues(values.id));
        history.push('/rooms')
        await dispatch(setLoaderComplete('updateListing'));

    } else if (data.updateListingV2.status == "notLoggedIn") {
        dispatch(setLoaderComplete('updateListing'));
        throw new SubmissionError({_error: messages.notLoggedIn});
    } else {
        dispatch(setLoaderComplete('updateListing'));
        throw new SubmissionError({_error: messages.somethingWentWrong});
    }

}

export default update;
