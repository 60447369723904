import messages from '../../../locale/messages';

export default function validatePrice(values) {
    const errors = {};
    const enableMonthlyPriceValidation = values.leaseType && values.leaseType.length > 1
    if (!values.basePrice) errors.basePrice = messages.required;

    if (enableMonthlyPriceValidation) {

        if (!values.monthlyPrice) errors.monthlyPrice = messages.required;
        if (isNaN(values.monthlyPrice) || (!/^[0-9\.]+$/.test(values.monthlyPrice)) || (parseFloat(values.monthlyPrice, 10) < 1)) errors.monthlyPrice = messages.basePriceInvalid;

    }

    if (isNaN(values.basePrice) || (!/^[0-9\.]+$/.test(values.basePrice)) || (parseFloat(values.basePrice, 10) < 1)) errors.basePrice = messages.basePriceInvalid;

    if (!values.paymentTypes) errors.paymentTypes = messages.required
    if (!values.publicUtilities) errors.publicUtilities = messages.required
    if (!values.publicUtilitiesType) errors.publicUtilitiesType = messages.required
    if (!values.tariff_id) errors.tariff_id = messages.required


    return errors;
}
