import BaseForm from '../base/BaseForm';
import {reduxForm, Field, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import messages from "../../../locale/messages";
import PropTypes from "prop-types";
import s from '../style/base.css'
import React from 'react'
import toolTipIcon from "/public/SiteIcons/listCommonToolTip.svg";
import Tariff from "./Tariff";

class Pricing extends BaseForm {

    static propTypes = {
        listingFields: PropTypes.object
    }

    static defaultProps = {
        listingFields: {
            rooms: [],
        }

    }

    constructor(props) {
        super(props);
        this.state = {
            depositPercent: 0,
            depositPercents: [],

        }
        this.onChangeDeposit = this.onChangeDeposit.bind(this);
    }

    componentDidMount() {
        const {formatMessage} = this.props.intl;
        const {price} = this.props
        const percents = [
            {
                id: 1,
                itemName: formatMessage(messages.withoutDeposit),
                value: 0,
                isEnable: 1
            },
            {
                id: 2,
                itemName: "50%",
                value: 50,
                isEnable: 1
            },
            {
                id: 3,
                itemName: "100%",
                value: 100,
                isEnable: 1
            }
        ]
        this.setState({
            depositPercents: percents,
            depositPercent: !price ? percents[0].id : 0
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {formatMessage} = this.props.intl;
        const {price} = nextProps;
        const percents = [
            {
                id: 1,
                itemName: formatMessage(messages.withoutDeposit),
                value: 0,
                isEnable: 1
            },
            {
                id: 2,
                itemName: "50%",
                value: 50,
                isEnable: 1
            },
            {
                id: 3,
                itemName: "100%",
                value: 100,
                isEnable: 1
            }
        ]
        this.setState({
            depositPercent: !price ? percents[0].id : 0
        })
    }

    onChangeDeposit(option) {
        const {price} = this.props;
        this.setState({
            depositPercent: option.id
        })

        if (option.value > 0 && parseFloat(price) > 0) {
            this.props.change('deposit', parseFloat(price) * (option.value / 100))
        } else {
            this.props.change('deposit', 0)
        }
    }

    render() {
        const {formatMessage} = this.props.intl;
        const {
            availableCurrencies,
            leaseTypesValue,
            listingFields: {
                publicUtilitiesType,
                publicUtilities,
                paymentTypes,
                leaseType
            }, handleSubmit
        } = this.props;
        const {depositPercents, depositPercent} = this.state
        const priceAuction = [
            {
                id: 1,
                itemName: formatMessage(messages.priceAuction),
                value: 1,
                isEnable: 1
            },
            {
                id: 0,
                itemName: formatMessage(messages.noLabel),
                value: 0,
                isEnable: 1
            }
        ]

        const showMonthlyPrice = leaseType.filter(item => leaseTypesValue.includes(item.id)).some(item => item.otherItemName === 'month') && leaseTypesValue.length > 1

        return (
            <div className={cx(s.listingForm, 'bgBlackTwo')}>
                <Row>
                    <Col xs={12}>
                        <h3 className={cx(s.sidePanelTitle, s.space6)}>
                            <FormattedMessage {...messages.pricing} />
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>

                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.auction} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="priceAuction"
                                component={this.renderRadioGroup}
                                options={priceAuction}
                                className={cx(s.small, s.maxContent)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <div className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.dailyPrice} />
                                <span
                                    className={s.required}>*</span>
                            </ControlLabel>
                            <div className={cx(s.multiInputs)}>
                                <FormGroup className={cx(s.space0)}>
                                    <Field
                                        name="basePrice"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                    />
                                </FormGroup>
                                <FormGroup className={cx(s.space0)}>
                                    <Field
                                        name="currency"
                                        component={this.renderFormControlSelect}
                                        className={cx(
                                            s.formControlSelect,
                                            s.jumboSelect,
                                            s.listCommonJumboSelect,
                                            "listCommonJumboSelectRTL"
                                        )}
                                    >
                                        {availableCurrencies?.length > 0 &&
                                            availableCurrencies.map((currency, key) => {
                                                if (currency.isEnable === true) {
                                                    return (
                                                        <option key={key} value={currency.symbol}>
                                                            {currency.symbol}
                                                        </option>
                                                    );
                                                }
                                            })}
                                    </Field>
                                </FormGroup>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className={s.tipCommonCss}>
                            <img src={toolTipIcon} alt={'info'}/>
                            <span className={cx(s.commonTipCsss, "textWhite")}>
                                <FormattedMessage {...messages.basePriceTip} />
                          </span>
                        </div>
                    </Col>
                    {showMonthlyPrice &&
                        <>
                            <Col xs={12}>
                                <div className={cx(s.space5, s.listingFormGroup)}>
                                    <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                        <FormattedMessage {...messages.monthlyPrice} />
                                        <span
                                            className={s.required}>*</span>
                                    </ControlLabel>
                                    <FormGroup className={cx(s.space0)}>
                                        <Field
                                            name="monthlyPrice"
                                            component={this.renderFormControl}
                                            type="number"
                                            className={cx(s.formControlInput, s.jumboInput)}
                                        />
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className={s.tipCommonCss}>
                                    <img src={toolTipIcon} alt={'info'}/>
                                    <span className={cx(s.commonTipCsss, "textWhite")}>
                                         <FormattedMessage {...messages.monthlyPriceTip} />
                                    </span>
                                </div>
                            </Col>
                        </>
                    }

                    <Col xs={12}>
                        <div className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, 'textWhite')}>
                                <FormattedMessage {...messages.deposit} />
                                <span
                                    className={s.required}>*</span>
                            </ControlLabel>
                            <div className={cx(s.multiInputs)}>
                                <FormGroup className={cx(s.space0)}>
                                    <Field
                                        name="deposit"
                                        component={this.renderFormControl}
                                        type="number"
                                        className={cx(s.formControlInput, s.jumboInput)}
                                    />
                                </FormGroup>
                                <FormGroup className={cx(s.space0)}>

                                    {this.renderRadioGroup({
                                        options: depositPercents,
                                        className: cx(s.small, s.maxContent),
                                        onChange: this.onChangeDeposit,
                                        input: {
                                            value: depositPercent
                                        }
                                    })}
                                </FormGroup>
                            </div>
                        </div>
                        <Col xs={12}>
                            <div className={s.tipCommonCss}>
                                <img src={toolTipIcon} alt={'info'}/>
                                <span className={cx(s.commonTipCsss, "textWhite")}>
                                <FormattedMessage {...messages.depositPriceTip} />
                          </span>
                            </div>
                        </Col>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>

                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.paymentTypes} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="paymentTypes"
                                component={this.renderButtonGroup}
                                options={paymentTypes}
                                className={cx(s.small, s.maxContent)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>

                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.publicUtilities} /> <span className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="publicUtilities"
                                component={this.renderButtonGroup}
                                options={publicUtilities}
                                className={cx(s.small, s.maxContent)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={cx(s.space5, s.listingFormGroup)}>
                            <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                                <FormattedMessage {...messages.publicUtilitiesType} /> <span
                                className={s.required}>*</span>
                            </ControlLabel>
                            <Field
                                name="publicUtilitiesType"
                                component={this.renderRadioGroup}
                                options={publicUtilitiesType}
                                className={cx(s.small, s.maxContent)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <Tariff></Tariff>

                    </Col>

                </Row>
            </div>

        )
    }

}

Pricing = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Pricing)

const selector = formValueSelector('ListingNewForm');

const mapState = (state) => ({
    listingFields: state.listingFields.data,
    availableCurrencies: state.currency.availableCurrencies,
    price: selector(state, 'basePrice'),
    leaseTypesValue: selector(state, 'leaseType') || [],
});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Pricing)));
