import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import Tariffs from './Tariffs';
import { restrictUrls } from '../../../helpers/adminPrivileges';
import getTariffById from "./add/getTariffById";
import Add from "./add/Add";


const title = 'Тарифы';

export default async function action({params, store }) {


  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' };
  }

  // Admin restriction
  if (!restrictUrls('/siteadmin/tariffs', adminPrivileges)) {
    return { redirect: '/siteadmin' };
  }

  const tariffId = params['tariffId']
  if (tariffId && !isNaN(tariffId)) {
    return {
      title,
      component: (
          <AdminLayout>
            <Add tariffId={tariffId} title={title} />
          </AdminLayout>
      ),
    }
  } else if (tariffId === 'add') {
    return {
      title,
      component: (
          <AdminLayout>
            <Add title={title} />
          </AdminLayout>
      ),
    }
  } else {
    return {
      title,
      component: <AdminLayout><Tariffs title={title} /></AdminLayout>,
    };
  }


}
