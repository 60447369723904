import React from 'react';
import PropTypes from 'prop-types';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ListingDetails.css';
import {
    Button,
    Row,
    Col,
    Collapse
} from 'react-bootstrap';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
// Translation
import {injectIntl, FormattedMessage} from 'react-intl';
// Locale
import messages from '../../../locale/messages';
// Redux
import {connect} from 'react-redux';
// Redux Action
import {getSpecificSettings} from '../../../actions/getSpecificSettings';
import {contactHostOpen} from '../../../actions/message/contactHostModal';
// Helper functions
import {checkIn, checkValue} from './helper';
// Internal Component
import ListItem from './ListItem';
import Link from '../../Link';
import Loader from '../../Loader';
import ListBedTypes from './ListBedTypes';

//image
import roomTypeIcon from '/public/SiteIcons/listingRoomTypeIcon.svg';
import guestIcon from '/public/SiteIcons/listingGuestIcon.svg';
import bedIcon from '/public/SiteIcons/listingBedIcon.svg';
import bathIcon from '/public/SiteIcons/listingBathIcon.svg';
import checkInIcon from '/public/SiteIcons/listingCheckIcon.svg';
import rightArrow from '/public/SiteIcons/viewReceiptRight.svg';
import parking from '/public/SiteIcons/listingParkingIcon2.svg';

import wallMaterialIcon from '/public/SiteIcons/equipmentIcons/wallMaterial.svg'
import wallHeightIcon from '/public/SiteIcons/equipmentIcons/wallHeight.svg'
import repairIcon from '/public/SiteIcons/equipmentIcons/repairType.svg'
import houseIcon from '/public/SiteIcons/equipmentIcons/houseType.svg'
import floorIcon from '/public/SiteIcons/equipmentIcons/floor.svg'
import elevatorIcon from '/public/SiteIcons/equipmentIcons/elevatorType.svg'
import layoutIcon from '/public/SiteIcons/equipmentIcons/elevatorType.svg'

import totalRoom from '/public/SiteIcons/totalRooms.svg';
import bt from "../../commonStyle.css";
import {url} from "../../../config";
import IconDefault from "../../../../public/SiteIcons/defaultIcon.png";

class ListingDetails extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            listingData: PropTypes.shape({
                cancellation: PropTypes.shape({
                    policyName: PropTypes.string.isRequired,
                    policyContent: PropTypes.string.isRequired
                })
            })
        }),
        getSpecificSettings: PropTypes.any,
        settingsData: PropTypes.object,
        isHost: PropTypes.bool.isRequired,
        formatMessage: PropTypes.any,
        userBanStatus: PropTypes.number,
        urlParameters: PropTypes.shape({
            startDate: PropTypes.string,
            endDate: PropTypes.string,
            guests: PropTypes.string,
            listTitle: PropTypes.string
        })
    };
    static defaultProps = {
        isHost: false,
        description: []
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isLoad: false,
            showMoreDetailes: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({isLoad: false});
    }

    UNSAFE_componentWillMount() {
        this.setState({isLoad: true});
    }

    handleClick() {
        this.setState({open: !this.state.open})
    }

    showMoreDetailes() {
        this.setState({showMoreDetails: this.state.showMoreDetailes});
    }

    render() {
        const {data} = this.props;
        const {formatMessage} = this.props.intl;
        const {open, isLoad, showMoreDetailes} = this.state;

        let checkInStart, checkInEnd, roomType, buildingSize, houseType;
        let userAmenities = [], userSafetyAmenities = [];
        let sharedSpaces = [], houseRules = [], listBedTypes = [];
        let description, personCapacity, bathrooms, bedrooms, bedCounts = 0;
        let count = 150, firstArray, restArray, dotString = false;
        let isAmenities, isSharedSpaces, parking, bedResult = [], equipments = [];
        let settings = {}
        if (data.listingData != undefined) {
            checkInStart = checkValue(data.listingData.checkInStart, '');
            checkInEnd = checkValue(data.listingData.checkInEnd, '');
        }
        if (data.settingsData != undefined && data.settingsData.length > 0) {
            data.settingsData.map((item) => {
                if (item.listsettings.settingsType.isMultiValue) {
                    if (settings[item.listsettings.settingsType.typeName] === undefined) {
                        settings[item.listsettings.settingsType.typeName] = [];
                    }
                    settings[item.listsettings.settingsType.typeName].push(item);
                } else {
                    settings[item.listsettings.settingsType.typeName] = item;
                }
            })
            roomType = settings.rooms.listsettings;
        }
        console.log(settings, 'settings')
        sharedSpaces = checkValue(data.userSpaces, []);
        houseRules = checkValue(data.houseRules, []);
        userAmenities = checkValue(data.userAmenities, []);
        userSafetyAmenities = checkValue(data.userSafetyAmenities, []);
        description = checkValue(data.description, '');
        personCapacity = checkValue(data.personCapacity, 0);
        bathrooms = checkValue(data.bathrooms, 0);
        bedrooms = checkValue(data.bedrooms, 0);
        listBedTypes = checkValue(data.listBedTypes, []);
        parking = checkValue(settings.parking, []);
        equipments = checkValue(settings.equipment, []);
        houseType = settings.houseType.listsettings
        let totalArea = data.listingData.totalArea
        let livingArea = data.listingData.livingArea
        let floor = data.listingData.floor
        let repairType = settings.repairType.listsettings
        let elevatorType = settings.elevatorType.listsettings
        let wallMaterial = settings.wallMaterial.listsettings
        let ceilingHeight = settings.ceilingHeight.listsettings
        let layoutType = settings.layoutType.listsettings


        if (listBedTypes && listBedTypes.length > 0) {
            bedResult = listBedTypes.reduce((acc, o) => (acc[o.listsettings && o.listsettings.itemName] = (acc[o.listsettings && o.listsettings.itemName] || 0) + 1, acc), {});
            bedCounts = Object.values(bedResult);
            bedCounts = bedCounts.reduce((a, b) => a + b, 0)
        }

        if (description) {
            firstArray = description.slice(0, count);
            restArray = description.slice(count, description.length);
        }
        if (restArray && restArray.length > 0) {
            dotString = true;
        }

        userAmenities.map((item) => {
            if (item.listsettings.isEnable == '1') {
                isAmenities = true;
            }
        });

        sharedSpaces.map((item) => {
            if (item.listsettings.isEnable == '1') {
                isSharedSpaces = true
            }
        });

        return (
            <Row className={cx(s.pageContent)}>
                <div className={cx(s.horizontalLineThrough)}>
                    <h2 className={cx(s.sectionTitleText)}><FormattedMessage {...messages.aboutListing} /></h2>
                    <div>
                        <p className={cx(s.listingFontSize)}>
                            {!this.state.open && count >= 150 &&
                                <span className={cx(s.subText, s.lineBreak)}>  {firstArray} {dotString === true &&
                                    <span>...</span>}</span>
                            }
                            {
                                restArray && restArray.length > 0 &&
                                <span>
                  <Collapse in={open}>
                    <div> <span className={cx(s.subText, s.lineBreak)}>
                      {this.state.open && firstArray} {restArray}
                    </span></div>
                  </Collapse>
                                    {
                                        dotString && <div className={s.btnContainer}>
                                            <div className={s.showHidePadding}>
                                                <Button
                                                    bsStyle="link"
                                                    className={cx(s.button, s.noPadding, s.btnLInk, s.showHideBtn, 'bgTransparent')}
                                                    onClick={() => this.handleClick()}
                                                >
                                                    {this.state.open ? <FormattedMessage {...messages.closeAll} /> :
                                                        <FormattedMessage {...messages.showDescription} />}

                                                    {
                                                        this.state.open &&
                                                        <FontAwesome.FaAngleUp className={s.navigationIcon}/>
                                                    }
                                                    {
                                                        !this.state.open &&
                                                        <FontAwesome.FaAngleDown className={s.navigationIcon}/>
                                                    }

                                                </Button>
                                            </div>
                                        </div>
                                    }
                </span>
                            }
                        </p>
                    </div>
                </div>
                <div className={s.listingLine}></div>
                <div className={cx(s.horizontalLineThrough)}>
                    <div className={cx(s.space1)}>
                        <p className={s.sectionTitleText}><FormattedMessage {...messages.mainFeatures} /></p>
                    </div>
                    {!isLoad &&
                        <div className={cx(s.grid, s.settingsItems)}>

                            {roomType &&
                                <p className={cx(s.flex, s.settingsItem)}>
                                    <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img
                                        src={roomTypeIcon}/>
                                    </div>
                                    <div className={cx(s.textCss)}>
                                        <div><FormattedMessage {...messages.roomType} /></div>
                                        <div className={cx(s.smallText, 'textWhite')}>{roomType.itemName}</div>
                                    </div>
                                </p>
                            }
                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={roomTypeIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.totalAreaShort} /></div>
                                    <div
                                        className={cx(s.smallText, 'textWhite')}>{totalArea} {formatMessage(messages.areaSymbol)}</div>
                                </div>
                            </p>
                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={roomTypeIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.livingAreaShort} /></div>
                                    <div
                                        className={cx(s.smallText, 'textWhite')}>{livingArea} {formatMessage(messages.areaSymbol)}</div>
                                </div>
                            </p>
                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={floorIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.floorShort} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{floor || 0}</div>
                                </div>
                            </p>
                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={repairIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.repairType} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{repairType.itemName}</div>
                                </div>
                            </p>

                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={bedIcon}/></div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.bedrooms} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{bedrooms} {bedrooms > 1 ?
                                        <FormattedMessage {...messages.bedrooms} /> :
                                        <FormattedMessage {...messages.bedroom} />}</div>
                                </div>
                            </p>

                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={bathIcon}/></div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.bathrooms} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{bathrooms} {bathrooms > 1 ?
                                        <FormattedMessage {...messages.bathrooms} /> :
                                        <FormattedMessage {...messages.bathroom} />}</div>
                                </div>
                            </p>
                            {houseType &&
                                <p className={cx(s.flex, s.settingsItem)}>
                                    <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img
                                        src={houseIcon}/>
                                    </div>
                                    <div className={cx(s.textCss, s.settingsItemText)}>
                                        <div><FormattedMessage {...messages.houseTypeLabel} /></div>
                                        <div className={cx(s.smallText, 'textWhite')}>{houseType.itemName}</div>
                                    </div>
                                </p>
                            }
                        </div>
                    }
                </div>
                <div className={s.listingLine}></div>

                <div className={cx(s.horizontalLineThrough)}>
                    <div className={cx(s.space1)}>
                        <p className={s.sectionTitleText}><FormattedMessage {...messages.layout} /></p>
                    </div>
                    {!isLoad &&
                        <div className={cx(s.grid, s.settingsItems)}>

                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={layoutIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.layoutType} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{layoutType.itemName}</div>
                                </div>
                            </p>
                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img
                                    src={wallMaterialIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.wallMaterial} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{wallMaterial.itemName}</div>
                                </div>
                            </p>
                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={wallHeightIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.ceilingHeight} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{ceilingHeight.itemName}</div>
                                </div>
                            </p>
                            <p className={cx(s.flex, s.settingsItem)}>
                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}><img src={elevatorIcon}/>
                                </div>
                                <div className={cx(s.textCss)}>
                                    <div><FormattedMessage {...messages.elevatorType} /></div>
                                    <div className={cx(s.smallText, 'textWhite')}>{elevatorType.itemName}</div>
                                </div>
                            </p>
                        </div>
                    }
                </div>

                <div className={cx(s.listingLine, s.listingLineTwo)}></div>
                {
                    listBedTypes && listBedTypes.length > 0 && listBedTypes[0].bedType && <div><ListBedTypes
                        itemList={listBedTypes}
                        label={formatMessage(messages.bedsCapital)}
                    />
                        <div className={cx(s.listingLine, s.listingLineTwo)}></div>
                    </div>

                }

                {equipments && equipments.length > 0 &&
                    <div className={cx(s.horizontalLineThrough)}>
                        <div className={cx(s.space1)}>
                            <p className={s.sectionTitleText}><FormattedMessage {...messages.equipment} /></p>
                        </div>
                        {!isLoad &&
                            <div className={cx(s.grid, s.settingsItems)}>
                                {
                                    equipments && equipments.length > 0 &&

                                    equipments.map((item) => {
                                        return (
                                            <p className={cx(s.flex, s.settingsItem)}>
                                                <div className={cx(s.svgImg, 'svgImg', s.settingsItemIcon)}>
                                                    {
                                                        item.listsettings.image ?
                                                            <img
                                                                src={url + '/images/amenities/' + item.listsettings.image}
                                                                className={cx(s.imgSection, 'imgSectionRtl', 'darkModeFilterNone')}/> :
                                                            <img src={IconDefault}
                                                                 className={cx(s.imgSection, 'imgSectionRtl')}/>
                                                    }
                                                </div>
                                                <div className={cx(s.textCss)}>
                                                    <div>{item.listsettings.itemName}</div>
                                                </div>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>

                } {
                parking && parking.length > 0 && <div><ListItem
                    itemList={parking}
                    label={formatMessage(messages.parking)}
                    showLabel={formatMessage(messages.showAmenities)}
                    hideLabel={formatMessage(messages.closeAmenities)}
                    icon={false}
                    houseRulesIcon={true}
                />
                    <div className={s.listingLine}></div>
                </div>
            }
                {
                    userSafetyAmenities.length > 0 && <div><ListItem
                        itemList={userSafetyAmenities}
                        label={formatMessage(messages.safetyFeatures)}
                        showLabel={formatMessage(messages.showAllSafetyFeatures)}
                        hideLabel={formatMessage(messages.closeSafetyFeatures)}
                        icon={true}
                    />
                        <div className={s.listingLine}></div>
                    </div>
                }
                {
                    sharedSpaces && sharedSpaces.length > 0 && isSharedSpaces && <div><ListItem
                        itemList={sharedSpaces}
                        label={formatMessage(messages.sharedSpaces)}
                        showLabel={formatMessage(messages.showAllSharedSpaces)}
                        hideLabel={formatMessage(messages.closeAllSharedSpaces)}
                        icon={true}
                    />
                        <div className={s.listingLine}></div>
                    </div>
                }

                {
                    houseRules.length > 0 && <div><ListItem
                        itemList={houseRules}
                        label={formatMessage(messages.houseRules)}
                        showLabel={formatMessage(messages.showAllHouseRules)}
                        hideLabel={formatMessage(messages.closeHouseRules)}
                        icon={false}
                        houseRulesIcon={true}
                    />
                        <div className={s.listingLine}></div>
                    </div>
                }

                {
                    data && data.listingData && data.listingData.cancellation &&
                    <div className={cx(s.horizontalLineThrough)}>
                        <div className={cx(s.space1)}>
                            <p className={s.sectionTitleText}><FormattedMessage {...messages.cancellations} /></p>
                        </div>
                        <div className={cx(s.spaceTop1)}>
                            <p className={s.listingFontSize}>
                                <span className={cx(s.text)}><strong>{data.listingData.cancellation.policyName}</strong></span>
                            </p>
                            <p className={s.listingFontSize}>
                                <span className={cx(s.text)}>{data.listingData.cancellation.policyContent}</span>
                            </p>
                            <div className={cx(s.listingFontSize, s.showHideMargin)}>
                                <Link
                                    to={"/cancellation-policies/" + data.listingData.cancellation.policyName}
                                    className={cx(s.sectionCaptionLink, s.viewFlex)}
                                >
                                    <FormattedMessage {...messages.viewDetails} /> <img src={rightArrow}
                                                                                        className={'detailsArrowRTL'}/>
                                </Link>
                            </div>
                        </div>
                        <div className={s.listingLine}></div>
                    </div>
                }
            </Row>
        );
    }
}

const mapState = (state) => ({
    settingsData: state.viewListing.settingsData,
});
const mapDispatch = {
    getSpecificSettings,
    contactHostOpen
};
export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ListingDetails)));
