
import messages from '../../locale/messages';


const validateStep3 = values => {

	const errors = {};

	if (!values.basePrice) errors.basePrice = messages.required;

	if (isNaN(values.basePrice) || (!/^[0-9\.]+$/.test(values.basePrice)) || (parseFloat(values.basePrice, 10) < 1)) errors.basePrice = messages.basePriceInvalid;

	if (!values.paymentTypes) errors.paymentTypes = messages.required
	if (!values.publicUtilities) errors.publicUtilities = messages.required
	if (!values.publicUtilitiesType) errors.publicUtilitiesType = messages.required
	return errors;
}

export default validateStep3;
