import {SubmissionError} from 'redux-form';
import fetch from '../../core/fetch';

import messages from '../../locale/messages';
import history from '../../core/history';
import {getListingData} from '../../actions/getListing';
import {manageListingSteps} from '../../actions/manageListingSteps';
import {setLoaderStart, setLoaderComplete} from '../../actions/loader/loader';
import showToaster from '../../helpers/showToaster';
import {getListingDataStep2} from "../../actions/getListingDataStep2";
import {getListingFieldsValues} from "../../actions/getListingFieldsValues";
import { updateListingSteps } from "../../actions/updateListingSteps";
import { updateCurrentStep } from "../../actions/updateCurrentStep";

async function createDetailedListing2(values, dispatch, options = {updateStep: false}) {

    dispatch(setLoaderStart('updateListing'));

    const query = `query (
            $id: Int,
            $description: String,
            $title: String,
            $kuulaLink: String,
            $coverPhoto: Int,
            $parking: [Int],
            $security: [Int],
            $landscaping: [Int],
            $infrastructure: [Int],
          ) {
              updateListingStep2 (
                id: $id,
                description: $description,
                title: $title,
                kuulaLink: $kuulaLink,
                coverPhoto: $coverPhoto,
                parking: $parking,
                security: $security,
                landscaping: $landscaping,
                infrastructure: $infrastructure,
              ) {
                status
              }
        }`;

    const resp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables: values
        }),
        credentials: 'include'
    });

    const { data } = await resp.json();

    if (data.updateListingStep2.status == "success") {
        if (options.updateStep) {
            await dispatch(updateListingSteps(values.id, 2));
        }

        await dispatch(setLoaderComplete('updateListing'));
        return Promise.resolve()
    } else if (data.updateListingStep2.status == "notLoggedIn") {
        dispatch(setLoaderComplete('updateListing'));
        throw new SubmissionError({ _error: messages.notLoggedIn });
    } else {
        dispatch(setLoaderComplete('updateListing'));
        throw new SubmissionError({ _error: messages.somethingWentWrong });
    }


}

export default createDetailedListing2;
