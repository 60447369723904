import {
    GET_LISTING_FIELDS_INTIAL_VALUES_ERROR,
    GET_LISTING_FIELDS_INTIAL_VALUES_START, GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS,
    GET_LISTING_STEPS_DATA_SUCCESS
} from "../../constants";
import getCancelPolicies from '../../routes/siteadmin/cancellationPolicies/getCancelPolicies.graphql';
import {initialize} from "redux-form";
import {gql} from "react-apollo";

const query = gql`
    query($step: String){
        getListingSettings(step: $step) {
            id
            typeName
            fieldType
            typeLabel
            step
            isEnable
            isMultiValue
            listSettings {
                id
                typeId
                itemName
                otherItemName
                maximum
                minimum
                startValue
                endValue
                isEnable
                image
            }
        }
    }
`;
export function getListingFieldsValues(step) {
    return async (dispatch, getState, {client}) => {

        dispatch({
            type: GET_LISTING_FIELDS_INTIAL_VALUES_START,
        });

        try {

            let listingFieldsValues = {}, bedType, bedTypeValue
            bedType = getState().listingFields.data.bedType;

            // Get Base Currency from Redux Store
            const baseCurrency = getState().currency.base;
            // Send Request to get listing data
            const {data} = await client.query({
                query,
                variables: {step},
                fetchPolicy: 'network-only'
            });

            if (!data && !data.getListingSettings) {
                console.log('Error in Get Listing Fields Values')
                dispatch({
                    type: GET_LISTING_FIELDS_INTIAL_VALUES_ERROR,
                });
            } else {
                data.getListingSettings.map((item, key) => {
                    if (item.fieldType === "numberType") {
                        listingFieldsValues[item.typeName] = item.listSettings.length > 0 ? item.listSettings[0].startValue : null;
                    } else {
                        if (item.isMultiValue === true) {
                            listingFieldsValues[item.typeName] = [];
                        } else {
                            let unSelectIndex = item.listSettings.length > 0 ? item.listSettings.findIndex(o => (Number(o.isEnable) == 1)) : null;
                            listingFieldsValues[item.typeName] = !(unSelectIndex === null || unSelectIndex === -1) ? item.listSettings[unSelectIndex].id : '';
                        }

                    }
                })
                let updatedValuesStep1 = Object.assign({}, listingFieldsValues, {currency: baseCurrency}, {maxDaysNotice: 'available'},
                    {bookingType: 'instant'}, {residenceType: "1", cadastralNumber: ""}, {deposit: 0});
                dispatch(initialize("ListingNewForm", updatedValuesStep1));
                dispatch({
                    type: GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS,
                    initialValuesLoaded: true
                });
            }
        } catch (error) {
            dispatch({
                type: GET_LISTING_FIELDS_INTIAL_VALUES_ERROR,
                payload: {
                    error
                }
            });
            return false;
        }

        return true;
    };
}
