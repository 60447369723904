import {SubmissionError} from 'redux-form';
import fetch from '../../../core/fetch';

import messages from '../../../locale/messages';
import history from '../../../core/history';
import {getListingData} from '../../../actions/getListing';
import {manageListingSteps} from '../../../actions/manageListingSteps';
import {setLoaderStart, setLoaderComplete} from '../../../actions/loader/loader';
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {
    let variables = Object.assign({}, values);
    dispatch(setLoaderStart('location'));
    if (variables.cadastralNumber) {
        variables.cadastralNumber = variables.cadastralNumber.replaceAll(':', '')
    }
    const query = `query (
            $country:String,
            $street:String,
            $buildingName:String,
            $state: Int,
            $district_id:Int,
            $lat:Float,
            $lng:Float,
            
          ) {
              createListingV2 (
                country: $country
                street: $street
                buildingName: $buildingName
                state: $state
                district_id: $district_id
                lat: $lat,
                lng: $lng,
              ) {
                status
                id
              }
        }`;

    const resp = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables: variables
        }),
        credentials: 'include'
    });

    const {data} = await resp.json();

    if (data.createListingV2.status == "success") {
        await dispatch(getListingData(data.createListingV2.id));
        await dispatch(setLoaderComplete('location'));
        await history.push('/become-a-host/' + data.createListingV2.id);
    } else if (data.createListingV2.status == "notLoggedIn") {
        throw new SubmissionError({_error: messages.notLoggedIn});
    } else if (data.createListingV2.status == "adminLoggedIn") {
        throw new SubmissionError({_error: messages.adminLoggedIn});
    } else {
        throw new SubmissionError({_error: messages.somethingWentWrong});
    }

}

export default submit;
