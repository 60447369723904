// General
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Redux Form
import {reduxForm} from 'redux-form';
// Redux
import {connect} from 'react-redux';
// Translation
import {injectIntl, FormattedMessage} from 'react-intl';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    Button,
    FormGroup,
    Col, Row
} from 'react-bootstrap';

// Internal Component
import PhotosUpload from '../../PhotosUpload';

import s from '../style/base.css';
import bt from '../../commonStyle.css';

import messages from '../../../locale/messages';


class Photos extends Component {

    static propTypes = {
        previousPage: PropTypes.any,
        nextPage: PropTypes.any,
        listId: PropTypes.number.isRequired,
        photosCount: PropTypes.number,
        nextPagePath: PropTypes.string,
        previousPagePath: PropTypes.string,
    };

    static defaultProps = {
        nextPagePath: 'conveniences',
        previousPagePath: 'home',
    }

    constructor(props) {
        super(props);
        this.state = {
            isAvailable: false
        };
    }

    UNSAFE_componentWillMount() {
        const {photosCount} = this.props;

        if (photosCount > 0) {
            this.setState({isAvailable: true});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {photosCount} = nextProps;

        if (photosCount > 0) {
            this.setState({isAvailable: true});
        } else {
            this.setState({isAvailable: false});
        }
    }

    render() {
        const {
            error,
            handleSubmit,
            submitting,
            dispatch,
            nextPage,
            previousPage,
            listId,
            formPage,
            step,
            createNewListing
        } = this.props;
        const {isAvailable} = this.state;
        const {formatMessage} = this.props.intl;
        return (
            <div className={cx(s.listingForm, 'bgBlackTwo')}>
                <Row>
                    <Col xs={12}>
                        <h3 className={cx(s.sidePanelTitle, s.space6)}>
                            <FormattedMessage {...messages.photos} /> <span
                            className={s.required}>*</span>
                        </h3>
                    </Col>
                    <Col xs={12}>
                    <FormGroup className={s.formGroup}>
                            <PhotosUpload listId={listId} placeholder={formatMessage(messages.photosPlaceholder)}/>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

Photos = reduxForm({
    form: 'ListingNewForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Photos);

const mapState = (state) => ({
    photosCount: state.location.photosCount
});
const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Photos)));
